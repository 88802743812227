<template>
    <section id="servicios">
        <div class="cont-ser">
            <div class="fletes">
                <div class="tit-ser"><i class="fa-solid fa-truck-moving"></i> Fletes</div>
                <div class="desc-ser">
                    Somos una empresa la cual tenemos especial interés en brindarle un excelente servicio a todos nuestros clientes, por tal motivo ponemos a sus ordenes nuestro servicio de fletes.
                </div>
            </div>
        </div>

    </section>
</template>

<script>
export default {
    name: "compServicios",
    data() {
        return {
        };
    },
};
</script>
