<template>
  <section id="header">

    <div class="barra">
      <div class="izq">
        Empresa Certificada ante la <span>Cámara Mexicana de la Industria de la Construcción</span> 
      </div>
      <div class="der hidden-xs">
        <div class="header-item">
          <i class="fa-solid fa-location-dot"></i>
          <span class="text1">Estado de México.</span>
        </div>
        <div class="header-item">
          <i class="fa-solid fa-phone-volume"></i>
          <span>55 7315 0035</span> 
        </div>
        <div class="header-item">
          <i class="fa-brands fa-whatsapp"></i>
          <span><a :href="whatsappLink" target="_blank">(52) 56 1091 9069</a></span>
        </div>
        <div class="header-item">
          <a href="https://www.facebook.com/AcerosIkary" target="_blank"><i class="fa-brands fa-square-facebook"></i></a>
          <a href="https://www.instagram.com/ikaryaceros/" target="_blank"><i class="fa-brands fa-square-instagram"></i></a>
          <a href="https://www.tiktok.com/@acerosikary" target="_blank"><i class="fa-brands fa-tiktok"></i></a>
          <a href="https://www.youtube.com/@AcerosIkary" target="_blank"><i class="fa-brands fa-youtube"></i></a>
        </div>
      </div>
    </div>

    <div class="menu">
      <div class="menu-logo">
        <div class="toggle hidden-lg" @click="toggleMenu = !toggleMenu"><i class="fa-solid fa-bars"></i></div>
      </div>
      <div class="menu-cont" :class="{ 'activo': toggleMenu }">
        <div class="menu-header-cel">
          <div class="titulo">Aceros Ikary</div>
          <div class="cerrar" @click="toggleMenu = !toggleMenu"><i class="fa-solid fa-rectangle-xmark"></i></div>
        </div>
        <ul class="enlaces">
          <li class="item" @click.prevent="handleClick('acordeon-slider')">
            <a href="">Inicio</a>
          </li>
          <li class="item" @click.prevent="handleClick('quienes')">
            <a href="">Quienes Somos</a>
          </li>
          <li class="item" @click.prevent="handleClick('proyectos')">
            <a href="">Proyectos</a>
          </li>
          <li class="item" @click.prevent="handleClick('productos')">
            <a href="">Productos</a>
          </li>
          <li class="item" @click.prevent="handleClick('servicios')">
            <a href="">Servicios</a>
          </li>
          <li class="item" @click.prevent="handleClick('galeria-fotos')">
            <a href="">Galería</a>
          </li>
          <li class="item contac" @click.prevent="handleClick('contacto')">
            <a href="">Contacto</a>
          </li>
        </ul>
        <div class="redes">
          <a href="https://www.facebook.com/AcerosIkary" target="_blank"><i class="fa-brands fa-square-facebook"></i></a>
          <a href="https://www.instagram.com/ikaryaceros/" target="_blank"><i class="fa-brands fa-square-instagram"></i></a>
          <a href="https://www.tiktok.com/@acerosikary" target="_blank"><i class="fa-brands fa-tiktok"></i></a>
          <a href="https://www.youtube.com/@AcerosIkary" target="_blank"><i class="fa-brands fa-youtube"></i></a>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "compHeader",
  data() {
    return {
      toggleMenu: false, 
      phoneNumber: '525610919069',
      message: 'Hola, ¿cómo estás?',
      whatsappBaseURL: 'https://wa.me/',
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
  handleClick(sectionId) {
    this.scrollToSection(sectionId);
    this.toggleMenu = !this.toggleMenu;
  },
    handleScroll() {
      // Verifica si la posición de desplazamiento es mayor que la altura de la barra
      this.isScrolled = window.scrollY > 40; // 40px es la altura de la barra
    }
  },
  computed: {
    // Calcula el enlace completo de WhatsApp con el número de teléfono y el mensaje
    whatsappLink() {
      // Elimina los caracteres no numéricos del número de teléfono
      const phoneNumberWithoutSpaces = this.phoneNumber.replace(/\D/g, '');
      // Construye el enlace completo de WhatsApp
      return `${this.whatsappBaseURL}${phoneNumberWithoutSpaces}?text=${encodeURIComponent(this.message)}`;
    },
  },
};
</script>
