<template>
  <section id="productos">
    <div class="titulo-gral">
      <span> Productos </span>
    </div>

    <section class="cont-pro">
      <div v-for="(producto, index) in productos" :key="index" class="carta-box">
        <div class="carta">
          <div class="cara">
            <div class="img-pro">
              <div v-if="producto.nombre === 'Acero Inoxidable'" class="nuevo">
                <img src="../assets/img/nuevo.svg">
              </div>
              <div class="nom">{{ producto.nombre }}</div>
              <img :src="producto.imagen" :alt="producto.nombre" />
            </div>
            <div class="desc-pro">
              {{ producto.descripcion }}
            </div>
            <div class="btn-pro">
              <div class="mas" @click="funModal(producto.nombre)">
                Ver Producto
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="modal-productos" v-show="modalProductos">
      <div class="body-modal">
        <div class="cerrar" @click="modalProductos = false">
          <span>Cerrar Ventana</span> <i class="fa-solid fa-square-xmark"></i>
        </div>
        <div class="tit-modal">
          <span>Materiales</span>
          <i class="fa-solid fa-angles-right"></i>
          <span class="pro">{{ productoSeleccionado }}</span>
        </div>
        <div class="cont-modal">

          <template v-if="productoSeleccionado === 'Alambre'">
            <div class="sub">Rollo</div>
            <div class="tablas table-responsive">

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-08">
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Diámetro</td>
                      <td colspan="2">Diámetro Int.</td>
                      <td colspan="2">Diámetro Ext.</td>
                      <td>Peso</td>
                    </tr>
                    <tr>
                      <td>MM.</td>
                      <td>PULG.</td>
                      <td>CM.</td>
                      <td>PULG.</td>
                      <td>CM.</td>
                      <td>PULG.</td>
                      <td>KG./Rollo</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.59</td>
                      <td>0.06</td>
                      <td>30</td>
                      <td>11.8</td>
                      <td>50</td>
                      <td>19.7</td>
                      <td>50</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-08">
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Diámetro</td>
                      <td colspan="2">Diámetro Int.</td>
                      <td colspan="2">Diámetro Ext.</td>
                      <td>Peso</td>
                    </tr>
                    <tr>
                      <td>MM.</td>
                      <td>PULG.</td>
                      <td>CM.</td>
                      <td>PULG.</td>
                      <td>CM.</td>
                      <td>PULG.</td>
                      <td>KG./Rollo</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>1.59</td>
                      <td>0.06</td>
                      <td>40</td>
                      <td>15.7</td>
                      <td>60</td>
                      <td>23.6</td>
                      <td>50</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="productoSeleccionado === 'Alambrón'">
            <div class="tablas table-responsive">
              <div class="row">
                <div class="col-md-4">
                  <table class="table table-striped table-bordered table-responsive ta-02">
                    <tr>
                      <td>Milímetros</td>
                      <td>Equivalencia decimal en pulgadas</td>
                    </tr>
                    <tr>
                      <td>5.5</td>
                      <td>1.218</td>
                    </tr>
                    <tr>
                      <td>6.3</td>
                      <td>0.250</td>
                    </tr>
                    <tr>
                      <td>7.0</td>
                      <td>0.276</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-4">
                  <table class="table table-striped table-bordered table-responsive ta-02">
                    <tr>
                      <td>Milímetros</td>
                      <td>Equivalencia decimal en pulgadas</td>
                    </tr>
                    <tr>
                      <td>8.0</td>
                      <td>0.315</td>
                    </tr>
                    <tr>
                      <td>9.5</td>
                      <td>0.374</td>
                    </tr>
                    <tr>
                      <td>10.0</td>
                      <td>0.394</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-4">
                  <table class="table table-striped table-bordered table-responsive ta-02">
                    <tr>
                      <td>Milímetros</td>
                      <td>Equivalencia decimal en pulgadas</td>
                    </tr>
                    <tr>
                      <td>11.0</td>
                      <td>0.343</td>
                    </tr>
                    <tr>
                      <td>12.0</td>
                      <td>0.473</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Ángulo'">
            <div class="tab-pro">
              <div class="item" @click="proAnguloUno()" :class="{ active: anguloUno }">Ángulo</div>
              <div class="item" @click="proAnguloDos()" :class="{ active: anguloDos }">Ángulo Lados Desiguales</div>
            </div>
            <div class="tablas table-responsive mt-5" v-show="anguloUno">

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1/8 x 3/4</td>
                      <td>3.2 x 19.0</td>
                      <td>0.880</td>
                      <td>5.37</td>
                    </tr>
                    <tr>
                      <td>1/8 x 1</td>
                      <td>3.2. x 25.4</td>
                      <td>1.190</td>
                      <td>7.26</td>
                    </tr>
                    <tr>
                      <td>1/8 x 1 1/4</td>
                      <td>3.2 x 31.7</td>
                      <td>1.500</td>
                      <td>9.15</td>
                    </tr>
                    <tr>
                      <td>1/8 x 1 1/2</td>
                      <td>3.2 x 38.1</td>
                      <td>1.830</td>
                      <td>11.17</td>
                    </tr>
                    <tr>
                      <td>1/8 x 1 3/4</td>
                      <td>3.2 x 44.4</td>
                      <td>2.213</td>
                      <td>13.5</td>
                    </tr>
                    <tr>
                      <td>1/8 x 2</td>
                      <td>3.2 x 50.8</td>
                      <td>2.460</td>
                      <td>15.01</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>3/16 x 1</td>
                      <td>4.8 x 25.4</td>
                      <td>1.730</td>
                      <td>10.56</td>
                    </tr>
                    <tr>
                      <td>3/16 x 1 1/4</td>
                      <td>4.8 x 31.7</td>
                      <td>2.200</td>
                      <td>13.42</td>
                    </tr>
                    <tr>
                      <td>3/16 x 1 1/2</td>
                      <td>4.8 x 38.1</td>
                      <td>2.680</td>
                      <td>16.35</td>
                    </tr>
                    <tr>
                      <td>3/16 x 1 3/4</td>
                      <td>4.8 x 44.4</td>
                      <td>3.196</td>
                      <td>19.5</td>
                    </tr>
                    <tr>
                      <td>3/16 x 2</td>
                      <td>4.8 x 50.8</td>
                      <td>3.630</td>
                      <td>22.15</td>
                    </tr>
                    <tr>
                      <td>3/16 x 2 1/2</td>
                      <td>4.8 x 63.5</td>
                      <td>4.600</td>
                      <td>28.06</td>
                    </tr>
                    <tr>
                      <td>3/16 x 3</td>
                      <td>4.8 x 76.2</td>
                      <td>5.970</td>
                      <td>36.42</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>5/16 x 2</td>
                      <td>7.9 x 50.8</td>
                      <td>5.840</td>
                      <td>35.6</td>
                    </tr>
                    <tr>
                      <td>5/16 x 2 1/2</td>
                      <td>7.9 x 63.5</td>
                      <td>7.440</td>
                      <td>45.5</td>
                    </tr>
                    <tr>
                      <td>5/16 x 3</td>
                      <td>7.9 x 76.2</td>
                      <td>9.080</td>
                      <td>55.39</td>
                    </tr>
                    <tr>
                      <td>5/16 x 3 1/2</td>
                      <td>7.9 x 88.9</td>
                      <td>10.819</td>
                      <td>66</td>
                    </tr>
                    <tr>
                      <td>5/16 x 4</td>
                      <td>7.9 x 101.6</td>
                      <td>12.200</td>
                      <td>74.42</td>
                    </tr>
                    <tr>
                      <td>5/16 x 5</td>
                      <td>7.9 x 127</td>
                      <td>15.245</td>
                      <td>93</td>
                    </tr>
                    <tr>
                      <td>5/16 x 6</td>
                      <td>7.9 x 152.4</td>
                      <td>18.850</td>
                      <td>115</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>3/8 x 2</td>
                      <td>9.5 x 50.8</td>
                      <td>6.990</td>
                      <td>42.5</td>
                    </tr>
                    <tr>
                      <td>3/8 x 2 1/2</td>
                      <td>9.5 x 63.5</td>
                      <td>8.790</td>
                      <td>53.62</td>
                    </tr>
                    <tr>
                      <td>3/8 x 3</td>
                      <td>9.5 x 76.2</td>
                      <td>10.720</td>
                      <td>65.40</td>
                    </tr>
                    <tr>
                      <td>3/8 x 3 1/2</td>
                      <td>9.5 x 88.9</td>
                      <td>12.950</td>
                      <td>79</td>
                    </tr>
                    <tr>
                      <td>3/8 x 4</td>
                      <td>9.5 x 101.6</td>
                      <td>14.600</td>
                      <td>89.06</td>
                    </tr>
                    <tr>
                      <td>3/8 x 5</td>
                      <td>9.5 x 127</td>
                      <td>18.606</td>
                      <td>11.5</td>
                    </tr>
                    <tr>
                      <td>3/8 x 6</td>
                      <td>9.5 x 152.4</td>
                      <td>22.270</td>
                      <td>135.24</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1/4 x 1</td>
                      <td>6.3 x 25.4</td>
                      <td>2.226</td>
                      <td>13.58</td>
                    </tr>
                    <tr>
                      <td>1/4 x 1 1/4</td>
                      <td>6.3 x 31.4</td>
                      <td>2.860</td>
                      <td>17.45</td>
                    </tr>
                    <tr>
                      <td>1/4 x 1 1/2</td>
                      <td>6.3 x 38.1</td>
                      <td>3.490</td>
                      <td>21.29</td>
                    </tr>
                    <tr>
                      <td>1/4 x 1 3/4</td>
                      <td>6.3 x 44.4</td>
                      <td>4.098</td>
                      <td>25</td>
                    </tr>
                    <tr>
                      <td>1/4 x 2</td>
                      <td>6.3 x 50.8</td>
                      <td>4.754</td>
                      <td>29</td>
                    </tr>
                    <tr>
                      <td>1/4 x 2 1/2</td>
                      <td>6.3 x 63.5</td>
                      <td>6.100</td>
                      <td>37.21</td>
                    </tr>
                    <tr>
                      <td>1/4 x 3</td>
                      <td>6.3 x 76.2</td>
                      <td>7.290</td>
                      <td>44.47</td>
                    </tr>
                    <tr>
                      <td>1/4 x 3 1/2</td>
                      <td>6.3 x 88.9</td>
                      <td>8.688</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>1/4 x 4</td>
                      <td>6.3 x 101.6</td>
                      <td>9.900</td>
                      <td>60.39</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1/2 x 2 1/2</td>
                      <td>12.7 x 63.5</td>
                      <td>11.803</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td>1/2 x 3</td>
                      <td>12.7 x 76.2</td>
                      <td>13.990</td>
                      <td>85.34</td>
                    </tr>
                    <tr>
                      <td>1/2 x 3 1/2</td>
                      <td>12.7 x 88.9</td>
                      <td>16.557</td>
                      <td>101</td>
                    </tr>
                    <tr>
                      <td>1/2 x 4</td>
                      <td>12.7 x 101.6</td>
                      <td>19.016</td>
                      <td>116</td>
                    </tr>
                    <tr>
                      <td>1/2 x 5</td>
                      <td>12.7 x 127</td>
                      <td>24.098</td>
                      <td>147</td>
                    </tr>
                    <tr>
                      <td>1/2 x 6</td>
                      <td>12.7 x 152</td>
                      <td>29.170</td>
                      <td>177.94</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>5/8 x 4</td>
                      <td>15.9 x 101.6</td>
                      <td>23.278</td>
                      <td>142</td>
                    </tr>
                    <tr>
                      <td>5/8 x 6</td>
                      <td>15.9 x 152.4</td>
                      <td>36.010</td>
                      <td>219.67</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>3/4 x 4</td>
                      <td>19.1 x 101.6</td>
                      <td>27.540</td>
                      <td>168</td>
                    </tr>
                    <tr>
                      <td>3/4 x 6</td>
                      <td>19.1 x 152.4</td>
                      <td>42.710</td>
                      <td>260.54</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1 x 6</td>
                      <td>25.4 x 152.4</td>
                      <td>60.819</td>
                      <td>371</td>
                    </tr>
                  </table>
                  <br>
                  <table class="table table-striped table-bordered table-responsive">
                    <tr>
                      <thead>Ángulos Ligeros<br><br></thead>
                    </tr>
                    <tr>
                      <td>Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>.098 x 3/4</td>
                      <td>0.786</td>
                      <td>4.8</td>
                    </tr>
                    <tr>
                      <td>.109 x 1</td>
                      <td>1.016</td>
                      <td>6.2</td>
                    </tr>
                    <tr>
                      <td>.109 x 1 1/4</td>
                      <td>1.278</td>
                      <td>7.8</td>
                    </tr>
                    <tr>
                      <td>.109 x 1 1/2</td>
                      <td>1.725</td>
                      <td>10.5</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="tablas table-responsive mt-5" v-show="anguloDos">
              <div class="row">
                <div class="sub">Medidas a 6.10MTS.</div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td>3 x 4</td>
                      <td>76.2 x 101.6</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>1/4</td>
                      <td>6.3</td>
                      <td>8.630</td>
                      <td>52.5</td>
                    </tr>
                    <tr>
                      <td>5/16</td>
                      <td>7.9</td>
                      <td>10.720</td>
                      <td>65.5</td>
                    </tr>
                    <tr>
                      <td>3/8</td>
                      <td>9.5</td>
                      <td>12.650</td>
                      <td>77.5</td>
                    </tr>
                    <tr>
                      <td>1/2</td>
                      <td>12.7</td>
                      <td>16.520</td>
                      <td>100.5</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td>4 x 6</td>
                      <td>101 x 152.4</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>3/8</td>
                      <td>9.5</td>
                      <td>18.300</td>
                      <td>111.5</td>
                    </tr>
                    <tr>
                      <td>1/2</td>
                      <td>12.7</td>
                      <td>24.110</td>
                      <td>147</td>
                    </tr>
                    <tr>
                      <td>5/8</td>
                      <td>15.9</td>
                      <td>29.760</td>
                      <td>181.5</td>
                    </tr>
                    <tr>
                      <td>3/4</td>
                      <td>19.1</td>
                      <td>35.120</td>
                      <td>214.5</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Canal C.P.S.'">
            <div class="tablas table-responsive">

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td colspan="2" rowspan="3">Peralte</td>
                      <td colspan="2" rowspan="3">Peso</td>
                      <td colspan="3">Dimensiones</td>
                    </tr>
                    <tr>
                      <td colspan="2">Patin</td>
                      <td rowspan="2">Espesor Alma</td>
                    </tr>
                    <tr>
                      <td>Ancho</td>
                      <td>Espesor</td>
                    </tr>
                    <tr>
                      <td>76.20</td>
                      <td>3</td>
                      <td>6.10</td>
                      <td>37.21</td>
                      <td>36</td>
                      <td>7</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>101.60</td>
                      <td>4</td>
                      <td>8.04</td>
                      <td>49.05</td>
                      <td>40</td>
                      <td>8</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td rowspan="3">152.40</td>
                      <td>6</td>
                      <td>12.20</td>
                      <td>74.42</td>
                      <td>49</td>
                      <td>9</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>15.65</td>
                      <td>95.5</td>
                      <td>52</td>
                      <td>9</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>19.350</td>
                      <td>118.04</td>
                      <td>55</td>
                      <td>9</td>
                      <td>11</td>
                    </tr>
                    <tr>
                      <td rowspan="3">203.20</td>
                      <td>8</td>
                      <td>17.11</td>
                      <td>104.38</td>
                      <td>57</td>
                      <td>10</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>20.46</td>
                      <td>124.8</td>
                      <td>60</td>
                      <td>10</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>27.90</td>
                      <td>170.19</td>
                      <td>64</td>
                      <td>10</td>
                      <td>12</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td colspan="2" rowspan="3">Peralte</td>
                      <td colspan="2" rowspan="3">Peso</td>
                      <td colspan="3">Dimensiones</td>
                    </tr>
                    <tr>
                      <td colspan="2">Patin</td>
                      <td rowspan="2">Espesor Alma</td>
                    </tr>
                    <tr>
                      <td>Ancho</td>
                      <td>Espesor</td>
                    </tr>
                    <tr>
                      <td rowspan="4">254.00</td>
                      <td>10</td>
                      <td>22.76</td>
                      <td>138.84</td>
                      <td>66</td>
                      <td>11</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>29.76</td>
                      <td>181.53</td>
                      <td>70</td>
                      <td>11</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>37.20</td>
                      <td>226.92</td>
                      <td>73</td>
                      <td>11</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>44.64</td>
                      <td>272.30</td>
                      <td>73</td>
                      <td>13</td>
                      <td>17</td>
                    </tr>
                    <tr>
                      <td rowspan="3">304.80</td>
                      <td>12</td>
                      <td>30.80</td>
                      <td>187.88</td>
                      <td>75</td>
                      <td>13</td>
                      <td>7</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>37.20</td>
                      <td>226.92</td>
                      <td>77</td>
                      <td>13</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>44.64</td>
                      <td>272.30</td>
                      <td>81</td>
                      <td>13</td>
                      <td>13</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Cuadrado'">
            <div class="tablas table-responsive">

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td colspan="3">Medidas</td>
                      <td colspan="2">Peso</td>
                      <td colspan="2">Área de la sección</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                      <td>MM<sup>2</sup>.</td>
                      <td>Pulgadas<sup>2</sup></td>
                    </tr>
                    <tr>
                      <td>1/4</td>
                      <td>0.250</td>
                      <td>6.3</td>
                      <td>0.327</td>
                      <td>2</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>5/16</td>
                      <td>0.310</td>
                      <td>7.9</td>
                      <td>0.524</td>
                      <td>3.2</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>3/8</td>
                      <td>0.375</td>
                      <td>9.53</td>
                      <td>0.712</td>
                      <td>4.35</td>
                      <td>90.73</td>
                      <td>0.14</td>
                    </tr>
                    <tr>
                      <td>1/2</td>
                      <td>0.500</td>
                      <td>12.70</td>
                      <td>1.266</td>
                      <td>7.73</td>
                      <td>161.29</td>
                      <td>0.25</td>
                    </tr>
                    <tr>
                      <td>5/8</td>
                      <td>0.625</td>
                      <td>15.88</td>
                      <td>1.978</td>
                      <td>12.07</td>
                      <td>252.02</td>
                      <td>0.39</td>
                    </tr>
                    <tr>
                      <td>3/4</td>
                      <td>0.750</td>
                      <td>19.05</td>
                      <td>2.849</td>
                      <td>17.38</td>
                      <td>362.90</td>
                      <td>0.56</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>1.000</td>
                      <td>25.40</td>
                      <td>5.065</td>
                      <td>30.90</td>
                      <td>645.16</td>
                      <td>1.00</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td colspan="3">Medidas</td>
                      <td colspan="2">Peso</td>
                      <td colspan="2">Área de la sección</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                      <td>MM<sup>2</sup>.</td>
                      <td>Pulgadas<sup>2</sup></td>
                    </tr>
                    <tr>
                      <td>1 1/4</td>
                      <td>1.250</td>
                      <td>31.74</td>
                      <td>7.913</td>
                      <td>48.27</td>
                      <td>1008.06</td>
                      <td>1.58</td>
                    </tr>
                    <tr>
                      <td>1 1/2</td>
                      <td>1.500</td>
                      <td>38.10</td>
                      <td>11.393</td>
                      <td>69.5</td>
                      <td>1451.61</td>
                      <td>2.25</td>
                    </tr>
                    <tr>
                      <td>1 3/4</td>
                      <td>1.750</td>
                      <td>44.4</td>
                      <td>15.737</td>
                      <td>96</td>
                      <td>2016.12</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>2.000</td>
                      <td>50.80</td>
                      <td>20.258</td>
                      <td>123.58</td>
                      <td>2580.64</td>
                      <td>4.00</td>
                    </tr>
                    <tr>
                      <td>2 1/4</td>
                      <td>2.250</td>
                      <td>57.1</td>
                      <td>25.901</td>
                      <td>158</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>2 1/2</td>
                      <td>2.500</td>
                      <td>63.50</td>
                      <td>31.653</td>
                      <td>193.09</td>
                      <td>4032.25</td>
                      <td>6.25</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>3.000</td>
                      <td>76.20</td>
                      <td>45.57</td>
                      <td>278</td>
                      <td>5806.44</td>
                      <td>9.00</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Duela'">
            <div class="tablas table-responsive">

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-06">
                    <tr>
                      <td rowspan="2">Duelas</td>
                      <td rowspan="2">Descripción</td>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td><img src="../assets/img/medidas/pro-01.jpg" class="img-responsive"></td>
                      <td>ZD-170</td>
                      <td>20</td>
                      <td>1.584</td>
                      <td>9.502</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td><img src="../assets/img/medidas/pro-02.jpg" class="img-responsive"></td>
                      <td>ZD-180</td>
                      <td>20</td>
                      <td>2.184</td>
                      <td>13.106</td>
                      <td>4</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-06">
                    <tr>
                      <td rowspan="2">Duelas</td>
                      <td rowspan="2">Descripción</td>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td><img src="../assets/img/medidas/pro-03.jpg" class="img-responsive"></td>
                      <td>ZD-181</td>
                      <td>20</td>
                      <td>1.196</td>
                      <td>7.177</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td><img src="../assets/img/medidas/pro-04.jpg" class="img-responsive"></td>
                      <td>ZD-171</td>
                      <td>20</td>
                      <td>0.862</td>
                      <td>5.170</td>
                      <td>10</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'H.S.S.'">
            <div class="tablas table-responsive">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td>Cuadrado</td>
                      <td>Rectangular</td>
                      <td colspan="5">Peso en KG./M.</td>
                    </tr>
                    <tr>
                      <td colspan="2">Pulgadas</td>
                      <td>3/16</td>
                      <td>1/4</td>
                      <td>5/16</td>
                      <td>3/8</td>
                      <td>1/2</td>
                    </tr>
                    <tr>
                      <td>4 x 4</td>
                      <td>5 x 3<br>6 x 2</td>
                      <td>14.02</td>
                      <td>18.17</td>
                      <td>22.07</td>
                      <td>25.70</td>
                      <td>32.19</td>
                    </tr>
                    <tr>
                      <td>4 1/2 x 4 1/2</td>
                      <td>5 x 4<br>6 x 3<br>7 x 2</td>
                      <td>15.92</td>
                      <td>20.70</td>
                      <td>25.24</td>
                      <td>29.50</td>
                      <td>37.25</td>
                    </tr>
                    <tr>
                      <td>5 x 5</td>
                      <td>6 x 4<br>7 x 3<br>8 x 2</td>
                      <td>17.81</td>
                      <td>23.25</td>
                      <td>28.39</td>
                      <td>32.29</td>
                      <td>42.31</td>
                    </tr>
                    <tr>
                      <td>5 1/2 x 5 1/2</td>
                      <td>6 x 5<br>7 x 4<br>8 x 3</td>
                      <td>19.72</td>
                      <td>25.77</td>
                      <td>31.56</td>
                      <td>37.10</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>6 x 6</td>
                      <td>7 x 5<br>8 x 4<br>9 x 3<br>10 x 2</td>
                      <td>21.62</td>
                      <td>28.30</td>
                      <td>34.73</td>
                      <td>40.89</td>
                      <td>52.44</td>
                    </tr>
                    <tr>
                      <td>7 x 7</td>
                      <td>8 x 6<br>9 x 5<br>10 x 4<br>12 x 2</td>
                      <td>25.42</td>
                      <td>33.36</td>
                      <td>41.06</td>
                      <td>48.48</td>
                      <td>62.58</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td>Cuadrado</td>
                      <td>Rectangular</td>
                      <td colspan="5">Peso en KG./M.</td>
                    </tr>
                    <tr>
                      <td>7 1/2 x 7 1/2</td>
                      <td>8 x 7<br>9 x 6<br>10 x 5<br>12 x 3</td>
                      <td>27.31</td>
                      <td>35.89</td>
                      <td>44.23</td>
                      <td>52.28</td>
                      <td>67.64</td>
                    </tr>
                    <tr>
                      <td>8 x 8</td>
                      <td>9 x 7<br>10 x 6<br>12 x 4</td>
                      <td>29.21</td>
                      <td>38.42</td>
                      <td>47.38</td>
                      <td>56.09</td>
                      <td>72.70</td>
                    </tr>
                    <tr>
                      <td>9 x 9</td>
                      <td>10 x 8<br>12 x 6<br>14 x 4</td>
                      <td>33.01</td>
                      <td>43.50</td>
                      <td>53.72</td>
                      <td>63.68</td>
                      <td>82.83</td>
                    </tr>
                    <tr>
                      <td>10 x 10</td>
                      <td>12 x 8<br>14 x 6<br>16 x 4</td>
                      <td>36.80</td>
                      <td>48.56</td>
                      <td>60.05</td>
                      <td>71.28</td>
                      <td>92.95</td>
                    </tr>
                    <tr>
                      <td>12 x 12</td>
                      <td>14 x 10<br>16 x 8<br>18 x 6<br>20 x 4</td>
                      <td>44.41</td>
                      <td>58.68</td>
                      <td>72.71</td>
                      <td>86.46</td>
                      <td>113.20</td>
                    </tr>
                    <tr>
                      <td>14 x 14</td>
                      <td>16 x 12<br>20 x 8</td>
                      <td></td>
                      <td></td>
                      <td>85.36</td>
                      <td>101.66</td>
                      <td>133.46</td>
                    </tr>
                    <tr>
                      <td>16 x 16</td>
                      <td>20 x 12</td>
                      <td></td>
                      <td></td>
                      <td>98.03</td>
                      <td>116.85</td>
                      <td>153.73</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Lámina'">
            <div class="tab-pro">
              <div class="item" @click="proLaminaUno()" :class="{ active: laminaUno }">Lámina acanalada galvanizada y/o
                pintada</div>
              <div class="item" @click="proLaminaDos()" :class="{ active: laminaDos }">Lámina galvanizada liza</div>
              <div class="item" @click="proLaminaTres()" :class="{ active: laminaTres }">Lámina losacero sección 4</div>
              <div class="item" @click="proLaminaCuatro()" :class="{ active: laminaCuatro }">Lámina negra RC</div>
              <div class="item" @click="proLaminaCinco()" :class="{ active: laminaCinco }">Lámina negra RF</div>
            </div>
            <div class="tablas table-responsive mt-5" v-show="laminaUno">
              R-72 y R-101
              <br><br>
              Cubiertas de naves industriales, bodegas y construcciones en general, en donde se requiera mediana
              capacidad estrucrural y de desagüe. Disponible sólo en las siguientes longitudes: 1.83 m., 2.44 m., 3.66
              m., 4.27 m,.5.50 m. y 6.10 m.
              <br><br>
              <table class="table table-striped table-bordered table-responsive ta-03">
                <tr>
                  <td rowspan="2">Calibre</td>
                  <td colspan="2">Peso (KG./M.)</td>
                </tr>
                <tr>
                  <td>R-72</td>
                  <td>R-01</td>
                </tr>
                <tr>
                  <td>30</td>
                  <td>3.39</td>
                  <td></td>
                </tr>
                <tr>
                  <td>28</td>
                  <td>4.12</td>
                  <td>3.92</td>
                </tr>
                <tr>
                  <td>26</td>
                  <td>4.89</td>
                  <td>4.64</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>5.64</td>
                  <td>5.36</td>
                </tr>
                <tr>
                  <td>22</td>
                  <td>7.91</td>
                  <td>7.52</td>
                </tr>
              </table>
              <br><br>
              0-30
              <br><br>
              Ideal para aplicación rural. Fácil menejo y trnsporte. Disponible sólo en las siguientes longitudes: 1.83
              m., 2.44 m., 3.05 m., 3.66 m., 4.27 m., 4.88 m., 5.50 m. y 6.10 m.
              <br><br>
              <table class="table table-striped table-bordered table-responsive ta-02">
                <tr>
                  <td>Calibre</td>
                  <td>Peso (KG./M.)</td>
                </tr>
                <tr>
                  <td>32</td>
                  <td>2.21</td>
                </tr>
              </table>
            </div>
            <div class="tablas table-responsive mt-5" v-show="laminaDos">

              <table class="table table-striped table-bordered table-responsive ta-11">
                <tr>
                  <td>Cal.</td>
                  <td>Espesor en (MM)</td>
                  <td>KG./Ml. 3 Pies</td>
                  <td>KG./Ml. 4 Pies</td>
                  <td>3 x 6<br>.915 x 1.83</td>
                  <td>3 x 8<br>.915 x 2.44</td>
                  <td>3 x 10<br>.915 x 3.05</td>
                  <td>3 x 12<br>.915 x 3.66</td>
                  <td>4 x 8<br>1.22 x 2.44</td>
                  <td>4 x 10<br>1.22 x 3.05</td>
                  <td>4 x 12<br>1.22 x 3.66</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>3.42</td>
                  <td>25.46</td>
                  <td>33.95</td>
                  <td>45.33</td>
                  <td>61</td>
                  <td>76.5</td>
                  <td>90.66</td>
                  <td>81.6</td>
                  <td>102</td>
                  <td>122</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>2.66</td>
                  <td>19.32</td>
                  <td>25.76</td>
                  <td>35.4</td>
                  <td>47.2</td>
                  <td>59</td>
                  <td>70.8</td>
                  <td>62.85</td>
                  <td>78.57</td>
                  <td>94.3</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>1.90</td>
                  <td>13.87</td>
                  <td>18.50</td>
                  <td>25.38</td>
                  <td>33.84</td>
                  <td>42.30</td>
                  <td>50.76</td>
                  <td>45.14</td>
                  <td>56.33</td>
                  <td>67.60</td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>1.52</td>
                  <td>11.16</td>
                  <td>14.87</td>
                  <td>20.5</td>
                  <td>27.21</td>
                  <td>34.04</td>
                  <td>40.9</td>
                  <td>36.28</td>
                  <td>45.35</td>
                  <td>54.42</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>1.21</td>
                  <td>8.97</td>
                  <td>11.96</td>
                  <td>16.41</td>
                  <td>21.89</td>
                  <td>27.36</td>
                  <td>32.83</td>
                  <td>29.18</td>
                  <td>36.48</td>
                  <td>43.77</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>.91</td>
                  <td>6.79</td>
                  <td>9.06</td>
                  <td>12.3</td>
                  <td>16.59</td>
                  <td>20.74</td>
                  <td>24.6</td>
                  <td>22.11</td>
                  <td>27.63</td>
                  <td>33.15</td>
                </tr>
                <tr>
                  <td>22</td>
                  <td>.760</td>
                  <td>5.71</td>
                  <td>7.61</td>
                  <td>10.43</td>
                  <td>13.91</td>
                  <td>17.39</td>
                  <td>20.86</td>
                  <td>18.54</td>
                  <td>23.18</td>
                  <td>23.21</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>.610</td>
                  <td>4.06</td>
                  <td>5.41</td>
                  <td>8.15</td>
                  <td>10.88</td>
                  <td>13.60</td>
                  <td>14.86</td>
                  <td>14.51</td>
                  <td>18.14</td>
                  <td>19.83</td>
                </tr>
                <tr>
                  <td>26</td>
                  <td>.450</td>
                  <td>3.52</td>
                  <td>4.69</td>
                  <td>6.44</td>
                  <td>8.59</td>
                  <td>10.74</td>
                  <td>12.82</td>
                  <td>10.88</td>
                  <td>13.60</td>
                  <td>16.32</td>
                </tr>
                <tr>
                  <td>28</td>
                  <td>.380</td>
                  <td>2.97</td>
                  <td>3.96</td>
                  <td>5.43</td>
                  <td>6.804</td>
                  <td>8.50</td>
                  <td>10.86</td>
                  <td>9.07</td>
                  <td>11.30</td>
                  <td>19.93</td>
                </tr>
                <tr>
                  <td>30</td>
                  <td>.300</td>
                  <td>2.44</td>
                  <td></td>
                  <td>4.74</td>
                  <td>5.44</td>
                  <td>6.80</td>
                  <td>9.48</td>
                  <td>7.15</td>
                  <td>9.07</td>
                  <td>12.20</td>
                </tr>
                <tr>
                  <td>32</td>
                  <td>.240</td>
                  <td>2.40</td>
                  <td></td>
                  <td>3.66</td>
                  <td>4.88</td>
                  <td>5.52</td>
                  <td>7.32</td>
                  <td>5.89</td>
                  <td>7.37</td>
                  <td>10.18</td>
                </tr>
              </table>
            </div>
            <div class="tablas table-responsive mt-5" v-show="laminaTres">

              <table class="table table-striped table-bordered table-responsive">
                <tr>
                  <td colspan="4">Propiedades de la sección 4 (sin concreto)</td>
                </tr>
                <tr>
                  <td>Calibre</td>
                  <td>Peso (KG./M.)</td>
                  <td>Peso (KG./PZA.)</td>
                  <td>Peso (KG./M<sub>2</sub>.)</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>6.06</td>
                  <td>37</td>
                  <td>5.7</td>
                </tr>
                <tr>
                  <td>22</td>
                  <td>7.21</td>
                  <td>44</td>
                  <td>8.0</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>8.6</td>
                  <td>52.5</td>
                  <td>9.54</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>12.54</td>
                  <td>76.5</td>
                  <td>12.59</td>
                </tr>
              </table>
            </div>
            <div class="tablas table-responsive mt-5" v-show="laminaCuatro">

              <table class="table table-striped table-bordered table-responsive ta-08">
                <tr>
                  <td colspan="8"> Pesos teoricos por hoja (Kg)</td>
                </tr>
                <tr>
                  <td>Calibre</td>
                  <td>Pulgada</td>
                  <td>Espesor</td>
                  <td>3 x 6<br>.915 x 1.83</td>
                  <td>3 x 8<br>.915 x 2.44</td>
                  <td>3 x 10<br>.915 x 3.05</td>
                  <td>4 x 8<br>1.22 x 2.44</td>
                  <td>4 x 10<br>1.22 x 3.05</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>0.075</td>
                  <td>1.89</td>
                  <td>25.5</td>
                  <td>34</td>
                  <td>42.8</td>
                  <td>45.4</td>
                  <td>56.7</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>0.1046</td>
                  <td>2.66</td>
                  <td>35.7</td>
                  <td>47.6</td>
                  <td>59.5</td>
                  <td>63.5</td>
                  <td>79.4</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>0.1196</td>
                  <td>3.04</td>
                  <td>40.8</td>
                  <td>54.4</td>
                  <td>68</td>
                  <td>72.6</td>
                  <td>90.7</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>0.1345</td>
                  <td>3.42</td>
                  <td>46.0</td>
                  <td>61.3</td>
                  <td>76.6</td>
                  <td>81.6</td>
                  <td>102.58</td>
                </tr>
              </table>
            </div>
            <div class="tablas table-responsive mt-5" v-show="laminaCinco">

              <table class="table table-striped table-bordered table-responsive ta-09">
                <tr>
                  <td colspan="9">Pesos Teoricos por hoja (Kg)</td>
                </tr>
                <tr>
                  <td>Calibre</td>
                  <td>Pulgada</td>
                  <td>Espesor</td>
                  <td>3 x 6<br>.915 x 1.83</td>
                  <td>3 x 8<br>.915 x 2.44</td>
                  <td>3 x 10<br>.915 x 3.05</td>
                  <td>4 x 8<br>1.22 x 2.44</td>
                  <td>4 x 10<br>1.22 x 3.05</td>
                  <td>KG./M<sup>2</sup>.</td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>0.0598</td>
                  <td>1.52</td>
                  <td>20.4</td>
                  <td>27.2</td>
                  <td>34.04</td>
                  <td>36.3</td>
                  <td>45.39</td>
                  <td>12.2060</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>0.0478</td>
                  <td>1.21</td>
                  <td>16.4</td>
                  <td>21.8</td>
                  <td>27.26</td>
                  <td>29</td>
                  <td>36.34</td>
                  <td>9.7648</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>0.0359</td>
                  <td>0.91</td>
                  <td>12.3</td>
                  <td>16.3</td>
                  <td>20.41</td>
                  <td>21.8</td>
                  <td>27.59</td>
                  <td>7.3236</td>
                </tr>
                <tr>
                  <td>22</td>
                  <td>0.0299</td>
                  <td>0.76</td>
                  <td>10.2</td>
                  <td>13.6</td>
                  <td>17.01</td>
                  <td>18.2</td>
                  <td>22.68</td>
                  <td>6.1030</td>
                </tr>
                <tr>
                  <td>24</td>
                  <td>0.0239</td>
                  <td>0.61</td>
                  <td>8.16</td>
                  <td>10.9</td>
                  <td>13.60</td>
                  <td>14.6</td>
                  <td>18.144</td>
                  <td>4.8824</td>
                </tr>
                <tr>
                  <td>26</td>
                  <td>0.0179</td>
                  <td>0.45</td>
                  <td>6.12</td>
                  <td>8.2</td>
                  <td>10.62</td>
                  <td>10.9</td>
                  <td>13.34</td>
                  <td>3.6627</td>
                </tr>
              </table>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Malla Electrosoldada'">
            <div class="tablas table-responsive">

              Rollos de mallas de 100 M<sup>2</sup>
              <br><br>
              <table class="table table-striped table-bordered table-responsive ta-0">
                <tr>
                  <td>Medida</td>
                  <td>Ancho (MTS.)</td>
                  <td>Longitud (MTS.)</td>
                  <td>KG./PZA.</td>
                </tr>
                <tr>
                  <td>66 - 1010</td>
                  <td>2.50</td>
                  <td>40</td>
                  <td>90</td>
                </tr>
                <tr>
                  <td>66 - 88</td>
                  <td>2.50</td>
                  <td>40</td>
                  <td>130</td>
                </tr>
                <tr>
                  <td>66 - 66</td>
                  <td>2.50</td>
                  <td>40</td>
                  <td>183</td>
                </tr>
                <tr>
                  <td>66 - 44</td>
                  <td>2.50</td>
                  <td>40</td>
                  <td>245</td>
                </tr>
              </table>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Pasamanos'">
            <div class="tablas table-responsive">

              <table class="table table-striped table-bordered table-responsive ta-05">
                <tr>
                  <td>Calibre</td>
                  <td>Longitud</td>
                  <td>KG./M.</td>
                  <td>KG./PZA.</td>
                  <td>PZA. atado</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>6.00</td>
                  <td>1.412</td>
                  <td>8.471</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>6.00</td>
                  <td>1.081</td>
                  <td>6.488</td>
                  <td>8</td>
                </tr>
              </table>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Perfil Tubular'">
            <div class="tablas table-responsive">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-01.jpg" class="img-responsive"><br>ZV-101
                        Ángulo liso, este perfil es el que forma la hoja de la ventana, la cual es la que tiene
                        movimiento, sea corredizo o abatible y su ceja sostiene el vidrio.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.189</td>
                      <td>7.132</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>0.915</td>
                      <td>5.488</td>
                      <td>10</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-02.jpg" class="img-responsive"><br>ZV-103 La
                        "T" sencilla va en la parte central de la ventana corrediza o abatible y sus cejas sirven para
                        sostener el vidrio y/o mosquitero.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.380</td>
                      <td>8.277</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.048</td>
                      <td>6.286</td>
                      <td>10</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-03.jpg" class="img-responsive"><br>ZV-106 La
                        Z se puede utilizar como perfil ZV-103 con la diferencia que al tener la ceja en desigual
                        posición facilita otros usos (ventana abatible como movimiento de la hoja interior, exterior,
                        etc.)<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.398</td>
                      <td>8.390</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.062</td>
                      <td>6.370</td>
                      <td>10</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-04.jpg" class="img-responsive"><br>ZV-116
                        Perfil escalonado con doble ceja se usa en la parte inferior, exclusivamente para ventanas
                        corredizas donde la hoja que tiene movimiento va montada sobre la ceja superior, en la ceja
                        inferior se colocan los vidrios cuando existan fijos.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.472</td>
                      <td>8.831</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-05.jpg" class="img-responsive"><br>ZV-120
                        Chambrana estructural, tiene la misma función que el perfil ZV-121, pero para ventanas mas
                        estructurales, u otros usos que requieran mayor rsistencia.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.324</td>
                      <td>7.945</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.020</td>
                      <td>6.123</td>
                      <td>8</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-06.jpg" class="img-responsive"><br>ZV.121 La
                        chambrana se utiliza como marco de la ventana (superior y lateral, en la corrediza y en la
                        abatible los cuatro lados) y en su ceja sostiene el vidrio y/o mosquitero.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.336</td>
                      <td>8.015</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.025</td>
                      <td>6.149</td>
                      <td>8</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-07.jpg" class="img-responsive"><br>ZV-122
                        Escalonado con una ceja, se usa en la parte inferior, exclusivamente para ventanas corredizas y
                        en su ceja va montada la hoja que tiene el movimiento.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.327</td>
                      <td>7.963</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.017</td>
                      <td>6.436</td>
                      <td>8</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-08.jpg" class="img-responsive"><br>ZV-123
                        Ángulo ranurado, va en la parte inferior de la hoja que tiene el movimiento en la ventana
                        corrediza, en su ceja se coloca el vidrio y su ranura va montada en la ceja del perfil
                        ZV-122.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.072</td>
                      <td>6.432</td>
                      <td>8</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-09.jpg" class="img-responsive"><br>ZV- 124
                        Riel, este perfil va soldado al marco superior (chambrana) de la ventana corrediza y en el
                        gancho va montada la hoja que tiene el movimiento.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>0.682</td>
                      <td>4.090</td>
                      <td>10</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-10.jpg" class="img-responsive"><br>ZV-126
                        Chambrana cerrada, se utiliza como marco de la ventana igual que el perfil ZV-121, pero con la
                        difeencia de ser más resistente estructuralmente por ser un perfil cerrado.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.713</td>
                      <td>10.281</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.302</td>
                      <td>7.812</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-11.jpg" class="img-responsive"><br>ZV-127
                        "T" doble, este perfil va en ventanas con fijos en la parte superior como inferior, se colocan
                        los vidrios y/o mosquiteros.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.919</td>
                      <td>11.512</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.479</td>
                      <td>8.875</td>
                      <td>5</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-12.jpg" class="img-responsive"><br>ZV-131
                        Mosquitero, va de marco en ventanas, puertas y ventilas.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>0.615</td>
                      <td>3.899</td>
                      <td>10</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-13.jpg" class="img-responsive"><br>ZV-144 T
                        de ceja, tiene la misma función que el perfil ZV-103 para ventanas mas estructurales u otros
                        usos con mayor resistencia.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.496</td>
                      <td>8.975</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.150</td>
                      <td>6.899</td>
                      <td>8</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-14.jpg" class="img-responsive"><br>ZV- 145
                        Mosquitero, va de mrco en ventanas, puertas y ventilas.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>0.640</td>
                      <td>3.838</td>
                      <td>10</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-15.jpg" class="img-responsive"><br>ZV- 154
                        Cantaleta, se utiliza como porta-vidrio o junta-vidrio de la ventana, en algunas partes del país
                        como maro o sujetador de la tela mosquitero.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>0.238</td>
                      <td>1.426</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>0.185</td>
                      <td>1.112</td>
                      <td>50</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-16.jpg" class="img-responsive"><br>ZV-155
                        Canaleta, se utiliza como porta-vidrio o junta-vidrio de la ventana, en algunas partes del país
                        como marco o sujetador de la tela mosquitero.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>0.251</td>
                      <td>1.509</td>
                      <td>50</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-17.jpg" class="img-responsive"><br>ZV-156
                        Canaleta, se utiliza principalmente para falso plafón.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>0.434</td>
                      <td>2.603</td>
                      <td>30</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-18.jpg" class="img-responsive"><br>ZP-015
                        Chapeton o manguete, se utiliza en medio, o como base central de la puerta o porton, dependiendo
                        del tamaño. En sus cejas tanto superior como inferior, se sujetan las duelas, tableros, vidrios
                        o mosquiteros.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.897</td>
                      <td>11.384</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.456</td>
                      <td>8.734</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-19.jpg" class="img-responsive"><br>ZP-020
                        Chapeton o manguete, se utiliza en medio, o como base central de la puerta o porton, dependiendo
                        del tamaño. En sus cejas tanto superior como inferior, se sujetan las duelas, tableos, vidrios o
                        mosquiteros.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.632</td>
                      <td>4.978</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-20.jpg" class="img-responsive"><br>ZP-030
                        Chapetn o manguete, se utiliza en medio,o comobase central de la puerta o porton, dependiendo
                        del tamaño, En sus cejas tanto superior com inferior, se sujetan las deulas, tableros, vidrios o
                        mosquiteros.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>2.618</td>
                      <td>15.707</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>2.000</td>
                      <td>12.003</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-21.jpg" class="img-responsive"><br>ZP-100 Se
                        utiliza para formar la ceja, el bastidor y la hoja de puertas y portones metálicos. Su ceja se
                        utiliza para sujetar duelas, tableros, vidrio o mosquitero.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.357</td>
                      <td>8.116</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.027</td>
                      <td>6.162</td>
                      <td>8</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-22.jpg" class="img-responsive"><br>ZP-150 Se
                        utiliza para formar la ceja, el bastidos y la hoja de puertas y portones´metálicos. Su ceja se
                        utiliza para sujetar duelas, tableros, vidrio o mosquitero.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.606</td>
                      <td>9.633</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.218</td>
                      <td>7.309</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-23.jpg" class="img-responsive"><br>ZP-200 Se
                        utiliza para formar la ceja, el bastidos y la hoja de puertas y portones´metálicos. Su ceja se
                        utiliza para sujetar duelas, tableros, vidrio o mosquitero.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.839</td>
                      <td>11.034</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.395</td>
                      <td>8.368</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-24.jpg" class="img-responsive"><br>ZP-250 Se
                        utiliza para formar la ceja, el bastidos y la hoja de puertas y portones´metálicos. Su ceja se
                        utiliza para sujetar duelas, tableros, vidrio o mosquitero.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>2.131</td>
                      <td>12.784</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.615</td>
                      <td>9.691</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-25.jpg" class="img-responsive"><br>ZP-300 Se
                        utiliza para formar la ceja, el bastidos y la hoja de puertas y portones´metálicos. Su ceja se
                        utiliza para sujetar duelas, tableros, vidrio o mosquitero.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>2.326</td>
                      <td>13.957</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.615</td>
                      <td>9.691</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-26.jpg" class="img-responsive"><br>ZP-400 Se
                        utiliza para formar la ceja, el bastidos y la hoja de puertas y portones´metálicos. Su ceja se
                        utiliza para sujetar duelas, tableros, vidrio o mosquitero.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>2.811</td>
                      <td>16.868</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>2.130</td>
                      <td>12.779</td>
                      <td>4</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-27.jpg" class="img-responsive"><br>ZP-1400
                        Riel o guia, va en la parte superior de portones, rejas o canceles, en sus ganchos se montan los
                        carros o carretillas para que den movimiento corredizo.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.205</td>
                      <td>7.229</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-28.jpg" class="img-responsive"><br>ZP-1500
                        Riel o guia, va en la parte superior de portones, rejas o canceles, en sus ganchos se montan los
                        carros o carretillas para que den movimiento corredizo.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>2.939</td>
                      <td>17.632</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>2.404</td>
                      <td>14.425</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-29.jpg" class="img-responsive"><br>ZM-221
                        Marco para puertas con tope sencillo y ceja que funciona como chambrana por si el marco va junto
                        a una ventana lateral.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.962</td>
                      <td>11.775</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.489</td>
                      <td>8.933</td>
                      <td>5</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-30.jpg" class="img-responsive"><br>ZM-225
                        Marco para puerta con tope sencilla. Se fabrica en 5.5 m de longitud.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.325</td>
                      <td>7.285</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.028</td>
                      <td>5.653</td>
                      <td>8</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-31.jpg" class="img-responsive"><br>ZM-300
                        Marco para puerta con tope doble para mayor resistencia. Se fabrica en 5.5 m.de
                        longitud.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>1.555</td>
                      <td>8.552</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.203</td>
                      <td>6.615</td>
                      <td>8</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-32.jpg" class="img-responsive"><br>ZM-350
                        Marco para puerta que no reuiere tope (vaiven) o bien para closets donde el tope de la puerta es
                        de frente. Se fabrica en 5.5 m de longitud.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.098</td>
                      <td>6.041</td>
                      <td>10</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="4"><img src="../assets/img/medidas/pro-2-33.jpg" class="img-responsive"><br>ZM-525
                        Marco con doble tope, para puerta abatible hacia el interior y pueta mosquiteo hacia el
                        exterior. Se fabrica en 5.5 m de longitud.<br><br></td>
                    </tr>
                    <tr>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>2.101</td>
                      <td>11.553</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>1.592</td>
                      <td>8.754</td>
                      <td>6</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6"></div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td rowspan="2">Producto</td>
                      <td rowspan="2">Lado X</td>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>ZC-050</td>
                      <td>13<br>13</td>
                      <td>18<br>20</td>
                      <td>.482<br>.371</td>
                      <td>2.894<br>2.223</td>
                      <td>20<br>20</td>
                    </tr>
                    <tr>
                      <td>ZC-075</td>
                      <td>19<br>19</td>
                      <td>18<br>20</td>
                      <td>.717<br>.547</td>
                      <td>4.300<br>3.282</td>
                      <td>20<br>20</td>
                    </tr>
                    <tr>
                      <td>ZC-100</td>
                      <td>25<br>25</td>
                      <td>18<br>20</td>
                      <td>.950<br>.723</td>
                      <td>5.701<br>4.341</td>
                      <td>20<br>1</td>
                    </tr>
                    <tr>
                      <td>ZC-125</td>
                      <td>32<br>32</td>
                      <td>18<br>20</td>
                      <td>1.224<br>.930</td>
                      <td>7.346<br>5.580</td>
                      <td>10<br>10</td>
                    </tr>
                    <tr>
                      <td>ZC-150</td>
                      <td>38<br>38</td>
                      <td>18<br>20</td>
                      <td>1.457<br>1.106</td>
                      <td>8.741<br>6.639</td>
                      <td>10<br>10</td>
                    </tr>
                    <tr>
                      <td>ZC-175</td>
                      <td>44<br>44</td>
                      <td>14<br>16</td>
                      <td>2.599<br>2.100</td>
                      <td>15.596<br>12.600</td>
                      <td>1<br>1</td>
                    </tr>
                    <tr>
                      <td>ZC-200</td>
                      <td>50<br>50</td>
                      <td>14<br>18</td>
                      <td>2.961<br>1.925</td>
                      <td>17.767<br>11.547</td>
                      <td>1<br>8</td>
                    </tr>
                    <tr>
                      <td>ZC-225</td>
                      <td>57</td>
                      <td>14</td>
                      <td>3.419</td>
                      <td>20.511</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>ZC-250</td>
                      <td>64<br>64</td>
                      <td>12<br>14<br>16</td>
                      <td>4.915<br>3.601<br>2.920</td>
                      <td>29.489<br>21.603<br>17.522</td>
                      <td>1<br>1<br>1</td>
                    </tr>
                    <tr>
                      <td colspan="6">Tienen muy variadosusos entre los que destacan: estructuras para muebles,
                        estructuras ligeras, poste para estacas, rejas y protecciones.</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td rowspan="2">Producto</td>
                      <td rowspan="2">Lado X</td>
                      <td rowspan="2">Calibre</td>
                      <td colspan="2">Longitud 6.00 MTS.</td>
                      <td rowspan="2">PZAS./Atado</td>
                    </tr>
                    <tr>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>ZR-100</td>
                      <td>13/25</td>
                      <td>18</td>
                      <td>.717</td>
                      <td>4.300</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>ZR-125</td>
                      <td>20/32<br>20/32</td>
                      <td>18<br>20</td>
                      <td>.989<br>.753</td>
                      <td>5.934<br>4.517</td>
                      <td>10<br>10</td>
                    </tr>
                    <tr>
                      <td>ZR-175</td>
                      <td>20/45<br>20/45</td>
                      <td>18<br>20</td>
                      <td>1.244<br>.945</td>
                      <td>7.463<br>5.673</td>
                      <td>10<br>10</td>
                    </tr>
                    <tr>
                      <td>ZR-200</td>
                      <td>25/50<br>25/50</td>
                      <td>18<br>20</td>
                      <td>1.438<br>1.093</td>
                      <td>8.630<br>6.555</td>
                      <td>10<br>10</td>
                    </tr>
                    <tr>
                      <td>ZR-225</td>
                      <td>20/58<br>20/58</td>
                      <td>18<br>20</td>
                      <td>1.496<br>1.136</td>
                      <td>8.974<br>6.815</td>
                      <td>8<br>8</td>
                    </tr>
                    <tr>
                      <td>ZR-249</td>
                      <td>32/65<br>32/65</td>
                      <td>18<br>20</td>
                      <td>1.867<br>1.417</td>
                      <td>11.203<br>8.500</td>
                      <td>8<br>8</td>
                    </tr>
                    <tr>
                      <td>ZR-250</td>
                      <td>38/64</td>
                      <td>14</td>
                      <td>3.022</td>
                      <td>18.129</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>ZR-300</td>
                      <td>38/75<br>38/75</td>
                      <td>18<br>20</td>
                      <td>2.101<br>1.601</td>
                      <td>12.603<br>9.608</td>
                      <td>6<br>6</td>
                    </tr>
                    <tr>
                      <td>ZR-400</td>
                      <td>38/100<br>38/100</td>
                      <td>18<br>20</td>
                      <td>2.587<br>1.969</td>
                      <td>15.521<br>11.813</td>
                      <td>4<br>4</td>
                    </tr>
                    <tr>
                      <td colspan="6">Tienen muy variadosusos entre los que destacan: estructuras ligeras, rejas y
                        polines para cargas ligeras.</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Placa'">
            <div class="tab-pro">
              <div class="item" @click="proPlacaUno()" :class="{ active: placaUno }">Placa antiderrapante</div>
              <div class="item" @click="proPlacaDos()" :class="{ active: placaDos }">Placa estructural A-36, A-283-C y
                A-572 GR.50</div>
            </div>
            <div class="tablas table-responsive mt-5" v-show="placaUno">

              <table class="table table-striped table-bordered table-responsive ta-10">
                <tr>
                  <td colspan="2">Medidas</td>
                  <td>1/8 KG./Pz</td>
                  <td>3/16 KG./Pz</td>
                  <td>1/4 KG./Pz</td>
                  <td>5/16 KG./Pz</td>
                  <td>3/8 KG./Pz</td>
                  <td>C-11 KG./Pz</td>
                  <td>C-12 KG./Pz</td>
                  <td>C-14 KG./Pz</td>
                </tr>
                <tr>
                  <td rowspan="2">Espesor</td>
                  <td>PULG.</td>
                  <td>.1250</td>
                  <td>.1875</td>
                  <td>.2500</td>
                  <td>.313</td>
                  <td>.375</td>
                  <td>.1146</td>
                  <td>.1046</td>
                  <td>.0747</td>
                </tr>
                <tr>
                  <td>MM.</td>
                  <td>3.17</td>
                  <td>4.76</td>
                  <td>6.35</td>
                  <td>7.9</td>
                  <td>9.5</td>
                  <td>3.4</td>
                  <td>2.66</td>
                  <td>1.90</td>
                </tr>
                <tr>
                  <td colspan="2">3 x 8</td>
                  <td>68</td>
                  <td>95</td>
                  <td>125</td>
                  <td>150</td>
                  <td>175</td>
                  <td>55</td>
                  <td>50</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td colspan="2">3 x 10</td>
                  <td>85</td>
                  <td>120</td>
                  <td>156</td>
                  <td>185</td>
                  <td>220</td>
                  <td>70</td>
                  <td>62</td>
                  <td>45</td>
                </tr>
                <tr>
                  <td colspan="2">3 x 20</td>
                  <td>170</td>
                  <td>240</td>
                  <td>315</td>
                  <td>370</td>
                  <td>440</td>
                  <td>135</td>
                  <td>125</td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="2">4 x 8</td>
                  <td>90</td>
                  <td>130</td>
                  <td>166</td>
                  <td>195</td>
                  <td>233</td>
                  <td>72</td>
                  <td>65</td>
                  <td>50</td>
                </tr>
                <tr>
                  <td colspan="2">4 x 10</td>
                  <td>113</td>
                  <td>162</td>
                  <td>208</td>
                  <td>245</td>
                  <td>290</td>
                  <td>90</td>
                  <td>85</td>
                  <td>62</td>
                </tr>
              </table>
            </div>
            <div class="tablas table-responsive mt-5" v-show="placaDos">


              <table class="table table-striped table-bordered table-responsive mb-5">
                <tr>
                  <td colspan="2">Medidas</td>
                  <td rowspan="2">3/16 4.8 KG./Pz.</td>
                  <td rowspan="2">1/4 6.3 KG./Pz.</td>
                  <td rowspan="2">5/16 7.9 KG./Pz.</td>
                  <td rowspan="2">3/8 9.5 KG./Pz.</td>
                  <td rowspan="2">7/16 11.1 KG./Pz.</td>
                  <td rowspan="2">1/2 12.7 KG./Pz.</td>
                </tr>
                <tr>
                  <td>Pies</td>
                  <td>MTS.</td>
                </tr>
                <tr>
                  <td>3 x 8 </td>
                  <td>.915 x 2.44</td>
                  <td>83</td>
                  <td>111</td>
                  <td>140</td>
                  <td>167</td>
                  <td>200</td>
                  <td>222</td>
                </tr>
                <tr>
                  <td>3 x 10</td>
                  <td>.915 x 3.05</td>
                  <td>104</td>
                  <td>139</td>
                  <td>173</td>
                  <td>208</td>
                  <td>205</td>
                  <td>278</td>
                </tr>
                <tr>
                  <td>3 x 20</td>
                  <td>.915 x 6.10</td>
                  <td>210</td>
                  <td>280</td>
                  <td>350</td>
                  <td>415</td>
                  <td>505</td>
                  <td>560</td>
                </tr>
                <tr>
                  <td>4 x 6</td>
                  <td>1.22 x 1.83</td>
                  <td>85</td>
                  <td>115</td>
                  <td>140</td>
                  <td>165</td>
                  <td>200</td>
                  <td>225</td>
                </tr>
                <tr>
                  <td>4 x 8</td>
                  <td>1.22 x 2.44</td>
                  <td>111</td>
                  <td>148</td>
                  <td>185</td>
                  <td>222</td>
                  <td>270</td>
                  <td>296</td>
                </tr>
                <tr>
                  <td>4 x 10</td>
                  <td>1.22 x 3.05</td>
                  <td>139</td>
                  <td>185</td>
                  <td>231</td>
                  <td>278</td>
                  <td>335</td>
                  <td>370</td>
                </tr>
                <tr>
                  <td>4 x 20</td>
                  <td>1.22 x 6.10</td>
                  <td>280</td>
                  <td>390</td>
                  <td>462</td>
                  <td>555</td>
                  <td>670</td>
                  <td>740</td>
                </tr>
                <tr>
                  <td>5 x 10</td>
                  <td>1.52 x 3.05</td>
                  <td>174</td>
                  <td>231</td>
                  <td>289</td>
                  <td>347</td>
                  <td>420</td>
                  <td>463</td>
                </tr>
                <tr>
                  <td> 5 x 20</td>
                  <td>1.52 x 6.10</td>
                  <td>347</td>
                  <td>465</td>
                  <td>578</td>
                  <td>695</td>
                  <td>835</td>
                  <td>925</td>
                </tr>
                <tr>
                  <td>6 x 10</td>
                  <td>1.83 x 3.05</td>
                  <td>205</td>
                  <td>280</td>
                  <td>347</td>
                  <td>416</td>
                  <td>500</td>
                  <td>560</td>
                </tr>
                <tr>
                  <td>6 x 20</td>
                  <td>1.83 x 6.10</td>
                  <td>416</td>
                  <td>555</td>
                  <td>694</td>
                  <td>833</td>
                  <td>1,005</td>
                  <td>1,110</td>
                </tr>
                <tr>
                  <td>8 x 10</td>
                  <td>2.44 x 3.05</td>
                  <td>280</td>
                  <td>373</td>
                  <td>465</td>
                  <td>555</td>
                  <td>670</td>
                  <td>940</td>
                </tr>
                <tr>
                  <td>8 x 20</td>
                  <td>2.44 x 6.10</td>
                  <td>555</td>
                  <td>746</td>
                  <td>925</td>
                  <td>1,110</td>
                  <td>1,340</td>
                  <td>1,480</td>
                </tr>
              </table>
              <table class="table table-striped table-bordered table-responsive ta-08">
                <tr>
                  <td colspan="2">Medidas</td>
                  <td rowspan="2">5/8 15.9 KG./Pz.</td>
                  <td rowspan="2">3/4 19.1 KG./Pz.</td>
                  <td rowspan="2">7/8 22.5 KG./Pz.</td>
                  <td rowspan="2">1 25.4 KG./Pz.</td>
                  <td rowspan="2">1 1/4 31.7 KG./Pz.</td>
                  <td rowspan="2">1 1/2 38.1 KG./Pz.</td>
                </tr>
                <tr>
                  <td>Pies</td>
                  <td>MTS.</td>
                </tr>
                <tr>
                  <td>3 x 8 </td>
                  <td>.915 x 2.44</td>
                  <td>280</td>
                  <td>333</td>
                  <td>390</td>
                  <td>445</td>
                  <td>555</td>
                  <td>680</td>
                </tr>
                <tr>
                  <td>3 x 10</td>
                  <td>.915 x 3.05</td>
                  <td>350</td>
                  <td>413</td>
                  <td>485</td>
                  <td>555</td>
                  <td>695</td>
                  <td>835</td>
                </tr>
                <tr>
                  <td>3 x 20</td>
                  <td>.915 x 6.10</td>
                  <td>730</td>
                  <td>870</td>
                  <td>970</td>
                  <td>1,110</td>
                  <td>1,390</td>
                  <td>1,670</td>
                </tr>
                <tr>
                  <td>4 x 6</td>
                  <td>1.22 x 1.83</td>
                  <td>280</td>
                  <td>335</td>
                  <td>390</td>
                  <td>445</td>
                  <td>555</td>
                  <td>680</td>
                </tr>
                <tr>
                  <td>4 x 8</td>
                  <td>1.22 x 2.44</td>
                  <td>370</td>
                  <td>445</td>
                  <td>520</td>
                  <td>592</td>
                  <td>740</td>
                  <td>905</td>
                </tr>
                <tr>
                  <td>4 x 10</td>
                  <td>1.22 x 3.05</td>
                  <td>465</td>
                  <td>555</td>
                  <td>650</td>
                  <td>740</td>
                  <td>930</td>
                  <td>1,130</td>
                </tr>
                <tr>
                  <td>4 x 20</td>
                  <td>1.22 x 6.10</td>
                  <td>970</td>
                  <td>1,170</td>
                  <td>1,300</td>
                  <td>1,480</td>
                  <td>1,855</td>
                  <td>2,265</td>
                </tr>
                <tr>
                  <td>5 x 10</td>
                  <td>1.52 x 3.05</td>
                  <td>580</td>
                  <td>695</td>
                  <td>810</td>
                  <td>925</td>
                  <td>1,155</td>
                  <td>1,410</td>
                </tr>
                <tr>
                  <td> 5 x 20</td>
                  <td>1.52 x 6.10</td>
                  <td>1,160</td>
                  <td>1,390</td>
                  <td>1,619</td>
                  <td>1,850</td>
                  <td>2,310</td>
                  <td>2,820</td>
                </tr>
                <tr>
                  <td>6 x 10</td>
                  <td>1.83 x 3.05</td>
                  <td>725</td>
                  <td>870</td>
                  <td>980</td>
                  <td>1,110</td>
                  <td>1,390</td>
                  <td>1,670</td>
                </tr>
                <tr>
                  <td>6 x 20</td>
                  <td>1.83 x 6.10</td>
                  <td>1,390</td>
                  <td>1,665</td>
                  <td>1,950</td>
                  <td>2,220</td>
                  <td>2,780</td>
                  <td>3,340</td>
                </tr>
                <tr>
                  <td>8 x 10</td>
                  <td>2.44 x 3.05</td>
                  <td>965</td>
                  <td>1,170</td>
                  <td>1,310</td>
                  <td>1,480</td>
                  <td>1,855</td>
                  <td>2,265</td>
                </tr>
                <tr>
                  <td>8 x 20</td>
                  <td>2.44 x 6.10</td>
                  <td>1,855</td>
                  <td>2,220</td>
                  <td>2,620</td>
                  <td>2,960</td>
                  <td>3,700</td>
                  <td>4,530</td>
                </tr>
              </table>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Polín Mon-Ten'">
            <div class="tablas table-responsive">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td colspan="2">Peralte</td>
                      <td rowspan="2">PAton (MM.)</td>
                      <td rowspan="2">Largo M.</td>
                      <td rowspan="2">Calibre</td>
                      <td rowspan="2">KG./M.</td>
                      <td rowspan="2">KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>(PULG.)</td>
                      <td>(MM.)</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>76</td>
                      <td>38</td>
                      <td>6</td>
                      <td>12</td>
                      <td>3.5</td>
                      <td>21</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>76</td>
                      <td>38</td>
                      <td>6</td>
                      <td>14</td>
                      <td>2.5</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>76</td>
                      <td>38</td>
                      <td>6</td>
                      <td>16</td>
                      <td>2.5</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>101</td>
                      <td>50</td>
                      <td>4</td>
                      <td>14</td>
                      <td>3.375</td>
                      <td>13.5</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>101</td>
                      <td>50</td>
                      <td>6</td>
                      <td>10</td>
                      <td>6.33</td>
                      <td>38</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>101</td>
                      <td>50</td>
                      <td>6</td>
                      <td>12</td>
                      <td>4.66</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>101</td>
                      <td>50</td>
                      <td>6</td>
                      <td>14</td>
                      <td>3.33</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>101</td>
                      <td>50</td>
                      <td>6</td>
                      <td>16</td>
                      <td>2.75</td>
                      <td>16.5</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>127</td>
                      <td>50</td>
                      <td>5</td>
                      <td>14</td>
                      <td>3.6</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>127</td>
                      <td>50</td>
                      <td>6</td>
                      <td>14</td>
                      <td>3.6</td>
                      <td>22</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>152</td>
                      <td>50</td>
                      <td>6</td>
                      <td>10</td>
                      <td>7.83</td>
                      <td>47</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>152</td>
                      <td>50</td>
                      <td>6</td>
                      <td>12</td>
                      <td>5.5</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>152</td>
                      <td>50</td>
                      <td>6</td>
                      <td>14</td>
                      <td>4.16</td>
                      <td>25</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>152</td>
                      <td>50</td>
                      <td>6</td>
                      <td>16</td>
                      <td>3.33</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>177</td>
                      <td>69</td>
                      <td>7</td>
                      <td>12</td>
                      <td>7.71</td>
                      <td>54</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td colspan="2">Peralte</td>
                      <td rowspan="2">PAton (MM.)</td>
                      <td rowspan="2">Largo M.</td>
                      <td rowspan="2">Calibre</td>
                      <td rowspan="2">KG./M.</td>
                      <td rowspan="2">KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>(PULG.)</td>
                      <td>(MM.)</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>177</td>
                      <td>69</td>
                      <td>7</td>
                      <td>14</td>
                      <td>5.14</td>
                      <td>36</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>203</td>
                      <td>69</td>
                      <td>6</td>
                      <td>14</td>
                      <td>5.245</td>
                      <td>32</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>203</td>
                      <td>69</td>
                      <td>8</td>
                      <td>10</td>
                      <td>9.84</td>
                      <td>78.5</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>203</td>
                      <td>69</td>
                      <td>8</td>
                      <td>12</td>
                      <td>7.68</td>
                      <td>61.5</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>203</td>
                      <td>69</td>
                      <td>8</td>
                      <td>14</td>
                      <td>5.25</td>
                      <td>42</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>203</td>
                      <td>69</td>
                      <td>12</td>
                      <td>14</td>
                      <td>5.58</td>
                      <td>67</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>203</td>
                      <td>69</td>
                      <td>7.5</td>
                      <td>14</td>
                      <td>5.6</td>
                      <td>42</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>254</td>
                      <td>69</td>
                      <td>10</td>
                      <td>10</td>
                      <td>11.2</td>
                      <td>112</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>254</td>
                      <td>69</td>
                      <td>10</td>
                      <td>12</td>
                      <td>8.8</td>
                      <td>88</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>254</td>
                      <td>69</td>
                      <td>10</td>
                      <td>14</td>
                      <td>6.3</td>
                      <td>63</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>304</td>
                      <td>89</td>
                      <td>10</td>
                      <td>10</td>
                      <td>14</td>
                      <td>144</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>304</td>
                      <td>89</td>
                      <td>10</td>
                      <td>12</td>
                      <td>11.1</td>
                      <td>111</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>304</td>
                      <td>89</td>
                      <td>12</td>
                      <td>10</td>
                      <td>13.3</td>
                      <td>160</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>304</td>
                      <td>89</td>
                      <td>12</td>
                      <td>12</td>
                      <td>10.58</td>
                      <td>127</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>304</td>
                      <td>89</td>
                      <td>12</td>
                      <td>14</td>
                      <td>7.5</td>
                      <td>90</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'P.T.R.'">
            <div class="tablas table-responsive">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-0">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td rowspan="2">Calibre</td>
                      <td rowspan="2">Color</td>
                      <td rowspan="2">Espesor (MM.)</td>
                      <td rowspan="2">KG./M.</td>
                      <td rowspan="2">KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>(PULG.)</td>
                      <td>(MM.)</td>
                    </tr>
                    <tr>
                      <td>3/4 x 3/4</td>
                      <td>19.1 x 19.1</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>.99</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td rowspan="3">1x1</td>
                      <td rowspan="3">25.4 x 25.4</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>1.32</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Verde</td>
                      <td>1.89</td>
                      <td>1.64</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Rojo</td>
                      <td>3.4</td>
                      <td>2.10</td>
                      <td>12.8</td>
                    </tr>
                    <tr>
                      <td>1 1/4 x 1 1/4</td>
                      <td>31.7 x 31.7</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>1.81</td>
                      <td>11</td>
                    </tr>
                    <tr>
                      <td rowspan="4">1 1/2 x 1 1/2</td>
                      <td rowspan="4">38.1 x 38.1</td>
                      <td>12</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>2.22</td>
                      <td>13.5</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Blanco</td>
                      <td>2.8</td>
                      <td>2.22</td>
                      <td>13.5</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Verde</td>
                      <td>3.2</td>
                      <td>3.22</td>
                      <td>19.60</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Rojo</td>
                      <td>4.0</td>
                      <td>3.87</td>
                      <td>23.60</td>
                    </tr>
                    <tr>
                      <td>1 3/4 x 1 3/4</td>
                      <td>44.4 x 44.4</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>2.46</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>2x1</td>
                      <td>50.8 x 25.4</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>2.22</td>
                      <td>13.5</td>
                    </tr>
                    <tr>
                      <td rowspan="4">2x2</td>
                      <td rowspan="4">50.8 x 50.8</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>2.95</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Blanco</td>
                      <td>2.8</td>
                      <td>4.10</td>
                      <td>25</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Verde</td>
                      <td>3.2</td>
                      <td>4.46</td>
                      <td>27.2</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Rojo</td>
                      <td>4.0</td>
                      <td>5.33</td>
                      <td>32.50</td>
                    </tr>
                    <tr>
                      <td> 2 1/2 x 1 1/4</td>
                      <td>63.5 x 31.7</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>2.71</td>
                      <td>16.5</td>
                    </tr>
                    <tr>
                      <td rowspan="2">2 1/2 x 1 1/2</td>
                      <td rowspan="2">63.5 x 38.1</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>3.0</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Verde</td>
                      <td>3.04</td>
                      <td>4.59</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td rowspan="4">2 1/2 x 2 1/2</td>
                      <td rowspan="4">63.5 x 63.5</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>3.69</td>
                      <td>22.5</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Blanco</td>
                      <td>3.02</td>
                      <td>5.58</td>
                      <td>34.0</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Verde</td>
                      <td>3.80</td>
                      <td>6.23</td>
                      <td>38.0</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Rojo</td>
                      <td>4.80</td>
                      <td>7.87</td>
                      <td>48</td>
                    </tr>
                    <tr>
                      <td> 3 x 1 1/2 </td>
                      <td>76.2 x 38.1</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>3.26</td>
                      <td>20</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-0">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td rowspan="2">Calibre</td>
                      <td rowspan="2">Color</td>
                      <td rowspan="2">Espesor (MM.)</td>
                      <td rowspan="2">KG./M.</td>
                      <td rowspan="2">KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>(PULG.)</td>
                      <td>(MM.)</td>
                    </tr>
                    <tr>
                      <td rowspan="4">3 x 3</td>
                      <td rowspan="4">76.2 x 76.2</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>4.27</td>
                      <td>26</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Blanco</td>
                      <td>3.20</td>
                      <td>6.89</td>
                      <td>42</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Verde</td>
                      <td>4.00</td>
                      <td>8.36</td>
                      <td>51</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Rojo</td>
                      <td>4.80</td>
                      <td>10.17</td>
                      <td>62</td>
                    </tr>
                    <tr>
                      <td rowspan="4">3 1/2 x 3 1/2</td>
                      <td rowspan="4">88.9 x 88.9</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>5.17</td>
                      <td>31.5</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Blanco</td>
                      <td>3.20</td>
                      <td>7.79</td>
                      <td>47.5</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Verde</td>
                      <td>4.00</td>
                      <td>9.84</td>
                      <td>60</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Rojo</td>
                      <td>4.80</td>
                      <td>11.64</td>
                      <td>71</td>
                    </tr>
                    <tr>
                      <td rowspan="4">3 x 2</td>
                      <td rowspan="4">76.2 x 50.8</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>3.61</td>
                      <td>22</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Blanco</td>
                      <td>3.20</td>
                      <td>5.41</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Verde</td>
                      <td>3.60</td>
                      <td>6.15</td>
                      <td>37.5</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Rojo</td>
                      <td>4.80</td>
                      <td>7.74</td>
                      <td>47.20</td>
                    </tr>
                    <tr>
                      <td>4 x 1 1/2</td>
                      <td>101.6 x 38.1</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>3.94</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td rowspan="4">4 x 2</td>
                      <td rowspan="4">101.6 x 50.8</td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>4.27</td>
                      <td>26</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Blanco</td>
                      <td>.20</td>
                      <td>6.99</td>
                      <td>42.6</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Verde</td>
                      <td>4.00</td>
                      <td>8.20</td>
                      <td>50.0</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Rojo</td>
                      <td>4.8</td>
                      <td>10.04</td>
                      <td>61.20</td>
                    </tr>
                    <tr>
                      <td rowspan="4"></td>
                      <td rowspan="4"></td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>5.27</td>
                      <td>32</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Blanco</td>
                      <td>3.20</td>
                      <td>7.87</td>
                      <td>48</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Verde</td>
                      <td>4.00</td>
                      <td>9.84</td>
                      <td>60</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Rojo</td>
                      <td>4.80</td>
                      <td>11.64</td>
                      <td>71</td>
                    </tr>
                    <tr>
                      <td rowspan="4"></td>
                      <td rowspan="4"></td>
                      <td>14</td>
                      <td>Azul</td>
                      <td>1.89</td>
                      <td>5.91</td>
                      <td>36</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Blanco</td>
                      <td>3.20</td>
                      <td>9.46</td>
                      <td>57.66</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Verde</td>
                      <td>4.00</td>
                      <td>11.64</td>
                      <td>70.98</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Rojo</td>
                      <td>4.80</td>
                      <td>13.87</td>
                      <td>84.60</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Redondo'">
            <div class="tab-pro">
              <div class="item" @click="proRedondoUno()" :class="{ active: redondoUno }">Redondo</div>
              <div class="item" @click="proRedondoDos()" :class="{ active: redondoDos }">Redondo pulido</div>
            </div>
            <div class="tablas table-responsive mt-5" v-show="redondoUno">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-06">
                    <tr>
                      <td colspan="2">Dimensión</td>
                      <td colspan="2">Área de la sección</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>3/8</td>
                      <td>9.5</td>
                      <td>71.3</td>
                      <td>0.110</td>
                      <td>0.559</td>
                      <td>3.4</td>
                    </tr>
                    <tr>
                      <td>1/2</td>
                      <td>12.7</td>
                      <td>126.7</td>
                      <td>0.196</td>
                      <td>0.994</td>
                      <td>6.07</td>
                    </tr>
                    <tr>
                      <td>9/16</td>
                      <td>14.3</td>
                      <td>162.3</td>
                      <td>0.251</td>
                      <td>1.147</td>
                      <td>7</td>
                    </tr>
                    <tr>
                      <td>5/8</td>
                      <td>15.9</td>
                      <td>197.9</td>
                      <td>0.307</td>
                      <td>1.552</td>
                      <td>9.47</td>
                    </tr>
                    <tr>
                      <td>3/4</td>
                      <td>19.1</td>
                      <td>285.0</td>
                      <td>0.442</td>
                      <td>2.235</td>
                      <td>13.64</td>
                    </tr>
                    <tr>
                      <td>7/8</td>
                      <td>22.2</td>
                      <td>387.9</td>
                      <td>0.601</td>
                      <td>3.045</td>
                      <td>18.58</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>25.4</td>
                      <td>506.7</td>
                      <td>0.785</td>
                      <td>0.973</td>
                      <td>24.24</td>
                    </tr>
                    <tr>
                      <td>1 1/16</td>
                      <td>17.5</td>
                      <td>241.5</td>
                      <td>0.374</td>
                      <td>1.885</td>
                      <td>11.5</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-06">
                    <tr>
                      <td colspan="2">Dimensión</td>
                      <td colspan="2">Área de la sección</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>1 1/8</td>
                      <td>28.6</td>
                      <td>641.3</td>
                      <td>0.994</td>
                      <td>5.022</td>
                      <td>30.64</td>
                    </tr>
                    <tr>
                      <td>1 3/16</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>5.573</td>
                      <td>34</td>
                    </tr>
                    <tr>
                      <td>1 1/4</td>
                      <td>31.8</td>
                      <td>791.7</td>
                      <td>1.227</td>
                      <td>6.208</td>
                      <td>37.87</td>
                    </tr>
                    <tr>
                      <td>1 5/16</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>6.639</td>
                      <td>40.5</td>
                    </tr>
                    <tr>
                      <td>1 3/8</td>
                      <td>34.9</td>
                      <td></td>
                      <td></td>
                      <td>7.514</td>
                      <td>45.84</td>
                    </tr>
                    <tr>
                      <td>1 1/2</td>
                      <td>38.1</td>
                      <td>1140.1</td>
                      <td>1.767</td>
                      <td>9.016</td>
                      <td>55</td>
                    </tr>
                    <tr>
                      <td>1 3/4</td>
                      <td>44.5</td>
                      <td>1551.8</td>
                      <td>2.405</td>
                      <td>12.170</td>
                      <td>74.24</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>50.8</td>
                      <td>2026.8</td>
                      <td>3.142</td>
                      <td>15.890</td>
                      <td>96.93</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="tablas table-responsive mt-5" v-show="redondoDos">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Lgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>3/16</td>
                      <td>4.8</td>
                      <td>0.163</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>1/4</td>
                      <td>6.3</td>
                      <td>0.243</td>
                      <td>1.5</td>
                    </tr>
                    <tr>
                      <td>5/16</td>
                      <td>7.9</td>
                      <td>0.409</td>
                      <td>2.5</td>
                    </tr>
                    <tr>
                      <td>3/8</td>
                      <td>9.5</td>
                      <td>0.557</td>
                      <td>3.4</td>
                    </tr>
                    <tr>
                      <td>1/2</td>
                      <td>12.7</td>
                      <td>1.032</td>
                      <td>6.3</td>
                    </tr>
                    <tr>
                      <td>5/8</td>
                      <td>15.9</td>
                      <td>1.557</td>
                      <td>9.5</td>
                    </tr>
                    <tr>
                      <td>3/4</td>
                      <td>19.1</td>
                      <td>2.235</td>
                      <td>13.61</td>
                    </tr>
                    <tr>
                      <td>7/8</td>
                      <td>22.2</td>
                      <td>3.032</td>
                      <td>18.5</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Lgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>24.4</td>
                      <td>4.016</td>
                      <td>24.5</td>
                    </tr>
                    <tr>
                      <td>1 1/4</td>
                      <td>31.7</td>
                      <td>6.147</td>
                      <td>37.5</td>
                    </tr>
                    <tr>
                      <td>2 1/4</td>
                      <td>57.1</td>
                      <td>20.163</td>
                      <td>123</td>
                    </tr>
                    <tr>
                      <td>2 1/2</td>
                      <td>63.5</td>
                      <td>24.836</td>
                      <td>151.5</td>
                    </tr>
                    <tr>
                      <td>2 3/4</td>
                      <td>70.0</td>
                      <td>30.327</td>
                      <td>185</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>76.2</td>
                      <td>35.766</td>
                      <td>218.18</td>
                    </tr>
                    <tr>
                      <td>3 1/4</td>
                      <td>82.5</td>
                      <td>38.688</td>
                      <td>236</td>
                    </tr>
                    <tr>
                      <td>3 1/2</td>
                      <td>88.9</td>
                      <td>41.967</td>
                      <td>256</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Rejilla tipo Irving'">
            <div class="tablas table-responsive">
              <table class="table table-striped table-bordered table-responsive ta-04">
                <tr>
                  <td>Medidas</td>
                  <td>Ancho (MTS.)</td>
                  <td>Longuitud (MTS.)</td>
                  <td>KG./PZA.</td>
                </tr>
                <tr>
                  <td>1/8 x 1</td>
                  <td>1</td>
                  <td>6</td>
                  <td>150</td>
                </tr>
                <tr>
                  <td>1/8 x 1 1/4</td>
                  <td>1</td>
                  <td>6</td>
                  <td>185</td>
                </tr>
                <tr>
                  <td>1/8 x 1 1/2</td>
                  <td>1</td>
                  <td>6</td>
                  <td>218</td>
                </tr>
                <tr>
                  <td>3/16 x 1</td>
                  <td>1</td>
                  <td>6</td>
                  <td>220</td>
                </tr>
                <tr>
                  <td>3/16 x 1 1/4</td>
                  <td>1</td>
                  <td>6</td>
                  <td>265</td>
                </tr>
                <tr>
                  <td>3/16 x 1 1/2</td>
                  <td>1</td>
                  <td>6</td>
                  <td>320</td>
                </tr>
              </table>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Solera'">
            <div class="tab-pro">
              <div class="item" @click="proSoleraUno()" :class="{ active: soleraUno }">Solera </div>
              <div class="item" @click="proSoleraDos()" :class="{ active: soleraDos }">Solera pasamanos</div>
            </div>
            <div class="tablas table-responsive mt-5" v-show="soleraUno">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1/8 x 1/2</td>
                      <td>3.2 x 12.7</td>
                      <td>0.317</td>
                      <td>1.93</td>
                    </tr>
                    <tr>
                      <td>1/8 x 5/8</td>
                      <td>3.2 x 15.9</td>
                      <td>0.396</td>
                      <td>2.41</td>
                    </tr>
                    <tr>
                      <td>1/8 x 3/4</td>
                      <td>3.2 x 19.1</td>
                      <td>0.474</td>
                      <td>2.89</td>
                    </tr>
                    <tr>
                      <td>1/8 x 1</td>
                      <td>3.2 x 25.4</td>
                      <td>0.633</td>
                      <td>3.86</td>
                    </tr>
                    <tr>
                      <td>1/8 x 1 1/4</td>
                      <td>3.2 x 31.8</td>
                      <td>0.791</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>1/8 x 1 1/2</td>
                      <td>3.2 x 38.1</td>
                      <td>0.950</td>
                      <td>5.79</td>
                    </tr>
                    <tr>
                      <td>1/8 x 2</td>
                      <td>3.2 x 50.8</td>
                      <td>1.266</td>
                      <td>7.72</td>
                    </tr>
                    <tr>
                      <td>1/8 x 2 1/2</td>
                      <td>3.2 x 63.5</td>
                      <td>1.631</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>1/8 x 3</td>
                      <td>3.2 x 76.2</td>
                      <td>1.967</td>
                      <td>12</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>3/16 x 1/2</td>
                      <td>4.8 x 12.7</td>
                      <td>0.474</td>
                      <td>2.89</td>
                    </tr>
                    <tr>
                      <td>3/16 x 5/8</td>
                      <td>4.8 x 15.9</td>
                      <td>0.593</td>
                      <td>3.61</td>
                    </tr>
                    <tr>
                      <td>3/16 x 3/4</td>
                      <td>4.8 x 19.1</td>
                      <td>0.712</td>
                      <td>4.34</td>
                    </tr>
                    <tr>
                      <td>3/16 x 1</td>
                      <td>4.8 x 25.4</td>
                      <td>0.950</td>
                      <td>5.79</td>
                    </tr>
                    <tr>
                      <td>3/16 x 1 1/4</td>
                      <td>4.8 x 31.8</td>
                      <td>1.187</td>
                      <td>7.24</td>
                    </tr>
                    <tr>
                      <td>3/16 x 1 1/2</td>
                      <td>4.8 x 38.1</td>
                      <td>1.424</td>
                      <td>8.68</td>
                    </tr>
                    <tr>
                      <td>3/16 x 2</td>
                      <td>4.8 x 50.8</td>
                      <td>1.899</td>
                      <td>11.58</td>
                    </tr>
                    <tr>
                      <td>3/16 x 2 1/2</td>
                      <td>4.8 x 63.5</td>
                      <td>2.374</td>
                      <td>14.48</td>
                    </tr>
                    <tr>
                      <td>3/16 x 3</td>
                      <td>4.8 x 76.2</td>
                      <td>2.849</td>
                      <td>17.37</td>
                    </tr>
                    <tr>
                      <td>3/16 x 4</td>
                      <td>4.8 x 101.6</td>
                      <td>3.770</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>3/16 x 5</td>
                      <td>4.8 x 127</td>
                      <td>4.754</td>
                      <td>29</td>
                    </tr>
                    <tr>
                      <td>3/16 x 6</td>
                      <td>4.8 x 152.4</td>
                      <td>5.737</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>3/16 x 8</td>
                      <td>4.8 x 203.2</td>
                      <td>7.540</td>
                      <td>46</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1/4 x 1/2</td>
                      <td>6.4 x 12.7</td>
                      <td>0.633</td>
                      <td>3.86</td>
                    </tr>
                    <tr>
                      <td>1/4 x 5/8</td>
                      <td>6.4 x 15.9</td>
                      <td>0.791</td>
                      <td>4.82</td>
                    </tr>
                    <tr>
                      <td>1/4 x 3/4</td>
                      <td>6.4 x 19.1</td>
                      <td>0.950</td>
                      <td>5.79</td>
                    </tr>
                    <tr>
                      <td>1/4 x 1</td>
                      <td>6.4 x 25.4</td>
                      <td>1.266</td>
                      <td>7.72</td>
                    </tr>
                    <tr>
                      <td>1/4 x 1 1/4</td>
                      <td>6.4 x 31.8</td>
                      <td>1.583</td>
                      <td>9.65</td>
                    </tr>
                    <tr>
                      <td>1/4 x 1 1/2</td>
                      <td>6.4 x 38.1</td>
                      <td>1.899</td>
                      <td>11.58</td>
                    </tr>
                    <tr>
                      <td>1/4 x 2</td>
                      <td>6.4 x 50.8</td>
                      <td>2.532</td>
                      <td>15.44</td>
                    </tr>
                    <tr>
                      <td>1/4 x 2 1/2</td>
                      <td>6.4 x 63.52</td>
                      <td>3.165</td>
                      <td>19.30</td>
                    </tr>
                    <tr>
                      <td>1/4 x 3</td>
                      <td>6.4 x 76.2</td>
                      <td>3.798</td>
                      <td>23.16</td>
                    </tr>
                    <tr>
                      <td>1/4 x 3 1/2</td>
                      <td>6.4 x 88.9</td>
                      <td>4.426</td>
                      <td>27</td>
                    </tr>
                    <tr>
                      <td>1/4 x 4</td>
                      <td>6.4 x 101.6</td>
                      <td>5.064</td>
                      <td>30.89</td>
                    </tr>
                    <tr>
                      <td>1/4 x 5</td>
                      <td>6.4 x 127</td>
                      <td>6.330</td>
                      <td>38.6</td>
                    </tr>
                    <tr>
                      <td>1/4 x 6</td>
                      <td>6.4 x 152.4</td>
                      <td>7.596</td>
                      <td>46.33</td>
                    </tr>
                    <tr>
                      <td>1/4 x 7</td>
                      <td>6.4 x 177.8</td>
                      <td>8.852</td>
                      <td>54</td>
                    </tr>
                    <tr>
                      <td>1/4 x 8</td>
                      <td>6.4 x 203.2</td>
                      <td>10.128</td>
                      <td>61.78</td>
                    </tr>
                    <tr>
                      <td>1/4 x 10</td>
                      <td>6.4 x 254</td>
                      <td>12.622</td>
                      <td>77</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>5/16 x 1</td>
                      <td>7.9 x 25.4</td>
                      <td>1.583</td>
                      <td>9.65</td>
                    </tr>
                    <tr>
                      <td>5/16 x 1 1/4</td>
                      <td>7.9 x 31.8</td>
                      <td>1.978</td>
                      <td>12.06</td>
                    </tr>
                    <tr>
                      <td>5/16 x 1 1/2</td>
                      <td>7.9 x 38.1</td>
                      <td>2.377</td>
                      <td>14.5</td>
                    </tr>
                    <tr>
                      <td>5/16 x 2</td>
                      <td>7.9 x 50.8</td>
                      <td>3.165</td>
                      <td>19.30</td>
                    </tr>
                    <tr>
                      <td>5/16 x 2 1/2</td>
                      <td>7.9 x 63.5</td>
                      <td>3.957</td>
                      <td>24.13</td>
                    </tr>
                    <tr>
                      <td>5/16 x 3</td>
                      <td>7.9 x 76.2</td>
                      <td>4.748</td>
                      <td>28.96</td>
                    </tr>
                    <tr>
                      <td>5/16 x 3 1/2</td>
                      <td>7.9 x 88.9</td>
                      <td>5.573</td>
                      <td>34</td>
                    </tr>
                    <tr>
                      <td>5/16 x 4</td>
                      <td>7.9 x 101.6</td>
                      <td>6.331</td>
                      <td>38.61</td>
                    </tr>
                    <tr>
                      <td>5/16 x 5</td>
                      <td>7.9 x 127</td>
                      <td>7.914</td>
                      <td>48.27</td>
                    </tr>
                    <tr>
                      <td>5/16 x 6</td>
                      <td>7.9 x 152.4</td>
                      <td>9.496</td>
                      <td>57.93</td>
                    </tr>
                    <tr>
                      <td>5/16 x 7</td>
                      <td>7.9 x 177.8</td>
                      <td>11.065</td>
                      <td>67.5</td>
                    </tr>
                    <tr>
                      <td>5/16 x 8</td>
                      <td>7.9 x 203.2</td>
                      <td>12.262</td>
                      <td>77</td>
                    </tr>
                    <tr>
                      <td>5/16 x 10</td>
                      <td>7.9 x 254.0</td>
                      <td>15.737</td>
                      <td>96</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>3/8 x 1</td>
                      <td>9.5 x 25.4</td>
                      <td>1.899</td>
                      <td>11.59</td>
                    </tr>
                    <tr>
                      <td>3/8 x 1 /14</td>
                      <td>9.5 x 31.8</td>
                      <td>2.377</td>
                      <td>14.5</td>
                    </tr>
                    <tr>
                      <td>3/8 x 1 1/2</td>
                      <td>9.5 x 38.1</td>
                      <td>2.849</td>
                      <td>17.38</td>
                    </tr>
                    <tr>
                      <td>3/8 x 2</td>
                      <td>9.5 x 50.8</td>
                      <td>3.798</td>
                      <td>23.17</td>
                    </tr>
                    <tr>
                      <td>3/8 x 2 1/2</td>
                      <td>9.5 x 63.5</td>
                      <td>4.748</td>
                      <td>28.97</td>
                    </tr>
                    <tr>
                      <td>3/8 x 3</td>
                      <td>9.5 x 76.2</td>
                      <td>5.698</td>
                      <td>34.76</td>
                    </tr>
                    <tr>
                      <td>3/8 x 3 1/2</td>
                      <td>9.5 x 88.9</td>
                      <td>6.721</td>
                      <td>41</td>
                    </tr>
                    <tr>
                      <td>3/8 x 4</td>
                      <td>9.5 x 101.6</td>
                      <td>7.597</td>
                      <td>46.35</td>
                    </tr>
                    <tr>
                      <td>3/8 x 5</td>
                      <td>9.5 x 127</td>
                      <td>9.496</td>
                      <td>57.93</td>
                    </tr>
                    <tr>
                      <td>3/8 x 6</td>
                      <td>9.5 x 152.4</td>
                      <td>11.393</td>
                      <td>69..5</td>
                    </tr>
                    <tr>
                      <td>3/8 x 7</td>
                      <td>9.5 x 177.8</td>
                      <td>13.196</td>
                      <td>80.5</td>
                    </tr>
                    <tr>
                      <td>3/8 x 8</td>
                      <td>9.5 x 203.2</td>
                      <td>15.280</td>
                      <td>93.21</td>
                    </tr>
                    <tr>
                      <td>3/8 x 9</td>
                      <td>9.5 x 228.6</td>
                      <td>17.049</td>
                      <td>104</td>
                    </tr>
                    <tr>
                      <td>3/8 x 10</td>
                      <td>9.5 x 254</td>
                      <td>19.016</td>
                      <td>116</td>
                    </tr>
                    <tr>
                      <td>3/8 x 12</td>
                      <td>9.5 x 304.8</td>
                      <td>22.786</td>
                      <td>139</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1/2 x 1</td>
                      <td>12.7 x 25.4</td>
                      <td>2.532</td>
                      <td>15.2</td>
                    </tr>
                    <tr>
                      <td>1/2 x 1 1/4</td>
                      <td>12.7 x 31.8</td>
                      <td>3.165</td>
                      <td>19.31</td>
                    </tr>
                    <tr>
                      <td>1/2 x 1 1/2</td>
                      <td>12.7 x 38.1</td>
                      <td>3.798</td>
                      <td>23.17</td>
                    </tr>
                    <tr>
                      <td>1/2 x 2</td>
                      <td>12.7 x 50.8</td>
                      <td>5.064</td>
                      <td>30.89</td>
                    </tr>
                    <tr>
                      <td>1/2 x 2 1/2</td>
                      <td>12.7 x 63.5</td>
                      <td>6.331</td>
                      <td>38.62</td>
                    </tr>
                    <tr>
                      <td>1/2 x 3</td>
                      <td>12.7 x 76.2</td>
                      <td>7.597</td>
                      <td>46.35</td>
                    </tr>
                    <tr>
                      <td>1/2 x 3 1/2</td>
                      <td>12.7 x 88.9</td>
                      <td>8.852</td>
                      <td>54</td>
                    </tr>
                    <tr>
                      <td>1/2 x 4</td>
                      <td>12.7 x 101.6</td>
                      <td>10.129</td>
                      <td>61.79</td>
                    </tr>
                    <tr>
                      <td>1/2 x 5</td>
                      <td>12.7 x 127</td>
                      <td>12.662</td>
                      <td>77.24</td>
                    </tr>
                    <tr>
                      <td>1/2 x 6</td>
                      <td>12.7 x 152.4</td>
                      <td>15.194</td>
                      <td>92.69</td>
                    </tr>
                    <tr>
                      <td>1/2 x 7</td>
                      <td>12.7 x 177.8</td>
                      <td>17.704</td>
                      <td>108</td>
                    </tr>
                    <tr>
                      <td>1/2 x 8</td>
                      <td>12.7 x 203.2</td>
                      <td>20.260</td>
                      <td>123.59</td>
                    </tr>
                    <tr>
                      <td>1/2 x 9</td>
                      <td>12.7 x 228.6</td>
                      <td>22.786</td>
                      <td>139</td>
                    </tr>
                    <tr>
                      <td>1/2 x 10</td>
                      <td>12.7 x 254</td>
                      <td>25.245</td>
                      <td>154</td>
                    </tr>
                    <tr>
                      <td>1/2 x 12</td>
                      <td>12.7 x 304.8</td>
                      <td>30.327</td>
                      <td>185</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>5/8 x 1</td>
                      <td>15.9 x 25.4</td>
                      <td>3.165</td>
                      <td>19.31</td>
                    </tr>
                    <tr>
                      <td>5/8 x 1 1/4</td>
                      <td>15.9 x 31.8</td>
                      <td>3.957</td>
                      <td>24.02</td>
                    </tr>
                    <tr>
                      <td>5/8 x 1 1/2</td>
                      <td>15.9 x 38.1</td>
                      <td>4.754</td>
                      <td>29</td>
                    </tr>
                    <tr>
                      <td>5/8 x 2</td>
                      <td>15.9 x 50.8</td>
                      <td>6.331</td>
                      <td>38.62</td>
                    </tr>
                    <tr>
                      <td>5/8 x 2 1/2</td>
                      <td>15.9 x 63.5</td>
                      <td>7.913</td>
                      <td>48.27</td>
                    </tr>
                    <tr>
                      <td>5/8 x 3</td>
                      <td>15.9 x 76.2</td>
                      <td>9.496</td>
                      <td>57.93</td>
                    </tr>
                    <tr>
                      <td>5/8 x 4</td>
                      <td>15.9 x 101.6</td>
                      <td>12.661</td>
                      <td>77.24</td>
                    </tr>
                    <tr>
                      <td>5/8 x 5</td>
                      <td>15.9 x 127</td>
                      <td>15.826</td>
                      <td>96.54</td>
                    </tr>
                    <tr>
                      <td>5/8 x 6</td>
                      <td>15.9 x 152.4</td>
                      <td>18.992</td>
                      <td>115.86</td>
                    </tr>
                    <tr>
                      <td>5/8 x 7</td>
                      <td>15.9 x 177.8</td>
                      <td>22.131</td>
                      <td>135</td>
                    </tr>
                    <tr>
                      <td>5/8 x 8</td>
                      <td>15.9 x 203.2</td>
                      <td>25.327</td>
                      <td>154.5</td>
                    </tr>
                    <tr>
                      <td>5/8 x 9</td>
                      <td>15.9 x 228.6</td>
                      <td>28.524</td>
                      <td>174</td>
                    </tr>
                    <tr>
                      <td>5/8 x 10</td>
                      <td>15.9 x 254</td>
                      <td>31.639</td>
                      <td>193</td>
                    </tr>
                    <tr>
                      <td>5/8 x 12</td>
                      <td>15.9 x 304.8</td>
                      <td>38.032</td>
                      <td>232</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>3/4 x 1 1/2</td>
                      <td>19.1 x 38.1</td>
                      <td>5.698</td>
                      <td>34.76</td>
                    </tr>
                    <tr>
                      <td>3/4 x 2</td>
                      <td>19.1 x 50.8</td>
                      <td>7.597</td>
                      <td>46.35</td>
                    </tr>
                    <tr>
                      <td>3/4 x 2 1/2</td>
                      <td>19.1 x 63.5</td>
                      <td>9.496</td>
                      <td>57.93</td>
                    </tr>
                    <tr>
                      <td>3/4 x 3</td>
                      <td>19.1 x 76.2</td>
                      <td>11.393</td>
                      <td>69.5</td>
                    </tr>
                    <tr>
                      <td>3/4 x 4</td>
                      <td>19.1 x 101.6</td>
                      <td>15.194</td>
                      <td>92.69</td>
                    </tr>
                    <tr>
                      <td>3/4 x 5</td>
                      <td>19.1 x 127</td>
                      <td>18.992</td>
                      <td>115.86</td>
                    </tr>
                    <tr>
                      <td>3/4 x 6</td>
                      <td>19.1 x 152.4</td>
                      <td>22.790</td>
                      <td>139.02</td>
                    </tr>
                    <tr>
                      <td>3/4 x 7</td>
                      <td>19.1 x 177.8</td>
                      <td>26.557</td>
                      <td>162</td>
                    </tr>
                    <tr>
                      <td>3/4 x 8</td>
                      <td>19.1 x 203.2</td>
                      <td>30.338</td>
                      <td>185.37</td>
                    </tr>
                    <tr>
                      <td>3/4 x 9</td>
                      <td>19.1 x 228.6</td>
                      <td>34.262</td>
                      <td>209</td>
                    </tr>
                    <tr>
                      <td>3/4 x 10</td>
                      <td>19.1 x 254</td>
                      <td>38.032</td>
                      <td>232</td>
                    </tr>
                    <tr>
                      <td>3/4 x 12</td>
                      <td>19.1 x 304.8</td>
                      <td>45.57</td>
                      <td>278</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="tablas table-responsive mt-5" v-show="soleraDos">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1/4 x 2</td>
                      <td>6.4 x 50.8</td>
                      <td>2.54</td>
                      <td>15.5</td>
                    </tr>
                    <tr>
                      <td colspan="4"></td>
                    </tr>
                    <tr>
                      <td>1 x 1 1/2</td>
                      <td>25.4 x 38.1</td>
                      <td>7.704</td>
                      <td>47</td>
                    </tr>
                    <tr>
                      <td>1 x 2</td>
                      <td>25.4 x 50.8</td>
                      <td>10.29</td>
                      <td>62.77</td>
                    </tr>
                    <tr>
                      <td>1 x 2 1/2</td>
                      <td>25.4 x 76.2</td>
                      <td>15.194</td>
                      <td>77.24</td>
                    </tr>
                    <tr>
                      <td>1 x 3</td>
                      <td>25.4 x 76.2</td>
                      <td>15.194</td>
                      <td>92.69</td>
                    </tr>
                    <tr>
                      <td>1 x 4</td>
                      <td>25.4 x 101.6</td>
                      <td>20.758</td>
                      <td>126.63</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td colspan="2">Dimensiones</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>Pulgadas</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1 x 5</td>
                      <td>25.4 x 127</td>
                      <td>25.322</td>
                      <td>154.47</td>
                    </tr>
                    <tr>
                      <td>1 x 6</td>
                      <td>25.4 x 152.4</td>
                      <td>30.388</td>
                      <td>185.37</td>
                    </tr>
                    <tr>
                      <td>1 x 7</td>
                      <td>25.4 x 177.8</td>
                      <td>35.409</td>
                      <td>216</td>
                    </tr>
                    <tr>
                      <td>1 x 8</td>
                      <td>25.4 x 203.2</td>
                      <td>40.491</td>
                      <td>247</td>
                    </tr>
                    <tr>
                      <td>1 x 9</td>
                      <td>25.4 x 228.6</td>
                      <td>45.573</td>
                      <td>278</td>
                    </tr>
                    <tr>
                      <td>1 x 10</td>
                      <td>25.4 x 254</td>
                      <td>50.655</td>
                      <td>309</td>
                    </tr>
                    <tr>
                      <td>1 x 12</td>
                      <td>25.4 x 304.8</td>
                      <td>60.819</td>
                      <td>371</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Tablero Galvanizado'">
            <div class="tablas table-responsive">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-06">
                    <tr>
                      <td>Tablero</td>
                      <td>Calibre</td>
                      <td>Ancho</td>
                      <td>Largo</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>.915</td>
                      <td>.915</td>
                      <td>6.727</td>
                      <td>6.122</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>.915</td>
                      <td>1.83</td>
                      <td>6.727</td>
                      <td>12.311</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>.915</td>
                      <td>2.13</td>
                      <td>6.727</td>
                      <td>14.328</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>.915</td>
                      <td>2.44</td>
                      <td>6.727</td>
                      <td>16.414</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>.915</td>
                      <td>3.05</td>
                      <td>6.727</td>
                      <td>20.518</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-06">
                    <tr>
                      <td>Tablero</td>
                      <td>Calibre</td>
                      <td>Ancho</td>
                      <td>Largo</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>1.22</td>
                      <td>.915</td>
                      <td>6.727</td>
                      <td>7.5</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>1.22</td>
                      <td>1.22</td>
                      <td>6.727</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>1.22</td>
                      <td>1.83</td>
                      <td>6.727</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>1.22</td>
                      <td>2.13</td>
                      <td>6.727</td>
                      <td>17.5</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>1.22</td>
                      <td>2.44</td>
                      <td>6.727</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>ZT 148</td>
                      <td>20</td>
                      <td>1.22</td>
                      <td>3.05</td>
                      <td>6.727</td>
                      <td>25</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Tee Estructural'">
            <div class="tablas table-responsive">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td>Medida</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA. 6.00M.</td>
                    </tr>
                    <tr>
                      <td>1/8 x 3/4</td>
                      <td>3 x 19</td>
                      <td>1.000</td>
                      <td>6.00</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-04">
                    <tr>
                      <td>Medida</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA. 6.00M.</td>
                    </tr>
                    <tr>
                      <td>1/8 x 1</td>
                      <td>3 x 25</td>
                      <td>1.333</td>
                      <td>8.00</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Tira para Cortinas'">
            <div class="tablas table-responsive">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-03">
                    <tr>
                      <td>Longitud</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>2.05</td>
                      <td>0.72</td>
                      <td>1.47</td>
                    </tr>
                    <tr>
                      <td>2.55</td>
                      <td>0.72</td>
                      <td>1.83</td>
                    </tr>
                    <tr>
                      <td>3.05</td>
                      <td>0.72</td>
                      <td>2.196</td>
                    </tr>
                    <tr>
                      <td>3.55</td>
                      <td>0.72</td>
                      <td>2.55</td>
                    </tr>
                    <tr>
                      <td>4.05</td>
                      <td>0.72</td>
                      <td>2.91</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-03">
                    <tr>
                      <td>Longitud</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>4.55</td>
                      <td>0.72</td>
                      <td>3.27</td>
                    </tr>
                    <tr>
                      <td>5.05</td>
                      <td>0.72</td>
                      <td>3.63</td>
                    </tr>
                    <tr>
                      <td>5.55</td>
                      <td>0.72</td>
                      <td>3.99</td>
                    </tr>
                    <tr>
                      <td>6.10</td>
                      <td>0.72</td>
                      <td>4.39</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Tubo'">
            <div class="tab-pro">
              <div class="item" @click="proTuboUno()" :class="{ active: tuboUno }">Tubo cédula 30 </div>
              <div class="item" @click="proTuboDos()" :class="{ active: tuboDos }">Tubo cédula 40 </div>
              <div class="item" @click="proTuboTres()" :class="{ active: tuboTres }">Tubo galvanizado cédula 40-L200
              </div>
              <div class="item" @click="proTuboCuatro()" :class="{ active: tuboCuatro }">Tubo negro </div>
            </div>
            <div class="tablas table-responsive mt-5" v-show="tuboUno">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-06">
                    <tr>
                      <td colspan="2">Diámetro Nominal</td>
                      <td colspan="2">Espesor de pared</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1/2</td>
                      <td>21.3</td>
                      <td>0.109</td>
                      <td>2.77</td>
                      <td>0.91</td>
                      <td>5.5</td>
                    </tr>
                    <tr>
                      <td>3/4</td>
                      <td>26.7</td>
                      <td>0.113</td>
                      <td>2.87</td>
                      <td>1.16</td>
                      <td>7</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>33.4</td>
                      <td>0.133</td>
                      <td>3.38</td>
                      <td>1.5</td>
                      <td>9</td>
                    </tr>
                    <tr>
                      <td>1 1/4</td>
                      <td>42.2</td>
                      <td>0.140</td>
                      <td>3.56</td>
                      <td>2.33</td>
                      <td>14</td>
                    </tr>
                    <tr>
                      <td>1 1/2</td>
                      <td>48.3</td>
                      <td>0.145</td>
                      <td>3.68</td>
                      <td>2.66</td>
                      <td>16</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>60.3</td>
                      <td>0.154</td>
                      <td>3.91</td>
                      <td>4</td>
                      <td>24</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-06">
                    <tr>
                      <td colspan="2">Diámetro Nominal</td>
                      <td colspan="2">Espesor de pared</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>2 1/2</td>
                      <td>73.0</td>
                      <td>0.203</td>
                      <td>5.16</td>
                      <td>4.83</td>
                      <td>29</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>88.9</td>
                      <td>0.216</td>
                      <td>5.49</td>
                      <td>6.66</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>3 1/2</td>
                      <td>101.6</td>
                      <td>0.226</td>
                      <td>5.74</td>
                      <td>7.5</td>
                      <td>45</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>114.3</td>
                      <td>0.237</td>
                      <td>6.02</td>
                      <td>8.5</td>
                      <td>51</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>168.3</td>
                      <td>0.258</td>
                      <td>6.35</td>
                      <td>19.1</td>
                      <td>115</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>219.1</td>
                      <td>0.322</td>
                      <td>6.35</td>
                      <td>33.33</td>
                      <td>200</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="tablas table-responsive mt-5" v-show="tuboDos">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-06">
                    <tr>
                      <td colspan="2">Diámetro Nominal</td>
                      <td colspan="2">Espesor de pared</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>1/2</td>
                      <td>21.3</td>
                      <td>0.109</td>
                      <td>2.77</td>
                      <td>1.27</td>
                      <td>8.13</td>
                    </tr>
                    <tr>
                      <td>3/4</td>
                      <td>26.7</td>
                      <td>0.113</td>
                      <td>2.87</td>
                      <td>1.69</td>
                      <td>10.82</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>33.4</td>
                      <td>0.133</td>
                      <td>3.38</td>
                      <td>2.5</td>
                      <td>16</td>
                    </tr>
                    <tr>
                      <td>1 1/4</td>
                      <td>42.2</td>
                      <td>0.140</td>
                      <td>3.56</td>
                      <td>3.39</td>
                      <td>21.7</td>
                    </tr>
                    <tr>
                      <td>1 1/2</td>
                      <td>48.3</td>
                      <td>0.145</td>
                      <td>3.68</td>
                      <td>4.05</td>
                      <td>25.92</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>60.3</td>
                      <td>0.154</td>
                      <td>3.91</td>
                      <td>5.43</td>
                      <td>34.81</td>
                    </tr>
                    <tr>
                      <td>2 1/2</td>
                      <td>73.0</td>
                      <td>0.203</td>
                      <td>5.16</td>
                      <td>8.62</td>
                      <td>55.23</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-06">
                    <tr>
                      <td colspan="2">Diámetro Nominal</td>
                      <td colspan="2">Espesor de pared</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>88.9</td>
                      <td>0.216</td>
                      <td>5.49</td>
                      <td>11.29</td>
                      <td>72.28</td>
                    </tr>
                    <tr>
                      <td>3 1/2</td>
                      <td>101.6</td>
                      <td>0.226</td>
                      <td>5.74</td>
                      <td>16.06</td>
                      <td>102.81</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>114.3</td>
                      <td>0.237</td>
                      <td>6.02</td>
                      <td>25.55</td>
                      <td>163.52</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>168.3</td>
                      <td>0.258</td>
                      <td>6.35</td>
                      <td>40.17</td>
                      <td>257.1</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>219.1</td>
                      <td>0.322</td>
                      <td>6.35</td>
                      <td>58.59</td>
                      <td>375</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>273.1</td>
                      <td>0.365</td>
                      <td>6.35</td>
                      <td>71.40</td>
                      <td>457</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>323.9</td>
                      <td>0.375</td>
                      <td>9.53</td>
                      <td>73.76</td>
                      <td>472</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="tablas table-responsive mt-5" v-show="tuboTres">
              <table class="table table-striped table-bordered table-responsive ta-13">
                <tr>
                  <td colspan="2">Diámetro nominal</td>
                  <td colspan="2">Diámetro exterior</td>
                  <td colspan="2">Espesor</td>
                  <td>CED. No.</td>
                  <td colspan="2">Presiones prueba hidrostática<br>ASTM A53 GA</td>
                  <td colspan="2">Peso nominal<br>extremo liso</td>
                  <td colspan="2">Sistema de embarque</td>
                </tr>
                <tr>
                  <td>PULG.</td>
                  <td>MM.</td>
                  <td>PULG.</td>
                  <td>MM.</td>
                  <td>PULG.</td>
                  <td>MM.</td>
                  <td></td>
                  <td>LB/PLG<sup>2</sup></td>
                  <td>KG./CM<sup>2</sup></td>
                  <td>LB/PIE</td>
                  <td>KG./M.</td>
                  <td>KG./PZA.</td>
                  <td>PZAS./PAQ.</td>
                </tr>
                <tr>
                  <td>1/2</td>
                  <td>13</td>
                  <td>0.840</td>
                  <td>21.3</td>
                  <td>0.090</td>
                  <td>2.29</td>
                  <td>L-200</td>
                  <td>700</td>
                  <td>49</td>
                  <td>0.70</td>
                  <td>1.07</td>
                  <td>7</td>
                  <td>120</td>
                </tr>
                <tr>
                  <td>3/4</td>
                  <td>19</td>
                  <td>1.050</td>
                  <td>26.7</td>
                  <td>0.095</td>
                  <td>2.41</td>
                  <td>L-200</td>
                  <td>700</td>
                  <td>49</td>
                  <td>0.97</td>
                  <td>1.45</td>
                  <td>9</td>
                  <td>84</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>25</td>
                  <td>1.315</td>
                  <td>33.4</td>
                  <td>0.104</td>
                  <td>2.64</td>
                  <td>L-200</td>
                  <td>700</td>
                  <td>49</td>
                  <td>1.35</td>
                  <td>2.00</td>
                  <td>13</td>
                  <td>60</td>
                </tr>
                <tr>
                  <td>1 1/4</td>
                  <td>32</td>
                  <td>1.660</td>
                  <td>42.2</td>
                  <td>0.110</td>
                  <td>2.79</td>
                  <td>L-200</td>
                  <td>1200</td>
                  <td>84</td>
                  <td>1.82</td>
                  <td>2.72</td>
                  <td>17.5</td>
                  <td>42</td>
                </tr>
                <tr>
                  <td>1 1/2</td>
                  <td>38</td>
                  <td>1.900</td>
                  <td>48.3</td>
                  <td>0.114</td>
                  <td>2.92</td>
                  <td>L-200</td>
                  <td>1200</td>
                  <td>84</td>
                  <td>2.19</td>
                  <td>3.27</td>
                  <td>21</td>
                  <td>36</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>51</td>
                  <td>2.375</td>
                  <td>60.3</td>
                  <td>0.120</td>
                  <td>3.05</td>
                  <td>L-200</td>
                  <td>1200</td>
                  <td>84</td>
                  <td>2.89</td>
                  <td>4.30</td>
                  <td>27.5</td>
                  <td>26</td>
                </tr>
                <tr>
                  <td>2 1/2</td>
                  <td>64</td>
                  <td>2.875</td>
                  <td>73.0</td>
                  <td>0.160</td>
                  <td>4.06</td>
                  <td>L-200</td>
                  <td>1950</td>
                  <td>137</td>
                  <td>4.64</td>
                  <td>6.25</td>
                  <td>40</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>76</td>
                  <td>3.500</td>
                  <td>88.9</td>
                  <td>0.170</td>
                  <td>4.32</td>
                  <td>L-200</td>
                  <td>1750</td>
                  <td>123</td>
                  <td>6.06</td>
                  <td>9.01</td>
                  <td>57.5</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>102</td>
                  <td>4.500</td>
                  <td>114.3</td>
                  <td>0.185</td>
                  <td>4.70</td>
                  <td>L-200</td>
                  <td>1500</td>
                  <td>105</td>
                  <td>8.54</td>
                  <td>12.70</td>
                  <td>81</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>0.188</td>
                  <td>4.78</td>
                  <td>L-200</td>
                  <td>1020</td>
                  <td>72</td>
                  <td>12.92</td>
                  <td>19.26</td>
                  <td>123</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>152</td>
                  <td>6.625</td>
                  <td>168.3</td>
                  <td>0.250</td>
                  <td>6.35</td>
                  <td>L-200</td>
                  <td>1360</td>
                  <td>96</td>
                  <td>17.02</td>
                  <td>25.36</td>
                  <td>162</td>
                  <td>7</td>
                </tr>
              </table>
            </div>
            <div class="tablas table-responsive mt-5" v-show="tuboCuatro">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-05">
                    <tr>
                      <td>Diámetro nominal</td>
                      <td colspan="2">Espesor de pared</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>3/16</td>
                      <td>4.8</td>
                      <td>24.50</td>
                      <td>299</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-05">
                    <tr>
                      <td>Diámetro nominal</td>
                      <td colspan="2">Espesor de pared</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>1/4</td>
                      <td>6.3</td>
                      <td>33.77</td>
                      <td>412</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>1/4</td>
                      <td>6.3</td>
                      <td>42.29</td>
                      <td>516</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>1/4</td>
                      <td>6.3</td>
                      <td>50.49</td>
                      <td>616</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-05">
                    <tr>
                      <td>Diámetro nominal</td>
                      <td colspan="2">Espesor de pared</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>5/16</td>
                      <td>7.9</td>
                      <td>43.11</td>
                      <td>526</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>5/16</td>
                      <td>7.9</td>
                      <td>50.98</td>
                      <td>622</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-05">
                    <tr>
                      <td>Diámetro nominal</td>
                      <td colspan="2">Espesor de pared</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>3/8</td>
                      <td>9.5</td>
                      <td>61.47</td>
                      <td>750</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>3/8</td>
                      <td>9.5</td>
                      <td>74.91</td>
                      <td>914</td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>3/8</td>
                      <td>9.5</td>
                      <td>82.78</td>
                      <td>1,010</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>3/8</td>
                      <td>9.5</td>
                      <td>94.59</td>
                      <td>1,154</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>3/8</td>
                      <td>9.5</td>
                      <td>105.08</td>
                      <td>1,282</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>3/8</td>
                      <td>9.5</td>
                      <td>117.04</td>
                      <td>1,428</td>
                    </tr>
                  </table>
                  <table class="table table-striped table-bordered table-responsive ta-05">
                    <tr>
                      <td>Diámetro nominal</td>
                      <td colspan="2">Espesor de pared</td>
                      <td colspan="2">Peso</td>
                    </tr>
                    <tr>
                      <td>PULG.</td>
                      <td>PULG.</td>
                      <td>MM.</td>
                      <td>KG./M.</td>
                      <td>KG./PZA.</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>1/2</td>
                      <td>12.7</td>
                      <td>99.18</td>
                      <td>1,210</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>1/2</td>
                      <td>12.7</td>
                      <td>113.00</td>
                      <td>1,378</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>1/2</td>
                      <td>12.7</td>
                      <td>125.40</td>
                      <td>1,530</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Varilla'">
            <div class="tablas table-responsive">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td colspan="7">Dimensiones y Pesos</td>
                    </tr>
                    <tr>
                      <td>Varilla No.</td>
                      <td>Medidas</td>
                      <td>Peso KG./M.</td>
                      <td>Perimetro MM.</td>
                      <td>Área CM.</td>
                      <td>Piezas TON.</td>
                    </tr>
                    <tr>
                      <td>MM.</td>
                      <td>PULG.</td>
                    </tr>
                    <tr>
                      <td>2.5</td>
                      <td>7.9</td>
                      <td>5/16</td>
                      <td>0.384</td>
                      <td>24.8</td>
                      <td>0.49</td>
                      <td>217 +- 7</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>9.5</td>
                      <td>3/8</td>
                      <td>0.557</td>
                      <td>29.8</td>
                      <td>0.71</td>
                      <td>149 +- 4</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>12.7</td>
                      <td>1/2</td>
                      <td>0.996</td>
                      <td>39.9</td>
                      <td>1.27</td>
                      <td>84 +- 2</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>15.9</td>
                      <td>5/8</td>
                      <td>1.560</td>
                      <td>50.0</td>
                      <td>1.99</td>
                      <td>54 +- 1</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-07">
                    <tr>
                      <td colspan="7">Dimensiones y Pesos</td>
                    </tr>
                    <tr>
                      <td>Varilla No.</td>
                      <td>Medidas</td>
                      <td>Peso KG./M.</td>
                      <td>Perimetro MM.</td>
                      <td>Área CM.</td>
                      <td>Piezas TON.</td>
                    </tr>
                    <tr>
                      <td>MM.</td>
                      <td>PULG.</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>19.1</td>
                      <td>3/4</td>
                      <td>2.250</td>
                      <td>60.0</td>
                      <td>2.87</td>
                      <td>37 +- 1</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>25.4</td>
                      <td>1</td>
                      <td>3.975</td>
                      <td>79.8</td>
                      <td>5.07</td>
                      <td>21</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>31.8</td>
                      <td>1 1/4</td>
                      <td>6.225</td>
                      <td>99.9</td>
                      <td>7.94</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>38.1</td>
                      <td>1 1/2</td>
                      <td>8.938</td>
                      <td>119.7</td>
                      <td>11.40</td>
                      <td>9</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="text-center">* Longitud a 9.15 y 12.20 MTS.</div>
              <div class="text-center">NORMA: NMX-C407-ONNCCE-2001 GRADO 30/42</div>
            </div>
          </template>



          <template v-else-if="productoSeleccionado === 'Viga'">
            <div class="tablas table-responsive">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-08">
                    <tr>
                      <td rowspan="2">Dimensiones PULG./MM.</td>
                      <td rowspan="2">LBS./M.</td>
                      <td rowspan="2">KG./M.</td>
                      <td rowspan="2">KG./PZA.</td>
                      <td rowspan="2">Peralte MM.</td>
                      <td colspan="2">Patín</td>
                      <td rowspan="2">Espesor del alma (MM.)</td>
                    </tr>
                    <tr>
                      <td>Ancho (MM.)</td>
                      <td>Espesor (MM.)</td>
                    </tr>
                    <tr>
                      <td rowspan="4">6x4<br>152.4x101.6</td>
                      <td>8.5</td>
                      <td>12.67</td>
                      <td>154.57</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>13.4</td>
                      <td>163.48</td>
                      <td>150</td>
                      <td>100</td>
                      <td>5.4</td>
                      <td>4.3</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>17.9</td>
                      <td>217.84</td>
                      <td>153</td>
                      <td>102</td>
                      <td>7.1</td>
                      <td>5.8</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>23.8</td>
                      <td>290.46</td>
                      <td>160</td>
                      <td>102</td>
                      <td>10.3</td>
                      <td>6.6</td>
                    </tr>
                    <tr>
                      <td rowspan="3">6x6<br>152.4x101.4</td>
                      <td>15</td>
                      <td>22.3</td>
                      <td>272</td>
                      <td>152</td>
                      <td>152</td>
                      <td>6.6</td>
                      <td>5.8</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>29.7</td>
                      <td>363</td>
                      <td>157</td>
                      <td>153</td>
                      <td>9.3</td>
                      <td>6.6</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>37.2</td>
                      <td>454</td>
                      <td>162</td>
                      <td>154</td>
                      <td>11.6</td>
                      <td>8.1</td>
                    </tr>
                    <tr>
                      <td rowspan="3">8x4<br>203.2x101.4</td>
                      <td>10</td>
                      <td>14.9</td>
                      <td>181.54</td>
                      <td>201</td>
                      <td>100</td>
                      <td>5.2</td>
                      <td>4.3</td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>19.4</td>
                      <td>236</td>
                      <td>203</td>
                      <td>102</td>
                      <td>8.0</td>
                      <td>5.8</td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>22.4</td>
                      <td>273.3</td>
                      <td>206</td>
                      <td>102</td>
                      <td>8.0</td>
                      <td>6.2</td>
                    </tr>
                    <tr>
                      <td rowspan="3">8x5 1/4<br>203.2x133.4</td>
                      <td>14</td>
                      <td>21.0</td>
                      <td>256</td>
                      <td>203</td>
                      <td>133</td>
                      <td>5</td>
                      <td>6.4</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>26.9</td>
                      <td>328.18</td>
                      <td>206.75</td>
                      <td>133.35</td>
                      <td>8.38</td>
                      <td>5.84</td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>31.3</td>
                      <td>381.23</td>
                      <td>210.31</td>
                      <td>133.85</td>
                      <td>10.16</td>
                      <td>6.35</td>
                    </tr>
                    <tr>
                      <td rowspan="2">8x6 1/2<br>203.4x165.1</td>
                      <td>24</td>
                      <td>35.7</td>
                      <td>36.76</td>
                      <td>165</td>
                      <td>181</td>
                      <td>6.2</td>
                      <td>10.2</td>
                    </tr>
                    <tr>
                      <td>28</td>
                      <td>41.7</td>
                      <td>508.74</td>
                      <td>205</td>
                      <td>166</td>
                      <td>11.8</td>
                      <td>7.24</td>
                    </tr>
                    <tr>
                      <td rowspan="6">8x8<br>203.4x203.4</td>
                      <td>31</td>
                      <td>46.2</td>
                      <td>562.42</td>
                      <td>203</td>
                      <td>203</td>
                      <td>11</td>
                      <td>7.2</td>
                    </tr>
                    <tr>
                      <td>35</td>
                      <td>52.1</td>
                      <td>635.62</td>
                      <td>206</td>
                      <td>204</td>
                      <td>12.6</td>
                      <td>7.9</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>59.6</td>
                      <td>727.2</td>
                      <td>210</td>
                      <td>205</td>
                      <td>14.2</td>
                      <td>9.1</td>
                    </tr>
                    <tr>
                      <td>48</td>
                      <td>71.4</td>
                      <td>871.08</td>
                      <td>216</td>
                      <td>206</td>
                      <td>17.4</td>
                      <td>10.16</td>
                    </tr>
                    <tr>
                      <td>58</td>
                      <td>86.3</td>
                      <td>1,052.86</td>
                      <td>222</td>
                      <td>209</td>
                      <td>20.6</td>
                      <td>12.95</td>
                    </tr>
                    <tr>
                      <td>67</td>
                      <td>99.7</td>
                      <td>1,216.34</td>
                      <td>229</td>
                      <td>210</td>
                      <td>23.7</td>
                      <td>14.48</td>
                    </tr>
                    <tr>
                      <td rowspan="4">10x4<br>254x101.6</td>
                      <td>12</td>
                      <td>17.9</td>
                      <td>217.86</td>
                      <td>251</td>
                      <td>100</td>
                      <td>5.2</td>
                      <td>4.6</td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>22.4</td>
                      <td>272.33</td>
                      <td>254</td>
                      <td>102</td>
                      <td>6.8</td>
                      <td>5.8</td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>25.5</td>
                      <td>311.10</td>
                      <td>257</td>
                      <td>102</td>
                      <td>8.4</td>
                      <td>6.1</td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>28.3</td>
                      <td>344.92</td>
                      <td>260</td>
                      <td>102</td>
                      <td>10</td>
                      <td>6.4</td>
                    </tr>
                    <tr>
                      <td rowspan="4">10x5 3/4<br>254x146</td>
                      <td>16</td>
                      <td>24</td>
                      <td>292.8</td>
                      <td>256.0</td>
                      <td>146.0</td>
                      <td>5</td>
                      <td>6.4</td>
                    </tr>
                    <tr>
                      <td>22</td>
                      <td>32.8</td>
                      <td>399.38</td>
                      <td>258.31</td>
                      <td>146.5</td>
                      <td>9.14</td>
                      <td>6.09</td>
                    </tr>
                    <tr>
                      <td>26</td>
                      <td>38.7</td>
                      <td>471.99</td>
                      <td>262.38</td>
                      <td>146.55</td>
                      <td>11.17</td>
                      <td>6.60</td>
                    </tr>
                    <tr>
                      <td>30</td>
                      <td>44.7</td>
                      <td>544.67</td>
                      <td>265.93</td>
                      <td>147.57</td>
                      <td>12.95</td>
                      <td>7.62</td>
                    </tr>
                    <tr>
                      <td rowspan="3">10x8<br>254x203.2</td>
                      <td>33</td>
                      <td>49.1</td>
                      <td>599.02</td>
                      <td>247</td>
                      <td>202</td>
                      <td>11</td>
                      <td>7.4</td>
                    </tr>
                    <tr>
                      <td>39</td>
                      <td>58.1</td>
                      <td>708.82</td>
                      <td>252</td>
                      <td>203</td>
                      <td>13.50</td>
                      <td>8.0</td>
                    </tr>
                    <tr>
                      <td>45</td>
                      <td>67.0</td>
                      <td>817.40</td>
                      <td>257</td>
                      <td>204</td>
                      <td>15.7</td>
                      <td>8.89</td>
                    </tr>
                    <tr>
                      <td rowspan="8">10x10<br>245x245</td>
                      <td>48</td>
                      <td>72.9</td>
                      <td>889.38</td>
                      <td>253</td>
                      <td>254</td>
                      <td>14.20</td>
                      <td>8.6</td>
                    </tr>
                    <tr>
                      <td>54</td>
                      <td>80.4</td>
                      <td>980.88</td>
                      <td>256</td>
                      <td>255</td>
                      <td>15.60</td>
                      <td>9.4</td>
                    </tr>
                    <tr>
                      <td>60</td>
                      <td>89.4</td>
                      <td>1,090.68</td>
                      <td>260</td>
                      <td>256</td>
                      <td>17.30</td>
                      <td>10.7</td>
                    </tr>
                    <tr>
                      <td>68</td>
                      <td>101.2</td>
                      <td>1,234.64</td>
                      <td>264</td>
                      <td>257</td>
                      <td>19.6</td>
                      <td>11.94</td>
                    </tr>
                    <tr>
                      <td>77</td>
                      <td>114.7</td>
                      <td>1,400</td>
                      <td>264</td>
                      <td>257</td>
                      <td>19.6</td>
                      <td>11.9</td>
                    </tr>
                    <tr>
                      <td>88</td>
                      <td>131.0</td>
                      <td>1,598.20</td>
                      <td>275</td>
                      <td>261</td>
                      <td>25.1</td>
                      <td>15.37</td>
                    </tr>
                    <tr>
                      <td>100</td>
                      <td>148.8</td>
                      <td>1,815.36</td>
                      <td>282</td>
                      <td>263</td>
                      <td>28.4</td>
                      <td>17.27</td>
                    </tr>
                    <tr>
                      <td>112</td>
                      <td>166.7</td>
                      <td>2,033.74</td>
                      <td>289</td>
                      <td>265</td>
                      <td>31.8</td>
                      <td>19.18</td>
                    </tr>
                    <tr>
                      <td rowspan="4">12x4<br>304.8x101.6</td>
                      <td>14</td>
                      <td>20.9</td>
                      <td>254.15</td>
                      <td>302</td>
                      <td>100</td>
                      <td>5.7</td>
                      <td>5.1</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>23.8</td>
                      <td>290.48</td>
                      <td>304.54</td>
                      <td>101.34</td>
                      <td>6.73</td>
                      <td>5.58</td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>28.3</td>
                      <td>344.92</td>
                      <td>309</td>
                      <td>102</td>
                      <td>8.9</td>
                      <td>6.1</td>
                    </tr>
                    <tr>
                      <td>22</td>
                      <td>32.8</td>
                      <td>399.38</td>
                      <td>313</td>
                      <td>102</td>
                      <td>10.8</td>
                      <td>6.6</td>
                    </tr>
                    <tr>
                      <td rowspan="4">12x6 1/2<br>304.8x165.1</td>
                      <td>21</td>
                      <td>31.29</td>
                      <td>381.73</td>
                      <td>308</td>
                      <td>164</td>
                      <td>7.4</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>26</td>
                      <td>38.7</td>
                      <td>471.99</td>
                      <td>310.38</td>
                      <td>16484</td>
                      <td>9.65</td>
                      <td>5.84</td>
                    </tr>
                    <tr>
                      <td>30</td>
                      <td>44.7</td>
                      <td>544.67</td>
                      <td>313.43</td>
                      <td>165.60</td>
                      <td>11.17</td>
                      <td>6.60</td>
                    </tr>
                    <tr>
                      <td>35</td>
                      <td>52.2</td>
                      <td>635.45</td>
                      <td>317.50</td>
                      <td>166.62</td>
                      <td>13.20</td>
                      <td>7.62</td>
                    </tr>
                    <tr>
                      <td rowspan="5">12x8<br>304.8x203.2</td>
                      <td>40</td>
                      <td>59.6</td>
                      <td>726.22</td>
                      <td>303</td>
                      <td>203</td>
                      <td>13.1</td>
                      <td>7.5</td>
                    </tr>
                    <tr>
                      <td>45</td>
                      <td>67.1</td>
                      <td>818.6</td>
                      <td>306</td>
                      <td>204</td>
                      <td>14.6</td>
                      <td>8.5</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>74.5</td>
                      <td>908.9</td>
                      <td>309</td>
                      <td>205</td>
                      <td>16.3</td>
                      <td>9.4</td>
                    </tr>
                    <tr>
                      <td>58</td>
                      <td>86.4</td>
                      <td>1,054</td>
                      <td>311</td>
                      <td>206</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>65</td>
                      <td>96.8</td>
                      <td>1,180.90</td>
                      <td>315</td>
                      <td>207</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td rowspan="2">12x10<br>304.8x254</td>
                      <td>53</td>
                      <td>78.9</td>
                      <td>962.58</td>
                      <td>306</td>
                      <td>254</td>
                      <td>14.6</td>
                      <td>876</td>
                    </tr>
                    <tr>
                      <td>58</td>
                      <td>86.3</td>
                      <td>1,052.86</td>
                      <td>310</td>
                      <td>254</td>
                      <td>16.3</td>
                      <td>9.14</td>
                    </tr>
                    <tr>
                      <td rowspan="8">12x12<br>304.8x304.8</td>
                      <td>65</td>
                      <td>96.8</td>
                      <td>1,180.9</td>
                      <td>308</td>
                      <td>305</td>
                      <td>15.4</td>
                      <td>9.9</td>
                    </tr>
                    <tr>
                      <td>72</td>
                      <td>107.1</td>
                      <td>1,306.62</td>
                      <td>311</td>
                      <td>306</td>
                      <td>17.0</td>
                      <td>10.92</td>
                    </tr>
                    <tr>
                      <td>79</td>
                      <td>117.5</td>
                      <td>1,433.5</td>
                      <td>314</td>
                      <td>307</td>
                      <td>18.7</td>
                      <td>11.9</td>
                    </tr>
                    <tr>
                      <td>87</td>
                      <td>129.5</td>
                      <td>1,579.9</td>
                      <td>318</td>
                      <td>308</td>
                      <td>20.6</td>
                      <td>13.08</td>
                    </tr>
                    <tr>
                      <td>96</td>
                      <td>142.9</td>
                      <td>1,743.38</td>
                      <td>323</td>
                      <td>309</td>
                      <td>22.9</td>
                      <td>13.97</td>
                    </tr>
                    <tr>
                      <td>106</td>
                      <td>157.7</td>
                      <td>1,923.94</td>
                      <td>327</td>
                      <td>310</td>
                      <td>25.1</td>
                      <td>15.49</td>
                    </tr>
                    <tr>
                      <td>120</td>
                      <td>178.6</td>
                      <td>2,178.92</td>
                      <td>333</td>
                      <td>313</td>
                      <td>28.1</td>
                      <td>18.03</td>
                    </tr>
                    <tr>
                      <td>136</td>
                      <td>202.6</td>
                      <td>2,472.0</td>
                      <td>341</td>
                      <td>315</td>
                      <td>31.8</td>
                      <td>20.1</td>
                    </tr>
                    <tr>
                      <td rowspan="9">12x12 1/2<br>304.8x317.50</td>
                      <td>152</td>
                      <td>226.2</td>
                      <td>2,759.64</td>
                      <td>348</td>
                      <td>317</td>
                      <td>35.6</td>
                      <td>22.10</td>
                    </tr>
                    <tr>
                      <td>170</td>
                      <td>253.0</td>
                      <td>3,086.60</td>
                      <td>356</td>
                      <td>319</td>
                      <td>39.6</td>
                      <td>24.38</td>
                    </tr>
                    <tr>
                      <td>190</td>
                      <td>282.8</td>
                      <td>3,450.16</td>
                      <td>365</td>
                      <td>322</td>
                      <td>44.1</td>
                      <td>26.92</td>
                    </tr>
                    <tr>
                      <td>210</td>
                      <td>312.5</td>
                      <td>3,812.50</td>
                      <td>374</td>
                      <td>325</td>
                      <td>48.3</td>
                      <td>29.97</td>
                    </tr>
                    <tr>
                      <td>230</td>
                      <td>342.3</td>
                      <td>4,176.06</td>
                      <td>382</td>
                      <td>328</td>
                      <td>52.6</td>
                      <td>32.64</td>
                    </tr>
                    <tr>
                      <td>252</td>
                      <td>375.0</td>
                      <td>4,575</td>
                      <td>391</td>
                      <td>330</td>
                      <td>57.2</td>
                      <td>35.43</td>
                    </tr>
                    <tr>
                      <td>279</td>
                      <td>415.2</td>
                      <td>5,065.44</td>
                      <td>403</td>
                      <td>334</td>
                      <td>62.7</td>
                      <td>38.86</td>
                    </tr>
                    <tr>
                      <td>305</td>
                      <td>53.9</td>
                      <td>5,537.58</td>
                      <td>415</td>
                      <td>336</td>
                      <td>68.7</td>
                      <td>41.28</td>
                    </tr>
                    <tr>
                      <td>336</td>
                      <td>500.0</td>
                      <td>6,100</td>
                      <td>427</td>
                      <td>340</td>
                      <td>75.1</td>
                      <td>45.09</td>
                    </tr>
                    <tr>
                      <td rowspan="2">14x5<br>355.6x171.45</td>
                      <td>22</td>
                      <td>32.8</td>
                      <td>400</td>
                      <td>349</td>
                      <td>127</td>
                      <td>8.5</td>
                      <td>5.8</td>
                    </tr>
                    <tr>
                      <td>26</td>
                      <td>38.7</td>
                      <td>472.1</td>
                      <td>353</td>
                      <td>128</td>
                      <td>10.7</td>
                      <td>6.5</td>
                    </tr>
                    <tr>
                      <td rowspan="3">14x6 3/4<br>355.6x171.45</td>
                      <td>30</td>
                      <td>44.7</td>
                      <td>545.34</td>
                      <td>352</td>
                      <td>171</td>
                      <td>9.7</td>
                      <td>6.9</td>
                    </tr>
                    <tr>
                      <td>34</td>
                      <td>50.7</td>
                      <td>618.5</td>
                      <td>356</td>
                      <td>171</td>
                      <td>11.5</td>
                      <td>7.3</td>
                    </tr>
                    <tr>
                      <td>38</td>
                      <td>56.6</td>
                      <td>690.5</td>
                      <td>359</td>
                      <td>172</td>
                      <td>13.0</td>
                      <td>7.9</td>
                    </tr>
                    <tr>
                      <td rowspan="3">14x8<br>355.6x203.2</td>
                      <td>43</td>
                      <td>64.1</td>
                      <td>782</td>
                      <td>347</td>
                      <td>203</td>
                      <td>13.4</td>
                      <td>7.8</td>
                    </tr>
                    <tr>
                      <td>48</td>
                      <td>71.5</td>
                      <td>872.3</td>
                      <td>351</td>
                      <td>204</td>
                      <td>15.1</td>
                      <td>8.6</td>
                    </tr>
                    <tr>
                      <td>53</td>
                      <td>79</td>
                      <td>963.8</td>
                      <td>354</td>
                      <td>205</td>
                      <td>16.7</td>
                      <td>9.4</td>
                    </tr>
                    <tr>
                      <td rowspan="5">14x10<br>355.6x254</td>
                      <td>61</td>
                      <td>90.8</td>
                      <td>1,107.7</td>
                      <td>353</td>
                      <td>254</td>
                      <td>16.4</td>
                      <td>9.5</td>
                    </tr>
                    <tr>
                      <td>68</td>
                      <td>101.3</td>
                      <td>1,235.8</td>
                      <td>357</td>
                      <td>255</td>
                      <td>18.3</td>
                      <td>10.5</td>
                    </tr>
                    <tr>
                      <td>74</td>
                      <td>110.26</td>
                      <td>1,345.1</td>
                      <td>360</td>
                      <td>256</td>
                      <td>19.9</td>
                      <td>11.4</td>
                    </tr>
                    <tr>
                      <td>82</td>
                      <td>122.18</td>
                      <td>1,490.5</td>
                      <td>63</td>
                      <td>257</td>
                      <td>21.7</td>
                      <td>13.0</td>
                    </tr>
                    <tr>
                      <td>128</td>
                      <td>190.9</td>
                      <td>2,328.9</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td rowspan="4">14x14 1/2<br>355.6x368.3</td>
                      <td>90</td>
                      <td>134.1</td>
                      <td>1,636.0</td>
                      <td>356</td>
                      <td>369</td>
                      <td>18.0</td>
                      <td>11.2</td>
                    </tr>
                    <tr>
                      <td>99</td>
                      <td>147.5</td>
                      <td>1,799.5</td>
                      <td>360</td>
                      <td>370</td>
                      <td>19.8</td>
                      <td>12.3</td>
                    </tr>
                    <tr>
                      <td>109</td>
                      <td>162.4</td>
                      <td>1,981.2</td>
                      <td>364</td>
                      <td>371</td>
                      <td>21.8</td>
                      <td>13.3</td>
                    </tr>
                    <tr>
                      <td>120</td>
                      <td>178.8</td>
                      <td>2,182.36</td>
                      <td>368</td>
                      <td>373</td>
                      <td>23.9</td>
                      <td>15.0</td>
                    </tr>
                    <tr>
                      <td>14x14 3/4<br>355.6x374.65</td>
                      <td>132</td>
                      <td>196.7</td>
                      <td>2,399.7</td>
                      <td>372</td>
                      <td>374</td>
                      <td>26.2</td>
                      <td>16.4</td>
                    </tr>
                    <tr>
                      <td rowspan="13">14x16<br>355.6x406.40</td>
                      <td>145</td>
                      <td>215.8</td>
                      <td>2,632.76</td>
                      <td>375</td>
                      <td>394</td>
                      <td>27.7</td>
                      <td>17.27</td>
                    </tr>
                    <tr>
                      <td>159</td>
                      <td>236.6</td>
                      <td>2,886.52</td>
                      <td>380</td>
                      <td>395</td>
                      <td>30.2</td>
                      <td>18.92</td>
                    </tr>
                    <tr>
                      <td>176</td>
                      <td>261.9</td>
                      <td>3,195.18</td>
                      <td>387</td>
                      <td>398</td>
                      <td>33.3</td>
                      <td>21.08</td>
                    </tr>
                    <tr>
                      <td>193</td>
                      <td>287.2</td>
                      <td>3,503.84</td>
                      <td>393</td>
                      <td>399</td>
                      <td>36.6</td>
                      <td>22.61</td>
                    </tr>
                    <tr>
                      <td>211</td>
                      <td>314.0</td>
                      <td>3,830.80</td>
                      <td>399</td>
                      <td>401</td>
                      <td>39.6</td>
                      <td>24.89</td>
                    </tr>
                    <tr>
                      <td>233</td>
                      <td>346.7</td>
                      <td>4,229.74</td>
                      <td>407</td>
                      <td>404</td>
                      <td>43.7</td>
                      <td>27.18</td>
                    </tr>
                    <tr>
                      <td>257</td>
                      <td>382.5</td>
                      <td>4,666.50</td>
                      <td>416</td>
                      <td>406</td>
                      <td>48.0</td>
                      <td>29.85</td>
                    </tr>
                    <tr>
                      <td>283</td>
                      <td>421.1</td>
                      <td>5,137.42</td>
                      <td>425</td>
                      <td>409</td>
                      <td>52.6</td>
                      <td>32.77</td>
                    </tr>
                    <tr>
                      <td>311</td>
                      <td>462.8</td>
                      <td>5,646.16</td>
                      <td>435</td>
                      <td>412</td>
                      <td>57.4</td>
                      <td>35.81</td>
                    </tr>
                    <tr>
                      <td>342</td>
                      <td>509.0</td>
                      <td>6,209.80</td>
                      <td>446</td>
                      <td>416</td>
                      <td>62.7</td>
                      <td>39.12</td>
                    </tr>
                    <tr>
                      <td>370</td>
                      <td>550.6</td>
                      <td>6,717.32</td>
                      <td>455</td>
                      <td>418</td>
                      <td>67.6</td>
                      <td>42.04</td>
                    </tr>
                    <tr>
                      <td>398</td>
                      <td>592.3</td>
                      <td>7,226.06</td>
                      <td>465</td>
                      <td>421</td>
                      <td>72.3</td>
                      <td>44.96</td>
                    </tr>
                    <tr>
                      <td>426</td>
                      <td>634.0</td>
                      <td>7,734.80</td>
                      <td>474</td>
                      <td>424</td>
                      <td>77.1</td>
                      <td>47.63</td>
                    </tr>
                    <tr>
                      <td rowspan="2">16x5 1/2<br>406.4x139.7</td>
                      <td>26</td>
                      <td>38.7</td>
                      <td>472.1</td>
                      <td>399</td>
                      <td>140</td>
                      <td>8.8</td>
                      <td>6.4</td>
                    </tr>
                    <tr>
                      <td>31</td>
                      <td>46.2</td>
                      <td>536.6</td>
                      <td>403</td>
                      <td>140</td>
                      <td>11.2</td>
                      <td>7.0</td>
                    </tr>
                    <tr>
                      <td rowspan="5">16x7<br>406.4x177.80</td>
                      <td>36</td>
                      <td>53.6</td>
                      <td>653.92</td>
                      <td>403</td>
                      <td>177</td>
                      <td>10.9</td>
                      <td>7.6</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>59.6</td>
                      <td>726.14</td>
                      <td>406</td>
                      <td>178</td>
                      <td>12.7</td>
                      <td>7.8</td>
                    </tr>
                    <tr>
                      <td>45</td>
                      <td>67.1</td>
                      <td>818.91</td>
                      <td>409</td>
                      <td>179</td>
                      <td>14.3</td>
                      <td>8.8</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>74.5</td>
                      <td>907.68</td>
                      <td>413</td>
                      <td>180</td>
                      <td>15.9</td>
                      <td>9.6</td>
                    </tr>
                    <tr>
                      <td>57</td>
                      <td>84.93</td>
                      <td>1,036.00</td>
                      <td>417</td>
                      <td>181</td>
                      <td>18.2</td>
                      <td>10.9</td>
                    </tr>
                    <tr>
                      <td rowspan="4">16x10<br>406.4x254</td>
                      <td>67</td>
                      <td>99.83</td>
                      <td>1,217.93</td>
                      <td>415</td>
                      <td>260</td>
                      <td>16.9</td>
                      <td>10.0</td>
                    </tr>
                    <tr>
                      <td>77</td>
                      <td>114.7</td>
                      <td>1,399.34</td>
                      <td>420</td>
                      <td>261</td>
                      <td>19.3</td>
                      <td>11.6</td>
                    </tr>
                    <tr>
                      <td>89</td>
                      <td>132.6</td>
                      <td>1,617.72</td>
                      <td>425</td>
                      <td>263</td>
                      <td>22.2</td>
                      <td>13.3</td>
                    </tr>
                    <tr>
                      <td>100</td>
                      <td>149</td>
                      <td>1,817.80</td>
                      <td>431</td>
                      <td>265</td>
                      <td>25.0</td>
                      <td>14.9</td>
                    </tr>
                    <tr>
                      <td rowspan="3">18x6<br>406.4x152.4</td>
                      <td>35</td>
                      <td>52</td>
                      <td>635.62</td>
                      <td>450</td>
                      <td>152</td>
                      <td>10.8</td>
                      <td>7.6</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>59.6</td>
                      <td>725.9</td>
                      <td>455</td>
                      <td>153</td>
                      <td>13.3</td>
                      <td>8.0</td>
                    </tr>
                    <tr>
                      <td>46</td>
                      <td>68.5</td>
                      <td>834.48</td>
                      <td>454</td>
                      <td>190</td>
                      <td>12.7</td>
                      <td>8.5</td>
                    </tr>
                    <tr>
                      <td rowspan="5">18x7 1/2<br>457.2x190.5</td>
                      <td>50</td>
                      <td>74.5</td>
                      <td>907.68</td>
                      <td>457</td>
                      <td>192.78</td>
                      <td>19.0</td>
                      <td>9.0</td>
                    </tr>
                    <tr>
                      <td>55</td>
                      <td>82</td>
                      <td>1,00.40</td>
                      <td>457</td>
                      <td>192.78</td>
                      <td>19.1</td>
                      <td>9.9</td>
                    </tr>
                    <tr>
                      <td>60</td>
                      <td>89.4</td>
                      <td>1,089.22</td>
                      <td>457</td>
                      <td>192.78</td>
                      <td>19.2</td>
                      <td>10.5</td>
                    </tr>
                    <tr>
                      <td>65</td>
                      <td>96.9</td>
                      <td>1,182.18</td>
                      <td>466.09</td>
                      <td>192.78</td>
                      <td>19.5</td>
                      <td>11.43</td>
                    </tr>
                    <tr>
                      <td>71</td>
                      <td>105.6</td>
                      <td>1,288.32</td>
                      <td>469.13</td>
                      <td>193.92</td>
                      <td>20.57</td>
                      <td>12.57</td>
                    </tr>
                    <tr>
                      <td rowspan="15">18x11<br>457.2x279.4</td>
                      <td>76</td>
                      <td>113.2</td>
                      <td>1,381.00</td>
                      <td>462.53</td>
                      <td>280.29</td>
                      <td>17.27</td>
                      <td>10.79</td>
                    </tr>
                    <tr>
                      <td>86</td>
                      <td>128.1</td>
                      <td>1,561.60</td>
                      <td>467.10</td>
                      <td>281.69</td>
                      <td>19.55</td>
                      <td>12.19</td>
                    </tr>
                    <tr>
                      <td>97</td>
                      <td>144.3</td>
                      <td>1,761.68</td>
                      <td>472.18</td>
                      <td>283.08</td>
                      <td>22.09</td>
                      <td>13.58</td>
                    </tr>
                    <tr>
                      <td>106</td>
                      <td>157.7</td>
                      <td>1,923.94</td>
                      <td>475.74</td>
                      <td>284.48</td>
                      <td>23.87</td>
                      <td>14.98</td>
                    </tr>
                    <tr>
                      <td>119</td>
                      <td>177.3</td>
                      <td>2,163.00</td>
                      <td>481.83</td>
                      <td>286.13</td>
                      <td>26.92</td>
                      <td>16.62</td>
                    </tr>
                    <tr>
                      <td>130</td>
                      <td>193.5</td>
                      <td>2,360.70</td>
                      <td>489</td>
                      <td>283</td>
                      <td>30.5</td>
                      <td>17.02</td>
                    </tr>
                    <tr>
                      <td>143</td>
                      <td>213</td>
                      <td>2,597.38</td>
                      <td>495</td>
                      <td>285</td>
                      <td>33.5</td>
                      <td>18.5</td>
                    </tr>
                    <tr>
                      <td>158</td>
                      <td>235.1</td>
                      <td>2,868.22</td>
                      <td>501</td>
                      <td>287</td>
                      <td>36.6</td>
                      <td>20.57</td>
                    </tr>
                    <tr>
                      <td>175</td>
                      <td>260.4</td>
                      <td>3,176.88</td>
                      <td>509</td>
                      <td>289</td>
                      <td>40.4</td>
                      <td>22.61</td>
                    </tr>
                    <tr>
                      <td>192</td>
                      <td>285.7</td>
                      <td>3,485.54</td>
                      <td>517</td>
                      <td>291</td>
                      <td>44.5</td>
                      <td>24.38</td>
                    </tr>
                    <tr>
                      <td>211</td>
                      <td>314.0</td>
                      <td>3,830.80</td>
                      <td>525</td>
                      <td>293</td>
                      <td>48.5</td>
                      <td>26.92</td>
                    </tr>
                    <tr>
                      <td>234</td>
                      <td>348.2</td>
                      <td>4,248.04</td>
                      <td>535</td>
                      <td>296</td>
                      <td>53.6</td>
                      <td>29.46</td>
                    </tr>
                    <tr>
                      <td>258</td>
                      <td>383.9</td>
                      <td>4,682.58</td>
                      <td>545</td>
                      <td>299</td>
                      <td>58.4</td>
                      <td>32.51</td>
                    </tr>
                    <tr>
                      <td>283</td>
                      <td>421.1</td>
                      <td>5,137.42</td>
                      <td>555</td>
                      <td>302</td>
                      <td>63.5</td>
                      <td>35.56</td>
                    </tr>
                    <tr>
                      <td>311</td>
                      <td>462.8</td>
                      <td>5,646.16</td>
                      <td>567</td>
                      <td>305</td>
                      <td>69.6</td>
                      <td>38.61</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-striped table-bordered table-responsive ta-08">
                    <tr>
                      <td rowspan="2">Dimensiones PULG./MM.</td>
                      <td rowspan="2">LBS./M.</td>
                      <td rowspan="2">KG./M.</td>
                      <td rowspan="2">KG./PZA.</td>
                      <td rowspan="2">Peralte MM.</td>
                      <td colspan="2">Patín</td>
                      <td rowspan="2">Espesor del alma (MM.)</td>
                    </tr>
                    <tr>
                      <td>Ancho (MM.)</td>
                      <td>Espesor (MM.)</td>
                    </tr>
                    <tr>
                      <td rowspan="4">21x6 1/2<br>533.4x165.1</td>
                      <td>44</td>
                      <td>65.6</td>
                      <td>800.32</td>
                      <td>525</td>
                      <td>165</td>
                      <td>11.4</td>
                      <td>8.9</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>74.5</td>
                      <td>908.9</td>
                      <td>529</td>
                      <td>166</td>
                      <td>13.6</td>
                      <td>9.7</td>
                    </tr>
                    <tr>
                      <td>55</td>
                      <td>81.9</td>
                      <td>999.18</td>
                      <td>530</td>
                      <td>166</td>
                      <td>15.5</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>57</td>
                      <td>84.9</td>
                      <td>1,035.78</td>
                      <td>535</td>
                      <td>166</td>
                      <td>16.5</td>
                      <td>10.3</td>
                    </tr>
                    <tr>
                      <td rowspan="2">21x8<br>533.4x203.2</td>
                      <td>62</td>
                      <td>92.4</td>
                      <td>1,127.28</td>
                      <td>533</td>
                      <td>209</td>
                      <td>15.6</td>
                      <td>10.2</td>
                    </tr>
                    <tr>
                      <td>68</td>
                      <td>101.3</td>
                      <td>1,235.86</td>
                      <td>537</td>
                      <td>210</td>
                      <td>17.4</td>
                      <td>10.9</td>
                    </tr>
                    <tr>
                      <td rowspan="5">21x8 1/4<br>533.4x209.55</td>
                      <td>62</td>
                      <td>92.3</td>
                      <td>1,126.06</td>
                      <td>533</td>
                      <td>209</td>
                      <td>15.6</td>
                      <td>10.16</td>
                    </tr>
                    <tr>
                      <td>68</td>
                      <td>101.2</td>
                      <td>1,234.64</td>
                      <td>537</td>
                      <td>210</td>
                      <td>17.4</td>
                      <td>10.92</td>
                    </tr>
                    <tr>
                      <td>73</td>
                      <td>108.6</td>
                      <td>1,324.92</td>
                      <td>539</td>
                      <td>211</td>
                      <td>18.8</td>
                      <td>11.56</td>
                    </tr>
                    <tr>
                      <td>83</td>
                      <td>123.5</td>
                      <td>1,506.70</td>
                      <td>544</td>
                      <td>212</td>
                      <td>21.2</td>
                      <td>13.08</td>
                    </tr>
                    <tr>
                      <td>93</td>
                      <td>138.4</td>
                      <td>1,688.48</td>
                      <td>549</td>
                      <td>214</td>
                      <td>23.6</td>
                      <td>14.73</td>
                    </tr>
                    <tr>
                      <td rowspan="3">21x8 1/2<br>533.4x215.9</td>
                      <td>73</td>
                      <td>108.8</td>
                      <td>1,327.36</td>
                      <td>539</td>
                      <td>211</td>
                      <td>18.8</td>
                      <td>11.6</td>
                    </tr>
                    <tr>
                      <td>83</td>
                      <td>123.7</td>
                      <td>1,509.14</td>
                      <td>544</td>
                      <td>212</td>
                      <td>21.2</td>
                      <td>13.1</td>
                    </tr>
                    <tr>
                      <td>93</td>
                      <td>138.6</td>
                      <td>1,690.92</td>
                      <td>549</td>
                      <td>214</td>
                      <td>23.6</td>
                      <td>14.7</td>
                    </tr>
                    <tr>
                      <td rowspan="11">21x21 1/4<br>533.4x311.15</td>
                      <td>101</td>
                      <td>150.3</td>
                      <td>1,833.66</td>
                      <td>543</td>
                      <td>312</td>
                      <td>20.3</td>
                      <td>12.70</td>
                    </tr>
                    <tr>
                      <td>111</td>
                      <td>165.2</td>
                      <td>2,015.44</td>
                      <td>546</td>
                      <td>313</td>
                      <td>22.2</td>
                      <td>13.97</td>
                    </tr>
                    <tr>
                      <td>122</td>
                      <td>181.6</td>
                      <td>2,215.52</td>
                      <td>551</td>
                      <td>315</td>
                      <td>24.4</td>
                      <td>15.24</td>
                    </tr>
                    <tr>
                      <td>132</td>
                      <td>196.4</td>
                      <td>2,396.08</td>
                      <td>554</td>
                      <td>316</td>
                      <td>26.3</td>
                      <td>16.51</td>
                    </tr>
                    <tr>
                      <td>147</td>
                      <td>218.8</td>
                      <td>2,669.36</td>
                      <td>560</td>
                      <td>318</td>
                      <td>29.2</td>
                      <td>18.29</td>
                    </tr>
                    <tr>
                      <td>166</td>
                      <td>247.0</td>
                      <td>3,013.40</td>
                      <td>571</td>
                      <td>315</td>
                      <td>34.5</td>
                      <td>19.05</td>
                    </tr>
                    <tr>
                      <td>182</td>
                      <td>270.8</td>
                      <td>3,303.76</td>
                      <td>577</td>
                      <td>318</td>
                      <td>37.6</td>
                      <td>21.08</td>
                    </tr>
                    <tr>
                      <td>201</td>
                      <td>299.1</td>
                      <td>3,649.02</td>
                      <td>585</td>
                      <td>319</td>
                      <td>41.4</td>
                      <td>23.11</td>
                    </tr>
                    <tr>
                      <td>223</td>
                      <td>331.9</td>
                      <td>4,049.18</td>
                      <td>593</td>
                      <td>322</td>
                      <td>45.5</td>
                      <td>25.40</td>
                    </tr>
                    <tr>
                      <td>248</td>
                      <td>369.1</td>
                      <td>4,503.02</td>
                      <td>603</td>
                      <td>324</td>
                      <td>50.5</td>
                      <td>27.94</td>
                    </tr>
                    <tr>
                      <td>275</td>
                      <td>409.2</td>
                      <td>4,992.24</td>
                      <td>617</td>
                      <td>327</td>
                      <td>55.6</td>
                      <td>30.99</td>
                    </tr>
                    <tr>
                      <td rowspan="2">21x12 1/2<br>533.4x330.2</td>
                      <td>101</td>
                      <td>150.49</td>
                      <td>1,835.98</td>
                      <td>543</td>
                      <td>312</td>
                      <td>20.3</td>
                      <td>12.7</td>
                    </tr>
                    <tr>
                      <td>122</td>
                      <td>181.78</td>
                      <td>2,217.72</td>
                      <td>551</td>
                      <td>315</td>
                      <td>24.4</td>
                      <td>15.2</td>
                    </tr>
                    <tr>
                      <td rowspan="2">24x7<br>609.6x177.8</td>
                      <td>55</td>
                      <td>81.9</td>
                      <td>999.18</td>
                      <td>599</td>
                      <td>178</td>
                      <td>12.8</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>62</td>
                      <td>92.38</td>
                      <td>1,127.00</td>
                      <td>603</td>
                      <td>179</td>
                      <td>15</td>
                      <td>10.9</td>
                    </tr>
                    <tr>
                      <td rowspan="5">24x9<br>609.6x228.6</td>
                      <td>68</td>
                      <td>101.3</td>
                      <td>1,235.86</td>
                      <td>603</td>
                      <td>228</td>
                      <td>14.9</td>
                      <td>10.9</td>
                    </tr>
                    <tr>
                      <td>76</td>
                      <td>113.24</td>
                      <td>1,381.53</td>
                      <td>608</td>
                      <td>228</td>
                      <td>17.3</td>
                      <td>11.2</td>
                    </tr>
                    <tr>
                      <td>84</td>
                      <td>125.2</td>
                      <td>1,527.44</td>
                      <td>612</td>
                      <td>229</td>
                      <td>19.6</td>
                      <td>11.9</td>
                    </tr>
                    <tr>
                      <td>94</td>
                      <td>140</td>
                      <td>1,708.00</td>
                      <td>617</td>
                      <td>230</td>
                      <td>22.2</td>
                      <td>13.1</td>
                    </tr>
                    <tr>
                      <td>103</td>
                      <td>153.47</td>
                      <td>1,872.33</td>
                      <td>623</td>
                      <td>229</td>
                      <td>24.9</td>
                      <td>14</td>
                    </tr>
                    <tr>
                      <td rowspan="14">24x12 3/4<br>609.6x323.8</td>
                      <td>104</td>
                      <td>155</td>
                      <td>1,889.78</td>
                      <td>611</td>
                      <td>324</td>
                      <td>16</td>
                      <td>12.7</td>
                    </tr>
                    <tr>
                      <td>117</td>
                      <td>174.3</td>
                      <td>2,126.46</td>
                      <td>616</td>
                      <td>325</td>
                      <td>21.6</td>
                      <td>14</td>
                    </tr>
                    <tr>
                      <td>131</td>
                      <td>195.19</td>
                      <td>2,381.31</td>
                      <td>622</td>
                      <td>327</td>
                      <td>24.4</td>
                      <td>15.4</td>
                    </tr>
                    <tr>
                      <td>146</td>
                      <td>217.54</td>
                      <td>2,653.98</td>
                      <td>628</td>
                      <td>328</td>
                      <td>27.7</td>
                      <td>16.5</td>
                    </tr>
                    <tr>
                      <td>162</td>
                      <td>241.38</td>
                      <td>2,944.84</td>
                      <td>635</td>
                      <td>329</td>
                      <td>31</td>
                      <td>17.1</td>
                    </tr>
                    <tr>
                      <td>176</td>
                      <td>262.5</td>
                      <td>3,202.50</td>
                      <td>641</td>
                      <td>327</td>
                      <td>34</td>
                      <td>19</td>
                    </tr>
                    <tr>
                      <td>192</td>
                      <td>285.7</td>
                      <td>3,485.54</td>
                      <td>647</td>
                      <td>329</td>
                      <td>37.1</td>
                      <td>20.57</td>
                    </tr>
                    <tr>
                      <td>207</td>
                      <td>308.0</td>
                      <td>3,757.60</td>
                      <td>653</td>
                      <td>330</td>
                      <td>39.9</td>
                      <td>22.10</td>
                    </tr>
                    <tr>
                      <td>229</td>
                      <td>340.8</td>
                      <td>4,157.76</td>
                      <td>661</td>
                      <td>333</td>
                      <td>43.9</td>
                      <td>24.38</td>
                    </tr>
                    <tr>
                      <td>250</td>
                      <td>372.0</td>
                      <td>4,538.40</td>
                      <td>669</td>
                      <td>335</td>
                      <td>48.0</td>
                      <td>26.42</td>
                    </tr>
                    <tr>
                      <td>279</td>
                      <td>415.2</td>
                      <td>5,065.44</td>
                      <td>679</td>
                      <td>338</td>
                      <td>53.1</td>
                      <td>29.46</td>
                    </tr>
                    <tr>
                      <td>306</td>
                      <td>455.4</td>
                      <td>5,555.88</td>
                      <td>689</td>
                      <td>340</td>
                      <td>57.9</td>
                      <td>32.00</td>
                    </tr>
                    <tr>
                      <td>335</td>
                      <td>498.5</td>
                      <td>6,081.70</td>
                      <td>699</td>
                      <td>343</td>
                      <td>63.0</td>
                      <td>35.05</td>
                    </tr>
                    <tr>
                      <td>370</td>
                      <td>550.6</td>
                      <td>6,717.32</td>
                      <td>711</td>
                      <td>347</td>
                      <td>69.1</td>
                      <td>38.61</td>
                    </tr>
                    <tr>
                      <td rowspan="5">27x10<br>685.8x254</td>
                      <td>84</td>
                      <td>125.2</td>
                      <td>1,527.44</td>
                      <td>678</td>
                      <td>253</td>
                      <td>16.3</td>
                      <td>11.7</td>
                    </tr>
                    <tr>
                      <td>94</td>
                      <td>140</td>
                      <td>1,708.00</td>
                      <td>684</td>
                      <td>254</td>
                      <td>18.9</td>
                      <td>12.4</td>
                    </tr>
                    <tr>
                      <td>102</td>
                      <td>151.8</td>
                      <td>1,851.96</td>
                      <td>688</td>
                      <td>254</td>
                      <td>21.1</td>
                      <td>13.08</td>
                    </tr>
                    <tr>
                      <td>114</td>
                      <td>169.8</td>
                      <td>2,071.56</td>
                      <td>693</td>
                      <td>256</td>
                      <td>23.6</td>
                      <td>14.5</td>
                    </tr>
                    <tr>
                      <td>129</td>
                      <td>192.0</td>
                      <td>2,342.40</td>
                      <td>702</td>
                      <td>254</td>
                      <td>27.9</td>
                      <td>15.49</td>
                    </tr>
                    <tr>
                      <td rowspan="11">27x14<br>685.8x355.60</td>
                      <td>145</td>
                      <td>217</td>
                      <td>2,647.00</td>
                      <td>695</td>
                      <td>357</td>
                      <td>27.7</td>
                      <td>16.5</td>
                    </tr>
                    <tr>
                      <td>146</td>
                      <td>217.3</td>
                      <td>2,651.06</td>
                      <td>695</td>
                      <td>355</td>
                      <td>24.8</td>
                      <td>15.37</td>
                    </tr>
                    <tr>
                      <td>161</td>
                      <td>239.6</td>
                      <td>2,923.12</td>
                      <td>701</td>
                      <td>356</td>
                      <td>27.4</td>
                      <td>16.76</td>
                    </tr>
                    <tr>
                      <td>178</td>
                      <td>264.9</td>
                      <td>3,231.78</td>
                      <td>706</td>
                      <td>358</td>
                      <td>30.2</td>
                      <td>18.42</td>
                    </tr>
                    <tr>
                      <td>194</td>
                      <td>288.7</td>
                      <td>3,522.14</td>
                      <td>714</td>
                      <td>356</td>
                      <td>34.0</td>
                      <td>19.05</td>
                    </tr>
                    <tr>
                      <td>217</td>
                      <td>322.9</td>
                      <td>3,939.38</td>
                      <td>722</td>
                      <td>359</td>
                      <td>38.1</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>235</td>
                      <td>349.7</td>
                      <td>4,266.34</td>
                      <td>728</td>
                      <td>360</td>
                      <td>40.9</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>258</td>
                      <td>383.9</td>
                      <td>4,683.58</td>
                      <td>736</td>
                      <td>362</td>
                      <td>45.0</td>
                      <td>24.89</td>
                    </tr>
                    <tr>
                      <td>307</td>
                      <td>456.9</td>
                      <td>5,574.18</td>
                      <td>752</td>
                      <td>367</td>
                      <td>53.1</td>
                      <td>26.46</td>
                    </tr>
                    <tr>
                      <td>336</td>
                      <td>500.0</td>
                      <td>6,100.00</td>
                      <td>762</td>
                      <td>370</td>
                      <td>57.9</td>
                      <td>32.00</td>
                    </tr>
                    <tr>
                      <td>368</td>
                      <td>547.6</td>
                      <td>6,680.72</td>
                      <td>772</td>
                      <td>372</td>
                      <td>63.0</td>
                      <td>35.05</td>
                    </tr>
                    <tr>
                      <td rowspan="4">30x10<br>732x254</td>
                      <td>90</td>
                      <td>134.1</td>
                      <td>1,636.00</td>
                      <td>750</td>
                      <td>264</td>
                      <td>16</td>
                      <td>12.9</td>
                    </tr>
                    <tr>
                      <td>99</td>
                      <td>147.51</td>
                      <td>1,799.60</td>
                      <td>753</td>
                      <td>265</td>
                      <td>17</td>
                      <td>13.2</td>
                    </tr>
                    <tr>
                      <td>108</td>
                      <td>160.9</td>
                      <td>1,963.00</td>
                      <td>758</td>
                      <td>266</td>
                      <td>19.3</td>
                      <td>13.8</td>
                    </tr>
                    <tr>
                      <td>132</td>
                      <td>196.69</td>
                      <td>2,400.00</td>
                      <td>770</td>
                      <td>268</td>
                      <td>25.4</td>
                      <td>15.6</td>
                    </tr>
                    <tr>
                      <td rowspan="7">30x10 1/2<br>762x266.70</td>
                      <td>90</td>
                      <td>133.9</td>
                      <td>1,633.58</td>
                      <td>750</td>
                      <td>264</td>
                      <td>15.5</td>
                      <td>11.94</td>
                    </tr>
                    <tr>
                      <td>99</td>
                      <td>147.3</td>
                      <td>1,797.06</td>
                      <td>753</td>
                      <td>265</td>
                      <td>17.0</td>
                      <td>13.21</td>
                    </tr>
                    <tr>
                      <td>108</td>
                      <td>160.7</td>
                      <td>1,960.54</td>
                      <td>758</td>
                      <td>266</td>
                      <td>19.3</td>
                      <td>13.84</td>
                    </tr>
                    <tr>
                      <td>116</td>
                      <td>172.6</td>
                      <td>2,105.72</td>
                      <td>762</td>
                      <td>267</td>
                      <td>21.6</td>
                      <td>14.35</td>
                    </tr>
                    <tr>
                      <td>124</td>
                      <td>184.5</td>
                      <td>2,250.90</td>
                      <td>766</td>
                      <td>267</td>
                      <td>23.6</td>
                      <td>14.86</td>
                    </tr>
                    <tr>
                      <td>132</td>
                      <td>196.4</td>
                      <td>2,396.08</td>
                      <td>770</td>
                      <td>268</td>
                      <td>25.4</td>
                      <td>15.62</td>
                    </tr>
                    <tr>
                      <td>148</td>
                      <td>220.2</td>
                      <td>2,686.44</td>
                      <td>779</td>
                      <td>266</td>
                      <td>30.0</td>
                      <td>16.51</td>
                    </tr>
                    <tr>
                      <td rowspan="9">30x15<br>762x381</td>
                      <td>173</td>
                      <td>257.5</td>
                      <td>3,141.50</td>
                      <td>773</td>
                      <td>381</td>
                      <td>27.1</td>
                      <td>16.64</td>
                    </tr>
                    <tr>
                      <td>191</td>
                      <td>284.2</td>
                      <td>3,467.24</td>
                      <td>779</td>
                      <td>382</td>
                      <td>30.1</td>
                      <td>18.03</td>
                    </tr>
                    <tr>
                      <td>211</td>
                      <td>314.0</td>
                      <td>3,830.80</td>
                      <td>786</td>
                      <td>384</td>
                      <td>33.4</td>
                      <td>19.69</td>
                    </tr>
                    <tr>
                      <td>235</td>
                      <td>349.7</td>
                      <td>4,266.34</td>
                      <td>795</td>
                      <td>382</td>
                      <td>38.1</td>
                      <td>21.08</td>
                    </tr>
                    <tr>
                      <td>261</td>
                      <td>388.4</td>
                      <td>4,738.48</td>
                      <td>803</td>
                      <td>385</td>
                      <td>41.9</td>
                      <td>23.62</td>
                    </tr>
                    <tr>
                      <td>292</td>
                      <td>434.5</td>
                      <td>5,300.90</td>
                      <td>813</td>
                      <td>387</td>
                      <td>47.0</td>
                      <td>25.91</td>
                    </tr>
                    <tr>
                      <td>326</td>
                      <td>485.1</td>
                      <td>5,918.22</td>
                      <td>823</td>
                      <td>390</td>
                      <td>52.1</td>
                      <td>28.96</td>
                    </tr>
                    <tr>
                      <td>357</td>
                      <td>532.3</td>
                      <td>6,481.86</td>
                      <td>833</td>
                      <td>393</td>
                      <td>56.9</td>
                      <td>31.50</td>
                    </tr>
                    <tr>
                      <td>391</td>
                      <td>581.9</td>
                      <td>7,099.18</td>
                      <td>843</td>
                      <td>396</td>
                      <td>62.0</td>
                      <td>34.54</td>
                    </tr>
                    <tr>
                      <td rowspan="5">33x11 1/2<br>838.20x292.10</td>
                      <td>118</td>
                      <td>175.8</td>
                      <td>2,145.00</td>
                      <td>835</td>
                      <td>292</td>
                      <td>18.8</td>
                      <td>14</td>
                    </tr>
                    <tr>
                      <td>130</td>
                      <td>193.5</td>
                      <td>2,360.70</td>
                      <td>840</td>
                      <td>292</td>
                      <td>21.7</td>
                      <td>14.73</td>
                    </tr>
                    <tr>
                      <td>141</td>
                      <td>209.8</td>
                      <td>2,559.56</td>
                      <td>846</td>
                      <td>293</td>
                      <td>24.4</td>
                      <td>15.37</td>
                    </tr>
                    <tr>
                      <td>152</td>
                      <td>226.2</td>
                      <td>2,759.64</td>
                      <td>851</td>
                      <td>294</td>
                      <td>26.8</td>
                      <td>16.13</td>
                    </tr>
                    <tr>
                      <td>169</td>
                      <td>251.5</td>
                      <td>3,068.30</td>
                      <td>859</td>
                      <td>292</td>
                      <td>31.0</td>
                      <td>17.02</td>
                    </tr>
                    <tr>
                      <td rowspan="8">33x15 3/4<br>838.20x400.05</td>
                      <td>201</td>
                      <td>299.1</td>
                      <td>3,649.02</td>
                      <td>855</td>
                      <td>400</td>
                      <td>29.2</td>
                      <td>18.16</td>
                    </tr>
                    <tr>
                      <td>221</td>
                      <td>328.9</td>
                      <td>4,012.58</td>
                      <td>862</td>
                      <td>401</td>
                      <td>32.4</td>
                      <td>19.69</td>
                    </tr>
                    <tr>
                      <td>241</td>
                      <td>358.6</td>
                      <td>4,374.92</td>
                      <td>868</td>
                      <td>403</td>
                      <td>35.6</td>
                      <td>21.08</td>
                    </tr>
                    <tr>
                      <td>263</td>
                      <td>391.4</td>
                      <td>4,775.08</td>
                      <td>877</td>
                      <td>401</td>
                      <td>39.9</td>
                      <td>22.10</td>
                    </tr>
                    <tr>
                      <td>291</td>
                      <td>433.1</td>
                      <td>5,283.82</td>
                      <td>885</td>
                      <td>404</td>
                      <td>43.9</td>
                      <td>24.38</td>
                    </tr>
                    <tr>
                      <td>318</td>
                      <td>473.2</td>
                      <td>5,773.04</td>
                      <td>893</td>
                      <td>406</td>
                      <td>48.0</td>
                      <td>26.42</td>
                    </tr>
                    <tr>
                      <td>354</td>
                      <td>526.8</td>
                      <td>6,426.96</td>
                      <td>903</td>
                      <td>409</td>
                      <td>53.1</td>
                      <td>29.46</td>
                    </tr>
                    <tr>
                      <td>387</td>
                      <td>575.9</td>
                      <td>7,025.98</td>
                      <td>913</td>
                      <td>411</td>
                      <td>57.9</td>
                      <td>32.00</td>
                    </tr>
                    <tr>
                      <td rowspan="9">36x12<br>914.40x304.80</td>
                      <td>135</td>
                      <td>200.9</td>
                      <td>2,450.98</td>
                      <td>903</td>
                      <td>304</td>
                      <td>20.1</td>
                      <td>15.24</td>
                    </tr>
                    <tr>
                      <td>150</td>
                      <td>223.2</td>
                      <td>2,723.04</td>
                      <td>911</td>
                      <td>304</td>
                      <td>23.9</td>
                      <td>15.88</td>
                    </tr>
                    <tr>
                      <td>160</td>
                      <td>238.1</td>
                      <td>2,904.82</td>
                      <td>915</td>
                      <td>305</td>
                      <td>25.9</td>
                      <td>16.51</td>
                    </tr>
                    <tr>
                      <td>170</td>
                      <td>253.0</td>
                      <td>3,086.60</td>
                      <td>919</td>
                      <td>306</td>
                      <td>27.9</td>
                      <td>17.27</td>
                    </tr>
                    <tr>
                      <td>182</td>
                      <td>270.8</td>
                      <td>3,303.76</td>
                      <td>923</td>
                      <td>307</td>
                      <td>30.0</td>
                      <td>18.42</td>
                    </tr>
                    <tr>
                      <td>194</td>
                      <td>288.7</td>
                      <td>3,522.14</td>
                      <td>927</td>
                      <td>308</td>
                      <td>32.0</td>
                      <td>19.43</td>
                    </tr>
                    <tr>
                      <td>210</td>
                      <td>312.5</td>
                      <td>3,812.50</td>
                      <td>932</td>
                      <td>309</td>
                      <td>34.5</td>
                      <td>21.08</td>
                    </tr>
                    <tr>
                      <td>232</td>
                      <td>345.3</td>
                      <td>4,212.66</td>
                      <td>943</td>
                      <td>308</td>
                      <td>39.9</td>
                      <td>22.10</td>
                    </tr>
                    <tr>
                      <td>256</td>
                      <td>381.0</td>
                      <td>4,648.20</td>
                      <td>951</td>
                      <td>310</td>
                      <td>43.9</td>
                      <td>24.38</td>
                    </tr>
                    <tr>
                      <td rowspan="9">36x16 1/2<br>914.40x419.10</td>
                      <td>231</td>
                      <td>343.8</td>
                      <td>4,194.36</td>
                      <td>927</td>
                      <td>418</td>
                      <td>32.0</td>
                      <td>19.30</td>
                    </tr>
                    <tr>
                      <td>247</td>
                      <td>367.6</td>
                      <td>4,484.72</td>
                      <td>931</td>
                      <td>419</td>
                      <td>34.3</td>
                      <td>20.32</td>
                    </tr>
                    <tr>
                      <td>262</td>
                      <td>389.9</td>
                      <td>4,756.78</td>
                      <td>936</td>
                      <td>420</td>
                      <td>36.6</td>
                      <td>21.34</td>
                    </tr>
                    <tr>
                      <td>282</td>
                      <td>419.7</td>
                      <td>5,120.34</td>
                      <td>943</td>
                      <td>422</td>
                      <td>39.9</td>
                      <td>22.48</td>
                    </tr>
                    <tr>
                      <td>302</td>
                      <td>449.4</td>
                      <td>5,482.68</td>
                      <td>948</td>
                      <td>423</td>
                      <td>42.7</td>
                      <td>24.00</td>
                    </tr>
                    <tr>
                      <td>330</td>
                      <td>491.1</td>
                      <td>5,991.42</td>
                      <td>957</td>
                      <td>422</td>
                      <td>47.0</td>
                      <td>25.91</td>
                    </tr>
                    <tr>
                      <td>361</td>
                      <td>537.2</td>
                      <td>6,553.84</td>
                      <td>965</td>
                      <td>425</td>
                      <td>51.1</td>
                      <td>28.45</td>
                    </tr>
                    <tr>
                      <td>395</td>
                      <td>587.8</td>
                      <td>7,171.16</td>
                      <td>976</td>
                      <td>427</td>
                      <td>55.9</td>
                      <td>30.99</td>
                    </tr>
                    <tr>
                      <td>441</td>
                      <td>656.3</td>
                      <td>8,006.86</td>
                      <td>987</td>
                      <td>431</td>
                      <td>2.0</td>
                      <td>34.54</td>
                    </tr>
                    <tr>
                      <td rowspan="8">40x12<br>1016x304.80</td>
                      <td>149</td>
                      <td>221.7</td>
                      <td>2,704.74</td>
                      <td>970</td>
                      <td>300</td>
                      <td>21.1</td>
                      <td>16.00</td>
                    </tr>
                    <tr>
                      <td>167</td>
                      <td>248.5</td>
                      <td>3,031.70</td>
                      <td>980</td>
                      <td>300</td>
                      <td>26.0</td>
                      <td>16.51</td>
                    </tr>
                    <tr>
                      <td>183</td>
                      <td>272.3</td>
                      <td>3,322.06</td>
                      <td>990</td>
                      <td>300</td>
                      <td>30.5</td>
                      <td>16.51</td>
                    </tr>
                    <tr>
                      <td>211</td>
                      <td>314.0</td>
                      <td>3,830.80</td>
                      <td>1000</td>
                      <td>300</td>
                      <td>35.9</td>
                      <td>19.05</td>
                    </tr>
                    <tr>
                      <td>235</td>
                      <td>349.7</td>
                      <td>4,266.34</td>
                      <td>1008</td>
                      <td>302</td>
                      <td>40.0</td>
                      <td>21.08</td>
                    </tr>
                    <tr>
                      <td>264</td>
                      <td>392.9</td>
                      <td>4,793.38</td>
                      <td>1016</td>
                      <td>303</td>
                      <td>43.9</td>
                      <td>24.38</td>
                    </tr>
                    <tr>
                      <td>294</td>
                      <td>438.5</td>
                      <td>5,338.50</td>
                      <td>1026</td>
                      <td>305</td>
                      <td>49.0</td>
                      <td>26.92</td>
                    </tr>
                    <tr>
                      <td>327</td>
                      <td>486.6</td>
                      <td>5,936.52</td>
                      <td>1036</td>
                      <td>308</td>
                      <td>54.1</td>
                      <td>29.97</td>
                    </tr>
                    <tr>
                      <td rowspan="10">40x16<br>1016x406.40</td>
                      <td>199</td>
                      <td>296.1</td>
                      <td>3,612.42</td>
                      <td>982</td>
                      <td>400</td>
                      <td>27.1</td>
                      <td>16.51</td>
                    </tr>
                    <tr>
                      <td>215</td>
                      <td>320.0</td>
                      <td>3,904.00</td>
                      <td>990</td>
                      <td>400</td>
                      <td>31.0</td>
                      <td>16.51</td>
                    </tr>
                    <tr>
                      <td>249</td>
                      <td>370.6</td>
                      <td>4,521.32</td>
                      <td>1000</td>
                      <td>400</td>
                      <td>36.1</td>
                      <td>19.05</td>
                    </tr>
                    <tr>
                      <td>277</td>
                      <td>412.2</td>
                      <td>5,028.84</td>
                      <td>1008</td>
                      <td>402</td>
                      <td>40.0</td>
                      <td>21.08</td>
                    </tr>
                    <tr>
                      <td>297</td>
                      <td>442.0</td>
                      <td>5,392.40</td>
                      <td>1012</td>
                      <td>402</td>
                      <td>41.9</td>
                      <td>23.62</td>
                    </tr>
                    <tr>
                      <td>324</td>
                      <td>482.2</td>
                      <td>5,882.84</td>
                      <td>1020</td>
                      <td>404</td>
                      <td>46.0</td>
                      <td>25.40</td>
                    </tr>
                    <tr>
                      <td>362</td>
                      <td>538.7</td>
                      <td>6,572.14</td>
                      <td>1030</td>
                      <td>407</td>
                      <td>51.1</td>
                      <td>28.45</td>
                    </tr>
                    <tr>
                      <td>372</td>
                      <td>553.6</td>
                      <td>6,753.92</td>
                      <td>1032</td>
                      <td>408</td>
                      <td>51.9</td>
                      <td>29.46</td>
                    </tr>
                    <tr>
                      <td>397</td>
                      <td>590.8</td>
                      <td>7,207.76</td>
                      <td>1040</td>
                      <td>409</td>
                      <td>55.9</td>
                      <td>30.99</td>
                    </tr>
                    <tr>
                      <td>431</td>
                      <td>641.4</td>
                      <td>7,825.08</td>
                      <td>1048</td>
                      <td>412</td>
                      <td>59.9</td>
                      <td>34.04</td>
                    </tr>
                    <tr>
                      <td rowspan="4">44x16<br>1117.6x406.40</td>
                      <td>230</td>
                      <td>342.3</td>
                      <td>4,176.06</td>
                      <td>1090</td>
                      <td>400</td>
                      <td>31.0</td>
                      <td>18.03</td>
                    </tr>
                    <tr>
                      <td>262</td>
                      <td>389.9</td>
                      <td>4,756.78</td>
                      <td>1100</td>
                      <td>400</td>
                      <td>35.9</td>
                      <td>19.94</td>
                    </tr>
                    <tr>
                      <td>290</td>
                      <td>431.6</td>
                      <td>5,265.52</td>
                      <td>1108</td>
                      <td>402</td>
                      <td>40.0</td>
                      <td>21.97</td>
                    </tr>
                    <tr>
                      <td>335</td>
                      <td>498.5</td>
                      <td>6,081.70</td>
                      <td>1118</td>
                      <td>405</td>
                      <td>45.0</td>
                      <td>26.04</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    
  </section>
</template>

<script>
export default {
  name: "compProductos",
  data() {
    return {
      modalProductos: false,
      productoSeleccionado: "",
      anguloUno: true,
      anguloDos: false,
      laminaUno: true,
      laminaDos: false,
      laminaTres: false,
      laminaCuatro: false,
      laminaCinco: false,
      placaUno: true,
      placaDos: false,
      redondoUno: true,
      redondoDos: false,
      soleraUno: true,
      soleraDos: false,
      tuboUno: true,
      tuboDos: false,
      tuboTres: false,
      tuboCuatro: false,
      productos: [
      { nombre: "Acero Inoxidable", imagen: require("@/assets/img/pro-24.jpg"), descripcion: "Es un acero de elevada resistencia a la corrosión, dado que el cromo u otros metales aleantes que contiene.", },
      { nombre: "Alambre", imagen: require("@/assets/img/pro-01.jpg"), descripcion: "Es flexible, sin riesgo de ruptura. Es un material conductor. Cuenta con una superficie sin defectos. Su diseño liso, lo que facilita el trabajo y amarre de objetos.", },
        { nombre: "Alambrón", imagen: require("@/assets/img/pro-02.jpeg"), descripcion: "Se utiliza en la industria de la manufactura y en la construcción. Es en este sector en donde adquiere gran relevancia, porque es un elemento que sirve para unir las estructuras de las edificaciones.", },
        { nombre: "Ángulo", imagen: require("@/assets/img/pro-03.jpg"), descripcion: "Ofrecen alta resistencia y son de diferentes medidas, en largos que van de los 6.10 a los 12.20 metros y con un peso desde 1 hasta 55.66 kilogramos.", },
        { nombre: "Canal C.P.S.", imagen: require("@/assets/img/pro-04.jpg"), descripcion: "Es un tipo de perfil estructural de acero al carbono que se caracteriza por tener una forma en U. Esta estructura le permite tener más soporte.", },
        { nombre: "Cuadrado", imagen: require("@/assets/img/pro-05.jpg"), descripcion: "Es una pieza de acero macizo, la cual está hecha para ser un material de soporte estructural y se caracteriza por ser una barra cuadrada con sus esquinas redondeadas.", },
        { nombre: "Duela", imagen: require("@/assets/img/pro-06.jpg"), descripcion: "Es un material que tiene fácil manejo. Y otra de las grandes ventajas que ofrece, es que es mucho más económico. Ofrece también el beneficio de que es muy fácil de armar.", },
        { nombre: "H.S.S.", imagen: require("@/assets/img/pro-07.jpg"), descripcion: "Acero de Alta Velocidad o llamado también Acero Rápido. Se trata de un término para definir el material para aceros de herramientas que puedan usarse a una alta velocidad de maquinado (cortar, perforar).", },
        { nombre: "Lámina", imagen: require("@/assets/img/pro-08.jpg"), descripcion: "El tipo de lámina más utilizado para una puerta es la lámina tipo tablero. Está hecha con acero galvanizado y tiene un distintivo recubrimiento de color verde, el cual le proporciona mayor resistencia que otras láminas.", },
        { nombre: "Malla Electrosoldada", imagen: require("@/assets/img/pro-09.jpg"), descripcion: "Es un producto formado por dos sistemas de barras o alambres de acero, cuyos puntos de contacto están unidos, mediante soldaduras eléctricas por resistencia en un proceso de producción en serie.", },
        { nombre: "Pasamanos", imagen: require("@/assets/img/pro-10.jpg"), descripcion: "Son una opción sobresaliente para brindar seguridad, estilo y durabilidad en escaleras, balcones, terrazas, entre muchos otros recintos.", },
        { nombre: "Perfil Tubular", imagen: require("@/assets/img/pro-11.jpg"), descripcion: "Es una material fabricado con acero, el cual destaca debido a que es una pieza totalmente hueca. Esta sección tubular está fabricada bajo un proceso de laminado en caliente o en frío.", },
        { nombre: "Placa", imagen: require("@/assets/img/pro-12.jpg"), descripcion: "La plancha es una placa de acero estructural utilizado para la construcción en general y aplicaciones industriales.", },
        { nombre: "Polín Mon-Ten", imagen: require("@/assets/img/pro-13.jpg"), descripcion: "También conocido como montén, es un elemento arquitectónico que es popularmente fabricado en forma de “C” o “Z” y es ampliamente utilizado en el sector industrial.", },
        { nombre: "P.T.R.", imagen: require("@/assets/img/pro-14.jpg"), descripcion: "Es un perfil de acero también conocido como perfil tubular rectangular, se caracteriza por ser una barra hueca, generalmente utilizada en la construcción de estructuras que no necesitan gran tamaño ni peso.", },
        { nombre: "Redondo", imagen: require("@/assets/img/pro-15.jpg"), descripcion: "Es una barra que se utiliza en diferentes aplicaciones estructurales. Sus usos van desde la elaboración de anclas, parrillas, maquinaria, herrería, piezas roscadas, formación de ejes, entre otros.", },
        { nombre: "Rejilla tipo Irving", imagen: require("@/assets/img/pro-16.jpg"), descripcion: "Están fabricadas mediante un proceso de electroforjado que asegura la fusión de la varilla y la solera, haciéndolos un solo elemento.", },
        { nombre: "Solera", imagen: require("@/assets/img/pro-17.jpg"), descripcion: "Es una placa de metal plana que se utiliza para fabricar herramientas o distintas estructuras metálicas; también se utiliza como un perfil estructural.", },
        { nombre: "Tablero Galvanizado", imagen: require("@/assets/img/pro-18.jpg"), descripcion: "Producto que tiene una cobertura más amplia, que no disminuye su tenacidad, por lo que se le usa para fabricar productos que sean más durables que el resto.", },
        { nombre: "Tee Estructural", imagen: require("@/assets/img/pro-19.jpg"), descripcion: "Producto de acero laminado en caliente de sección en forma de T. USOS: En la fabricación de estructuras metálicas para la construcción civil, torres de transmisión, tijerales, carpintería metálica, etc.", },
        { nombre: "Tira para Cortinas", imagen: require("@/assets/img/pro-20.jpg"), descripcion: "Perfil galvanizado de gran uso en la industria de la herrería para ser utilizado en la fabricación de cortinas plegables para comercios, bodegas y naves industriales.", },
        { nombre: "Tubo", imagen: require("@/assets/img/pro-21.jpg"), descripcion: "Pueden ser redondos, cuadrados o rectangulares, y suelen incorporar aleaciones con diferentes metales, como el aluminio, el titanio, el manganesio o el tungsteno.", },
        { nombre: "Varilla", imagen: require("@/assets/img/pro-22.jpg"), descripcion: "Barra de acero bajo carbón, con una especie de bordes tipo corrugado en su superficie para mejorar la adherencia en el concreto y mantener sin movimiento la estructura con este último.", },
        { nombre: "Viga", imagen: require("@/assets/img/pro-23.jpg"), descripcion: "Se caracterizan por ser resistentes. Tienen un peso bajo y una flexibilidad mínima; por lo que muchos los eligen para sus construcciones.", },
      ],
    };
  },
  methods: {
    proAnguloUno() {
      this.anguloUno = true;
      this.anguloDos = false;
    },
    proAnguloDos() {
      this.anguloUno = false;
      this.anguloDos = true;
    },
    proLaminaUno() {
      this.laminaUno = true;
      this.laminaDos = false;
      this.laminaTres = false;
      this.laminaCuatro = false;
      this.laminaCinco = false;
    },
    proLaminaDos() {
      this.laminaUno = false;
      this.laminaDos = true;
      this.laminaTres = false;
      this.laminaCuatro = false;
      this.laminaCinco = false;
    },
    proLaminaTres() {
      this.laminaUno = false;
      this.laminaDos = false;
      this.laminaTres = true;
      this.laminaCuatro = false;
      this.laminaCinco = false;
    },
    proLaminaCuatro() {
      this.laminaUno = false;
      this.laminaDos = false;
      this.laminaTres = false;
      this.laminaCuatro = true;
      this.laminaCinco = false;
    },
    proLaminaCinco() {
      this.laminaUno = false;
      this.laminaDos = false;
      this.laminaTres = false;
      this.laminaCuatro = false;
      this.laminaCinco = true;
    },
    proPlacaUno() {
      this.placaUno = true;
      this.placaDos = false;
    },
    proPlacaDos() {
      this.placaUno = false;
      this.placaDos = true;
    },
    proRedondoUno() {
      this.redondoUno = true;
      this.redondoDos = false;
    },
    proRedondoDos() {
      this.redondoUno = false;
      this.redondoDos = true;
    },
    proSoleraUno() {
      this.soleraUno = true;
      this.soleraDos = false;
    },
    proSoleraDos() {
      this.soleraUno = false;
      this.soleraDos = true;
    },
    proTuboUno() {
      this.tuboUno = true;
      this.tuboDos = false;
      this.tuboTres = false;
      this.tuboCuatro = false;
    },
    proTuboDos() {
      this.tuboUno = false;
      this.tuboDos = true;
      this.tuboTres = false;
      this.tuboCuatro = false;
    },
    proTuboTres() {
      this.tuboUno = false;
      this.tuboDos = false;
      this.tuboTres = true;
      this.tuboCuatro = false;
    },
    proTuboCuatro() {
      this.tuboUno = false;
      this.tuboDos = false;
      this.tuboTres = false;
      this.tuboCuatro = true;
    },
    funModal(producto) {
      
  if (producto === "Acero Inoxidable") {
    // No realiza ninguna acción
    return;
  }
      // Resetea todos los modales a false
      for (let key in this.$data) {
        if (key.startsWith("modal")) {
          this.$data[key] = false;
        }
      }
      // Actualiza el nombre del producto seleccionado
      this.productoSeleccionado = producto;
      // Activa el modal del producto seleccionado
      this["modal" + producto] = true;
      // Muestra el modal principal
      this.modalProductos = true;
    },
  },
};
</script>
