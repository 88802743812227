<template>
    <footer>

        <div class="cont-foo">
            <div class="logo-foo"><img src="../assets/img/logo.webp" alt=""></div>
            <div class="info-foo">
                <div class="menu-foo">
                    <ul class="enlaces">
                        <li class="item" @click.prevent="handleClick('acordeon-slider')">
                            <a href="">Inicio</a>
                        </li>
                        <li class="item" @click.prevent="handleClick('quienes')">
                            <a href="">Quienes Somos</a>
                        </li>
                        <li class="item" @click.prevent="handleClick('proyectos')">
                            <a href="">Proyectos</a>
                        </li>
                        <li class="item" @click.prevent="handleClick('productos')">
                            <a href="">Productos</a>
                        </li>
                        <li class="item" @click.prevent="handleClick('servicios')">
                            <a href="">Servicios</a>
                        </li>
                        <li class="item" @click.prevent="handleClick('galeria-fotos')">
                            <a href="">Galería</a>
                        </li>
                        <li class="item contac" @click.prevent="handleClick('contacto')">
                            <a href="">Contacto</a>
                        </li>
                    </ul>
                </div>
                <div class="copy-foo"><span>Aceros Ikary</span> © Todos los derechos reservados 2024</div>
            </div>
        </div>

    </footer>
</template>

<script>
export default {
    name: "compFooter",
    data() {
        return {
        };
    },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
  handleClick(sectionId) {
    this.scrollToSection(sectionId);
    this.toggleMenu = !this.toggleMenu;
  },
    handleScroll() {
      this.isScrolled = window.scrollY > 40;
    }
  }
};
</script>
