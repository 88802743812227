<template>
  <section id="proyectos">
    <div class="back-trans"></div>
    <div class="cont-proy">
      <div class="info">
        <div class="tit">Proyectos</div>
        <div class="desc">
            Los puentes peatonales ubicados sobre Periférico, al cruce con Canal Nacional y Cañaverales ya están en operación.
            <a href="https://twitter.com/SOBSECDMX/status/1069307178291347456?s=19" target="_blank">Más Información</a>
        </div>
      </div>
      <div class="img">
        <img src="../assets/img/puente-02.jpeg" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "compProyectos",
};
</script>
