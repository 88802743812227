<template>
  <section id="acordeon-slider">

      <div class="logo"><img src="../assets/img/logo-2.webp" alt=""></div>

    <div class="cont-slider">
      
      <div class="item-slider" v-show="sliderIntro">
        <img src="../assets/img/banner/slider-intro.jpg" />
      </div>
      
      <div class="item-slider" v-show="sliderUno">
        <img src="../assets/img/banner/slider-01.webp" />
        <div class="info-item">
          <div class="tit">P.T.R</div>
        </div>
      </div>

      <div class="item-slider" v-show="sliderDos">
        <img src="../assets/img/banner/slider-02.webp" />
        <div class="info-item">
          <div class="tit">H.S.S</div>
        </div>
      </div>

      <div class="item-slider" v-show="sliderTres">
        <img src="../assets/img/banner/slider-03.jpg" />
        <div class="info-item">
          <div class="tit">Vigas IPG</div>
        </div>
      </div>

      <div class="item-slider" v-show="sliderCuatro">
        <img class="slider-cuatro" src="../assets/img/banner/slider-04.jpeg" />
        <div class="info-item">
          <div class="tit">Placas</div>
        </div>
      </div>

      <div class="item-slider" v-show="sliderCinco">
        <img src="../assets/img/banner/slider-05.webp" />
        <div class="info-item">
          <div class="tit">Monten</div>
        </div>
      </div>

      <div class="item-slider" v-show="sliderSeis">
        <img src="../assets/img/banner/slider-06.jpeg" />
        <div class="info-item">
          <div class="tit">Laminas</div>
        </div>
      </div>

      <div class="item-slider" v-show="sliderSiete">
        <img src="../assets/img/banner/slider-07.jpeg" />
        <div class="info-item">
          <div class="tit">Tubos</div>
        </div>
      </div>

      <div class="item-slider" v-show="sliderOcho">
        <img src="../assets/img/banner/slider-08.webp" />
        <div class="info-item">
          <div class="tit">Perfiles</div>
        </div>
      </div>

      <div class="item-slider" v-show="sliderNueve">
        <img src="../assets/img/banner/slider-09.jpeg" />
        <div class="info-item">
          <div class="tit">Rejillas Irving</div>
        </div>
      </div>

      <div class="item-slider" v-show="sliderDiez">
        <img class="alto" src="../assets/img/banner/slider-10.jpg" />
        <div class="info-item">
          <div class="tit">Fletes</div>
        </div>
      </div>

      <div class="item-slider" v-show="sliderOnce">
        <img class="alto" src="../assets/img/banner/slider-11.jpg" />
        <div class="info-item">
          <div class="tit">Construcción</div>
        </div>
      </div>

      <div class="indicadores">
        <i
          class="fa-circle-dot"
          @click="metodoSliderIntro"
          :class="{ 'fa-solid': sliderIntro, 'fa-regular': !sliderIntro }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderUno"
          :class="{ 'fa-solid': sliderUno, 'fa-regular': !sliderUno }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderDos"
          :class="{ 'fa-solid': sliderDos, 'fa-regular': !sliderDos }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderTres"
          :class="{ 'fa-solid': sliderTres, 'fa-regular': !sliderTres }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderCuatro"
          :class="{ 'fa-solid': sliderCuatro, 'fa-regular': !sliderCuatro }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderCinco"
          :class="{ 'fa-solid': sliderCinco, 'fa-regular': !sliderCinco }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderSeis"
          :class="{ 'fa-solid': sliderSeis, 'fa-regular': !sliderSeis }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderSiete"
          :class="{ 'fa-solid': sliderSiete, 'fa-regular': !sliderSiete }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderOcho"
          :class="{ 'fa-solid': sliderOcho, 'fa-regular': !sliderOcho }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderNueve"
          :class="{ 'fa-solid': sliderNueve, 'fa-regular': !sliderNueve }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderDiez"
          :class="{ 'fa-solid': sliderDiez, 'fa-regular': !sliderDiez }"
        ></i>
        <i
          class="fa-circle-dot"
          @click="metodoSliderOnce"
          :class="{ 'fa-solid': sliderOnce, 'fa-regular': !sliderOnce }"
        ></i>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "compAcordeanSlider",

  data() {
    return {
      sliderIntro: true,
      sliderUno: false,
      sliderDos: false,
      sliderTres: false,
      sliderCuatro: false,
      sliderCinco: false,
      sliderSeis: false,
      sliderSiete: false,
      sliderOcho: false,
      sliderNueve: false,
      sliderDiez: false,
      sliderOnce: false,
    };
  },

  methods: {
    metodoSliderIntro() {
      this.sliderIntro = true;
      this.sliderUno = false;
      this.sliderDos = false;
      this.sliderTres = false;
      this.sliderCuatro = false;
      this.sliderCinco = false;
      this.sliderSeis = false;
      this.sliderSiete = false;
      this.sliderOcho = false;
      this.sliderNueve = false;
      this.sliderDiez = false;
      this.sliderOnce = false;
    },
    metodoSliderUno() {
      this.sliderIntro = false;
      this.sliderUno = true;
      this.sliderDos = false;
      this.sliderTres = false;
      this.sliderCuatro = false;
      this.sliderCinco = false;
      this.sliderSeis = false;
      this.sliderSiete = false;
      this.sliderOcho = false;
      this.sliderNueve = false;
      this.sliderDiez = false;
      this.sliderOnce = false;
    },
    metodoSliderDos() {
      this.sliderIntro = false;
      this.sliderUno = false;
      this.sliderDos = true;
      this.sliderTres = false;
      this.sliderCuatro = false;
      this.sliderCinco = false;
      this.sliderSeis = false;
      this.sliderSiete = false;
      this.sliderOcho = false;
      this.sliderNueve = false;
      this.sliderDiez = false;
      this.sliderOnce = false;
    },
    metodoSliderTres() {
      this.sliderIntro = false;
      this.sliderUno = false;
      this.sliderDos = false;
      this.sliderTres = true;
      this.sliderCuatro = false;
      this.sliderCinco = false;
      this.sliderSeis = false;
      this.sliderSiete = false;
      this.sliderOcho = false;
      this.sliderNueve = false;
      this.sliderDiez = false;
      this.sliderOnce = false;
    },
    metodoSliderCuatro() {
      this.sliderIntro = false;
      this.sliderUno = false;
      this.sliderDos = false;
      this.sliderTres = false;
      this.sliderCuatro = true;
      this.sliderCinco = false;
      this.sliderSeis = false;
      this.sliderSiete = false;
      this.sliderOcho = false;
      this.sliderNueve = false;
      this.sliderDiez = false;
      this.sliderOnce = false;
    },
    metodoSliderCinco() {
      this.sliderIntro = false;
      this.sliderUno = false;
      this.sliderDos = false;
      this.sliderTres = false;
      this.sliderCuatro = false;
      this.sliderCinco = true;
      this.sliderSeis = false;
      this.sliderSiete = false;
      this.sliderOcho = false;
      this.sliderNueve = false;
      this.sliderDiez = false;
      this.sliderOnce = false;
    },
    metodoSliderSeis() {
      this.sliderIntro = false;
      this.sliderUno = false;
      this.sliderDos = false;
      this.sliderTres = false;
      this.sliderCuatro = false;
      this.sliderCinco = false;
      this.sliderSeis = true;
      this.sliderSiete = false;
      this.sliderOcho = false;
      this.sliderNueve = false;
      this.sliderDiez = false;
      this.sliderOnce = false;
    },
    metodoSliderSiete() {
      this.sliderIntro = false;
      this.sliderUno = false;
      this.sliderDos = false;
      this.sliderTres = false;
      this.sliderCuatro = false;
      this.sliderCinco = false;
      this.sliderSeis = false;
      this.sliderSiete = true;
      this.sliderOcho = false;
      this.sliderNueve = false;
      this.sliderDiez = false;
      this.sliderOnce = false;
    },
    metodoSliderOcho() {
      this.sliderIntro = false;
      this.sliderUno = false;
      this.sliderDos = false;
      this.sliderTres = false;
      this.sliderCuatro = false;
      this.sliderCinco = false;
      this.sliderSeis = false;
      this.sliderSiete = false;
      this.sliderOcho = true;
      this.sliderNueve = false;
      this.sliderDiez = false;
      this.sliderOnce = false;
    },
    metodoSliderNueve() {
      this.sliderIntro = false;
      this.sliderUno = false;
      this.sliderDos = false;
      this.sliderTres = false;
      this.sliderCuatro = false;
      this.sliderCinco = false;
      this.sliderSeis = false;
      this.sliderSiete = false;
      this.sliderOcho = false;
      this.sliderNueve = true;
      this.sliderDiez = false;
      this.sliderOnce = false;
    },
    metodoSliderDiez() {
      this.sliderIntro = false;
      this.sliderUno = false;
      this.sliderDos = false;
      this.sliderTres = false;
      this.sliderCuatro = false;
      this.sliderCinco = false;
      this.sliderSeis = false;
      this.sliderSiete = false;
      this.sliderOcho = false;
      this.sliderNueve = false;
      this.sliderDiez = true;
      this.sliderOnce = false;
    },
    metodoSliderOnce() {
      this.sliderIntro = false;
      this.sliderUno = false;
      this.sliderDos = false;
      this.sliderTres = false;
      this.sliderCuatro = false;
      this.sliderCinco = false;
      this.sliderSeis = false;
      this.sliderSiete = false;
      this.sliderOcho = false;
      this.sliderNueve = false;
      this.sliderDiez = false;
      this.sliderOnce = true;
    },
    // Método para cambiar automáticamente los sliders cada 5 segundos
    cambiarAutomaticamente() {
      setInterval(() => {
        if (this.sliderIntro) {
          this.metodoSliderUno();
        } else if (this.sliderUno) {
          this.metodoSliderDos();
        }  else if (this.sliderDos) {
          this.metodoSliderTres();
        } else if (this.sliderTres) {
          this.metodoSliderCuatro();
        } else if (this.sliderCuatro) {
          this.metodoSliderCinco();
        }  else if (this.sliderCinco) {
          this.metodoSliderSeis();
        }  else if (this.sliderSeis) {
          this.metodoSliderSiete();
        }  else if (this.sliderSiete) {
          this.metodoSliderOcho();
        }  else if (this.sliderOcho) {
          this.metodoSliderNueve();
        }  else if (this.sliderNueve) {
          this.metodoSliderDiez();
        }  else if (this.sliderDiez) {
          this.metodoSliderOnce();
        } else {
          this.metodoSliderIntro();
        }
      }, 5000);
    },
  },

  mounted() {
    // Llamar al método para cambiar automáticamente los sliders al montar el componente
    this.cambiarAutomaticamente();
  },
};
</script>
