<template>
  <section id="galeria-fotos">
    <div class="container">
      <div class="titulo">
        <i class="fa-regular fa-images"></i>
        Galería de Fotos
      </div>

      <div class="galeria">
        <div class="item-foto hidden-xs" v-for="(foto, index) in fotos" :key="index" @click="abrirModal(index)">
          <img :src="foto.url" :alt="foto.descripcion">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>

        <div class="body-fotos hidden-lg hidden-md hidden-sm">
          <div class="foto">
            <img :src="fotos[indiceModal].url" :alt="fotos[indiceModal].descripcion">
          </div>
          <div class="navegacion">
            <button class="ant" @click="cambiarFoto(-1)"><i class="fa-solid fa-angles-left"></i></button>
            <button class="sig" @click="cambiarFoto(1)"><i class="fa-solid fa-angles-right"></i></button>
            <button class="cerrar" @click="cerrarModal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-fotos" v-show="modalUno">
      <div class="body-fotos">
        <div class="foto">
          <img :src="fotos[indiceModal].url" :alt="fotos[indiceModal].descripcion">
        </div>
        <div class="navegacion">
          <button class="ant" @click="cambiarFoto(-1)"><i class="fa-solid fa-angles-left"></i></button>
          <button class="sig" @click="cambiarFoto(1)"><i class="fa-solid fa-angles-right"></i></button>
          <button class="cerrar" @click="cerrarModal">Cerrar</button>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "compGaleria",
  data() {
    return {
      modalUno: false,
      fotos: [
        { url: require('@/assets/img/fotos/img-01.jpg'), descripcion: "Descripción de la imagen 1" },
        { url: require('@/assets/img/fotos/img-02.jpg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-03.jpg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-04.jpg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-05.jpg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-06.jpg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-07.jpg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-08.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-09.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-10.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-11.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-12.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-13.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-14.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-15.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-16.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-17.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-18.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-19.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-20.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-21.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-22.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-23.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-24.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-25.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-26.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-27.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-28.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-29.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-30.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-31.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-32.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-33.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-34.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-35.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-36.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-37.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-38.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-39.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-40.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-41.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-42.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-43.jpeg'), descripcion: "Descripción de la imagen 2" },
        { url: require('@/assets/img/fotos/img-44.jpeg'), descripcion: "Descripción de la imagen 2" },
      ],
      indiceModal: 0 // Índice de la imagen actualmente mostrada en la modal
    };
  },
  methods: {
    abrirModal(index) {
      this.modalUno = true;
      this.indiceModal = index;
    },
    cerrarModal() {
      this.modalUno = false;
    },
    cambiarFoto(delta) {
      // Cambiar la imagen mostrada en la modal al siguiente o anterior
      this.indiceModal = (this.indiceModal + delta + this.fotos.length) % this.fotos.length;
    }
  }
};
</script>