<template>
    <section id="contacto">

        <div class="cont-contacto">
            <div class="datos-contacto">
                <div class="titulo">Contacto</div>
                <div class="info">
                    <ul>
                        <li>
                            <i class="fa-solid fa-location-dot"></i>
                            <span>Estado de México</span>
                        </li>
                        <li class="tele">
                            <i class="fa-solid fa-phone"></i>
                             <div class="numeros">
                                <span class="tel-comu"><a href="phone:5573150035" target="_blank">55 7315 0035</a></span><br>
                            <span class="tel-comu"><a href="phone:5573150018," target="_blank">55 7315 0018</a></span><br>
                            <span class="tel-comu"><a href="phone:5530918854" target="_blank">55 3091 8854</a></span><br>
                            <span class="tel-comu"><a href="phone:5530919464" target="_blank">55 3091 9464</a></span>
                             </div>
                        </li>
                        <li>
                            <i class="fa-brands fa-whatsapp"></i>
                            <span><a href="https://wa.me/525610919069" target="_blank">(52) 56 1091 9069</a></span>
                        </li>
                        <li>
                            <i class="fa-regular fa-envelope"></i>
                            <span><a href="mail:compras@acerosikary.com"
                                    target="_blank">compras@acerosikary.com</a></span>
                        </li>
                    </ul>
                </div>
                <div class="redes">
                    <ul>
                        <li class="item-fac"><a href="https://www.facebook.com/AcerosIkary" target="_blank"><i class="fa-brands fa-square-facebook"></i></a></li>
                        <li class="item-ins"><a href="https://www.instagram.com/ikaryaceros/" target="_blank"><i class="fa-brands fa-square-instagram"></i></a></li>
                        <li class="item-tik"><a href="https://www.tiktok.com/@acerosikary" target="_blank"><i class="fa-brands fa-tiktok"></i></a></li>
                        <li class="item-you"><a href="https://www.youtube.com/@AcerosIkary" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="mapa-contacto">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1927698.3246286116!2d-100.92456485929479!3d19.324253184619625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd8992c0eb0a3b%3A0xc2fef9be9fc5a857!2sEstado%20de%20M%C3%A9xico!5e0!3m2!1ses!2smx!4v1712764730144!5m2!1ses!2smx"
                    width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>

    </section>
</template>

<script>
export default {
    name: "compContacto",
    data() {
        return {
        };
    },
};
</script>
