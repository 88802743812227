<template>
  <section id="quienes">
    <div class="titulo-gral">
      <span> ¿Quienes Somos? </span>
    </div>

    <div class="container">
      <div class="row contenedor">
        <div class="col-md-5 img order-2 order-md-1">
          <img src="../assets/img/quienes.jpg" />
        </div>
        <div class="col-md-7 desc-cont order-1 order-md-2">
          <div class="tit">Aceros Ikary</div>
          Es una empresa confiable con presencia estratégica a nivel nacional de
          compra, venta y distribución de los más importantes materiales de
          acero.
          <div class="mision-vision">
            <span>Misión:</span> Satisfacer las necesidades de nuestros clientes
            mediante un excelente servicio, creando oportunidades de negocio.
            <br /><br />
            <span>Visión:</span> Ser la red de distribución de acero más
            importante de México.
          </div>
        </div>
      </div>
      <div class="ventajas-beneficios">
        <div class="ventajas">
          <div class="tit">
            <i class="fa-solid fa-user-shield"></i>
            Ventajas con la Competencia
          </div>
          <ul>
            <li>La prioridad para nuestra Empresa son nuestros clientes</li>
            <li>Le brindamos atención personalizada</li>
            <li>Personal Capacitado</li>
            <li>No se hace cargo de flete</li>
            <li>Entregamos en tiempo y forma</li>
            <li>Si no tenemos el material en existencia lo conseguimos</li>
            <li>No tenemos problema para entregar material a gran volumen</li>
            <li>Contamos con unidades propias</li>
            <li>Realizamos visitas a sus instalaciones para tratar sus necesidades</li>
            <li>Trabajadores responsables</li>
            <li>Instalaciones adecuadas</li>
            <li>Contamos con el servicio de fabricación y colocación de estructuras metálicas</li>
            <li>Habilitación de acero de acuerdo con sus necesidades</li>
            <li>Testimonio de clientes satisfechos</li>
          </ul>
        </div>
        <div class="beneficios">
          <div class="tit">
            <i class="fa-solid fa-trophy"></i>Beneficios
          </div>
          <ul>
            <li>Somos una Empresa con años de Experiencia</li>
            <li>Extenso surtido de material</li>
            <li>Manejamos diversas formas de pago</li>
            <li>Plazo mínimo de un día de entrega</li>
            <li>Le brindamos certificado de calidad de los materiales que adquiera</li>
            <li>Le brindamos crédito en base a un estudio previo</li>
            <li>Se entrega material a toda la república</li>
            <li>Tenemos la solución para cada una de sus necesidades</li>
            <li>Contamos con un excelente servicio</li>
            <li>Le brindamos calidad</li>
            <li>Somos una empresa seria y responsable</li>
            <li>Comprometidos en cubrir las necesidades del cliente</li>
            <li>Nos acoplamos a los lineamientos de entrega</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "compQuieres",
};
</script>
