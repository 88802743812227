<template>
  <section>
    <compHeader></compHeader>
    <compAcordeanSlider></compAcordeanSlider>
    <compQuienes></compQuienes>
    <compProyectos></compProyectos>
    <compProductos></compProductos>
    <compServicios></compServicios>
    <compGaleria></compGaleria>
    <compContacto></compContacto>
    <compFooter></compFooter>

    <div>
      Ancho de la ventana: {{ windowWidth }}px
    </div>
    <div>
      Alto de la ventana: {{ windowHeight }}px
    </div>
  </section>
</template>

<script>
import compHeader from './components/header';
import compAcordeanSlider from './components/acordeon-slider';
import compQuienes from './components/quienes.vue';
import compProyectos from './components/proyectos.vue';
import compProductos from './components/productos.vue';
import compServicios from './components/servicios.vue';
import compGaleria from './components/galeria.vue';
import compContacto from './components/contacto.vue';
import compFooter from './components/footer.vue'

export default {
  name: 'App',
  components: {
    compHeader,
    compAcordeanSlider,
    compQuienes,
    compProyectos,
    compProductos,
    compServicios,
    compGaleria,
    compContacto,
    compFooter,
  },
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0
    };
  },
  mounted() {
    // Llama al método para obtener el tamaño de la pantalla
    this.getWindowSize();
    
    // Agrega un event listener para el evento 'resize'
    window.addEventListener('resize', this.getWindowSize);
  },
  beforeDestroy() {
    // Remueve el event listener cuando el componente se destruye
    window.removeEventListener('resize', this.getWindowSize);
  },
  methods: {
    getWindowSize() {
      // Obtiene el ancho y el alto de la ventana del navegador
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    }
  }
}
</script>


